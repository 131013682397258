import React from 'react'

import styles from './index.module.less'

import globalLoaing from '@/assets/web/globalLoading.svg'

import useTeamsLoading from '@/hooks/useTeamsLoading'

export default () => {
  const loadingNode = (
    <div className={styles['loading-wrap']}>
      <img src={globalLoaing} alt="" />
    </div>
  )

  return useTeamsLoading(loadingNode)
}
