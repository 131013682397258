export const BODY_BACKGROUND = '#F4F7FE'
export const GRAY_COLOR = '#96A3BE'
export const TEXT_COLOR_SECONDARY = '#04204073' ///* 字体/3级 */   rgba(4, 32, 64, 0.45)
export const TEXT_COLOR = '#5D6A83'
export const TITLE_COLOR = '#1A202C'
export const BTN_PRIMARY_COLOR = '#ffffff'
export const ERROR_COLOR = '#FF4747'
export const WARNING_COLOR = '#FACC15'
export const UNKNOW_COLOR = '#CBD5E0'
export const SUCCESS_COLOR = '#22C55E'
export const RED_10_COLOR = '#5c0011'
export const GRAY_400_COLOR = '#9CA3AF'
export const ORANGE_400_COLOR = '#FF8A4C'
export const GREEN_500_COLOR = '#0E9F6E'
export const PURPLE_500_COLOR = '#9061F9'
export const BLUE_500_COLOR = '#3F83F8'
export const PRIMARY_COLOR = '#137eff'
export const TRANSPARENT_COLOR = 'transparent'
