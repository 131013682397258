import React, { useEffect } from 'react'

function TestError() {
  useEffect(() => {
    const globalLoadingInHtml = document.getElementById('globalLoadingInHtml')
    globalLoadingInHtml?.remove()
  }, [])
  return (
    <div>
      <button
        type="button"
        onClick={() => {
          const a = import.meta.env.VITE_NODE_ENV
          throw new Error(`Sentry Test Error: env=${a}`)
        }}
      >
        Throw error
      </button>
    </div>
  )
}

const routes = {
  path: '/testerror',
  element: <TestError />,
}

export default routes
