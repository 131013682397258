import React, { useState } from 'react'

const UserContext = React.createContext({})

function getUser() {
  const [followingsWorkStatuses, setFollowingsWorkStatuses] = useState([])

  return {
    followingsWorkStatuses,
    setFollowingsWorkStatuses,
  }
}

// provider, provide authenticated, login, logout
export function UserProvider({ children }: any) {
  const visitor = getUser()

  return <UserContext.Provider value={visitor}>{children}</UserContext.Provider>
}

// consumer
export function useUser() {
  const context = React.useContext(UserContext)
  if (context === undefined) {
    throw new Error('useVisitor must be used within a VisitorProvider')
  }
  return context
}
