import React, { createContext, useState, useEffect } from 'react'
import intl from 'react-intl-universal'
import { useLocation, useNavigate } from 'react-router-dom'
import { SUPPORTED_LOCALES } from '@/utils/constants/supportedLocales'

import dayjs from 'dayjs'

import GlobalLoading from '@/components/GlobalLoading'

// MUST Load all locales needed manually

const LangContext = createContext({})

function getLangValue() {
  const navigate = useNavigate()
  const { pathname, state, search } = useLocation()
  const [loading, setLoading] = useState(true)

  const [lang, setLang] = useState(() => {
    // 自动获取language设置
    let currentLocale = intl.determineLocale({
      urlLocaleKey: 'lang',
      cookieLocaleKey: 'lang',
      localStorageLocaleKey: 'lang',
    })
    if (!SUPPORTED_LOCALES.find((x: any) => x.value === currentLocale)) {
      currentLocale = 'zh-CN'
    }
    return currentLocale
  })

  useEffect(() => {
    import(`../locales/${lang}.json`).then((res) => {
      intl
        .init({
          currentLocale: lang, // 默认en-US
          locales: {
            [lang]: res?.default,
          },
        })
        .then(() => {
          window.intl_inited = true
          console.log('**** lang inited', lang)
          navigate(`${pathname}${search}`, { state, replace: true })
          // setTimeout(() => setLoading(false), 300);
          setLoading(false)
        })
    })
  }, [lang])

  const onChange = (v = 'zh-CN') => {
    let _v = v

    // 其他平台获取到的语言不一定是标准格式，所以这里换了一些
    if (v?.toLowerCase() === 'zh-hans-cn') {
      _v = 'zh-CN'
    } else if (v?.toLowerCase() === 'zh-hant-cn') {
      _v = 'zh-TW'
    }

    const locale =
      SUPPORTED_LOCALES.find(
        (x: any) => x.value.toLowerCase() === _v.toLowerCase()
      ) || SUPPORTED_LOCALES[0]

    if (locale.value !== lang) {
      localStorage.setItem('lang', locale.value)
      setLoading(true)
      setLang(locale.value)
    }
  }

  useEffect(() => {
    switch (lang) {
      case 'zh-CN':
        dayjs.locale('zh-cn')
        break
      case 'zh-TW':
        dayjs.locale('zh-tw')
        break
      case 'en-US':
        dayjs.locale('en')
        break
      default:
        dayjs.locale('en')
        break
    }
  }, [lang])

  return {
    loading,
    lang,
    setLang: onChange,
  }
}

export function LangProvider({ children }: any) {
  const value = getLangValue()
  return (
    <LangContext.Provider value={value}>
      {value?.loading ? <GlobalLoading /> : children}
    </LangContext.Provider>
  )
}

export function useLangContext() {
  const context = React.useContext(LangContext)
  if (context === undefined) {
    throw new Error('useLangContext must be used within a LangProvider')
  }
  return context
}
