import * as api from '@/services/api'

import { setLocalStorage, getLocalStorage } from '@/utils/localStorage'

import { diffArray, isIntersected } from '@/utils/utils'
import { isNumber, isString, get, set } from 'lodash'
import React, { useCallback, useEffect, useState, useMemo, useRef } from 'react'

import { useAuthentication } from './AuthContext'

const LocationContext = React.createContext({})

function getLocation() {
  const [currentLocation, setCurrentLocation] = useState()
  const [currentLocationSettings, setCurrentLocationSettings] = useState({})
  const [floors, setFloors] = useState([])

  const {
    permissions = {},
    currentUser,
    space,
    getModules,
  } = useAuthentication()
  const [isEmployee, setIsEmployee] = useState(true)
  const [roles, setRoles] = useState([])
  const [
    locationDeskGroupDefaultSettings,
    setLocationDeskGroupDefaultSettings,
  ] = useState()
  const [visitorModuleDisabled, setVisitorModuleDisabled] = useState(false)
  const [meetingModuleDisabled, setMeetingModuleDisabled] = useState(false)
  const [deskModuleDisabled, setDeskModuleDisabled] = useState(false)
  const [submenu, setSubmenu] = useState([])
  const [menu, setMenu] = useState([])
  const [userDrawerInfo, setUserDrawerInfo] = useState({})
  const isInit = useRef(true)

  // TODO: cannot use useAuthentication because context is

  let currentLocationId = currentLocation?.id

  const getCurrentLocationId = () => currentLocationId

  /**
   * change location. pass null to change to space level
   * @param {*} newLocation
   */
  const changeLocation = (location = null) => {
    let newLocation = location
    if (isNumber(newLocation) || isString(newLocation)) {
      newLocation = currentUser.locations?.find((x: any) => x.id == newLocation)
    }
    // console.log("change location to ", newLocation);
    setLocalStorage(
      'current_location',
      newLocation ? JSON.stringify(newLocation) : ''
    )

    setCurrentLocation(newLocation)
    setMenu([]) // 必须清除menu防止自动跳转

    currentLocationId = newLocation?.id // update currentLocationId immediately
    return newLocation
  }

  // 刷新页面时，在分店层级和用户端，默认选中之前储存的分店
  if (isInit.current) {
    const localStorageLocationData = getLocalStorage('current_location')
    const isSpacePage = window.location.pathname?.startsWith('/admin/space')

    if (localStorageLocationData && !isSpacePage) {
      changeLocation(JSON.parse(localStorageLocationData))
    }

    isInit.current = false
  }

  // 变更Header中X-location参数
  useEffect(() => {
    window._x_location = currentLocation?.id

    const moduleDisabled =
      currentLocation?.settings?.general?.disabled_plans?.includes('desk')
    setDeskModuleDisabled(moduleDisabled)
  }, [currentLocation])

  // 获取roles
  const getRoles = () => {
    return get(permissions, 'space_roles', [])
  }

  // 获取该分店所有可用模块
  const getEnabledModules = useCallback(() => {
    const modules = getModules(space) || [] // 订阅的计划，计划可能多个模块

    const disabledPlans = currentLocationSettings?.general?.disabled_plans || [] // 禁用的模块, hybridWork, desk, meeting, ...
    const enabledModules = diffArray(modules, disabledPlans)
    return enabledModules
  }, [space, currentLocationSettings])

  // 判断是否module是enabled
  // module - 购买的plan
  // feature - 实际激活的模块feature
  const isModuleEnabled = useCallback(
    (module: any) => {
      const enabledModules = getEnabledModules()
      return enabledModules.includes(module)
    },
    [space, currentLocationSettings]
  )

  /**
   * 判断是否是普通员工, space_roles是空的
   * @returns
   */
  useEffect(() => {
    const roles = getRoles()
    const hasLocationRole = roles.find(
      (role: any) =>
        !role.location_id || role.location_id === currentLocation?.id
    )
    setIsEmployee(!hasLocationRole)
  }, [permissions, currentLocation?.id])

  const updateLocationSetting = useCallback((setting: any, value: any) => {
    set(currentLocationSettings, setting, value)
    setCurrentLocationSettings({ ...currentLocationSettings })
  })

  async function saveLocationSetting(settings: any) {
    const { response } = await api.putSettings({
      target_id: currentLocationId,
      target_type: 'Location',
      settings: JSON.stringify(settings || currentLocationSettings),
    })

    console.debug('*** saved location setting response', response)
    const localStorageLocationData = getLocalStorage('current_location')
    try {
      const location = JSON.parse(localStorageLocationData)
      location.settings = { ...currentLocationSettings, ...response }
      setLocalStorage('current_location', JSON.stringify(location))
    } catch (error) {}

    setCurrentLocationSettings({ ...currentLocationSettings, ...response })
    return response
  }

  return {
    setVisitorModuleDisabled, // 设置访客模块是否禁用
    visitorModuleDisabled, // 获取访客模块是否禁用
    setMeetingModuleDisabled, // 设置会议室模块是否禁用
    meetingModuleDisabled, // 获取会议室模块是否禁用
    setDeskModuleDisabled, // 设置工位模块是否禁用
    deskModuleDisabled, // 获取工位模块是否禁用
    getCurrentLocationId,
    currentLocationSettings, // 获得当前设置
    setCurrentLocationSettings, // memory 设置settings，未调用服务器API
    saveLocationSetting, // 调用服务器API，永久保存
    updateLocationSetting, // 更新某一个settings，未调用服务器API
    currentLocation,
    currentLocationId,
    setCurrentLocation,
    // 工位设置
    locationDeskGroupDefaultSettings,
    setLocationDeskGroupDefaultSettings,
    changeLocation,
    floors,
    setFloors,
    isEmployee, // 是否是普通用户,
    roles,
    setRoles,
    setSubmenu,
    submenu,
    setMenu,
    menu,
    userDrawerInfo,
    setUserDrawerInfo,
    isModuleEnabled,
    getEnabledModules,
  }
}

// provider, provide authenticated, login, logout
export function LocationProvider({ children }: any) {
  const location = getLocation()

  return (
    <LocationContext.Provider value={location}>
      {children}
    </LocationContext.Provider>
  )
}

// consumer
export function useLocationContext() {
  const context = React.useContext(LocationContext)
  if (context === undefined) {
    throw new Error('useLocation must be used within a LocationProvider')
  }
  return context
}
