import React from 'react'

const KBTitle = (props: any) => {
  const { level = 1, children, style, ...restProps } = props
  let fontSize
  let lineHeight
  switch (level) {
    case 1:
      fontSize = 38
      lineHeight = '46px'
      break

    case 2:
      fontSize = 30
      lineHeight = '38px'

      break
    case 9:
      fontSize = 28
      lineHeight = '36px'

      break
    case 3:
      fontSize = 24
      lineHeight = '32px'

      break
    case 4:
      fontSize = 20
      lineHeight = '28px'

      break
    case 5:
      fontSize = 18
      lineHeight = '26px'

      break
    case 6:
      fontSize = 16
      lineHeight = '24px'

      break
    case 7:
      fontSize = 14
      lineHeight = '22px'
      break
    case 8:
      fontSize = 12
      lineHeight = '18px'

      break
  }
  return (
    <span
      style={{ fontSize, lineHeight, fontWeight: 700, ...style }}
      className="title_color"
      {...restProps}
    >
      {children}
    </span>
  )
}

export default KBTitle
