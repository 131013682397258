import FREE_EMAIL_PROVIDERS from './free_email_provider_domains'

import { tr } from './utils'

const isChinaRegion = import.meta.env.VITE_REGION === 'China'

export const EMAIL_PATTERN = new RegExp(
  /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/
)

export const PASSWORD_PATTERN = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/

export const URL_PATTERN =
  /(((^https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(?::\d+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/

export const CHINA_PHONE_PATTERN =
  /^1([38]\d|5[0-35-9]|7[3678]|6[0-9]|9[0-9]|4[0-9])\d{8}$/

export const OVERSEAS_PHONE_PATTERN = /^\d+$/

export const PHONE_PATTERN = isChinaRegion
  ? CHINA_PHONE_PATTERN
  : OVERSEAS_PHONE_PATTERN

export const CHINA_ID_CARD_NUMBER_PATTERN =
  /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/

export const OVERSEAS_ID_CARD_NUMBER_PATTERN = /^.+$/

export const ID_CARD_NUMBER_PATTERN = isChinaRegion
  ? CHINA_ID_CARD_NUMBER_PATTERN
  : OVERSEAS_ID_CARD_NUMBER_PATTERN

export const TERMS_AND_PRIVACY = {
  terms: `${import.meta.env.VITE_TERMS_URL}`,

  privacy: `${import.meta.env.VITE_PRIVACY_URL}`,
}

export const emailValidator = async (rule: any, value: any) => {
  const emailRegexp = new RegExp(
    /^[a-zA-Z0-9][\-_\.\+\!\#\$\%\&\'\*\/\=\?\^\`\{\|]{0,1}([a-zA-Z0-9][\-_\.\+\!\#\$\%\&\'\*\/\=\?\^\`\{\|]{0,1})*[a-zA-Z0-9]@[a-zA-Z0-9][-\.]{0,1}([a-zA-Z][-\.]{0,1})*[a-zA-Z0-9]\.[a-zA-Z0-9]{1,}([\.\-]{0,1}[a-zA-Z]){0,}[a-zA-Z0-9]{0,}$/i
  )
  if (!emailRegexp.test(value)) {
    throw new Error(tr('validator.invalid_email'))
  }
  return true
}

// 验证企业邮箱地址
export const companyEmailValidator = async (rule: any, value: any) => {
  // TODO: 为了测试流程临时跳过企业邮箱认证
  // return true
  if (!value) return
  if (!EMAIL_PATTERN.test(value)) {
    throw new Error(tr('validator.invalid_email'))
  }
  const fields = value.split('@')
  if (fields.length < 2) return
  const domain = fields[1]
  if (FREE_EMAIL_PROVIDERS.has(domain)) {
    throw new Error(tr('validator.invalid_company_email'))
  }
  return true
}
