import React, { useCallback, useEffect, useState, useRef } from 'react'
import { io } from 'socket.io-client'

const WebsocketContext = React.createContext({})

// this is the same event name as our server. This will allow communication between the server and client possible.
const MESSAGE_EVENT = 'Message'

function getWebsocket() {
  const socketUrl = import.meta.env.VITE_SOCKET_BASE_URL
  const socketRef = useRef()
  const [lastMessage, setLastMessage] = useState()
  const [connectionState, setConnectionState] = useState('disconnected')

  useEffect(() => {
    // create a new client with our server url

    socketRef.current = io(socketUrl)

    // listen for incoming message

    socketRef.current.on(MESSAGE_EVENT, (message: any) => {
      console.log('**** socket io message received: ', message)
      setLastMessage(message)
    })

    socketRef.current.on('connect', () => {
      console.log('**** socket connected: ', socketRef.current.id)
      setConnectionState('connected')
    })

    socketRef.current.on('disconnect', () => {
      console.log('**** socket disconnected: ', socketRef.current.id)
      setConnectionState('disconnected')
    })

    return () => {
      socketRef.current.disconnect()
    }
  }, [])

  const subscribeChannel = (channel: any) => {
    socketRef.current?.emit('subscribe', channel)
  }

  const unSubscribeChannel = (channel: any) => {
    socketRef.current?.emit('unsubscribe', channel)
  }

  // send the messagee along with a sender id. The sender id would allow us to style the UI just like a message app like iOS.
  const sendWebsocketMessage = (messageBody: any) => {
    socketRef.current.emit(NEW_MESSAGE_EVENT, {
      body: messageBody,

      senderId: socketRef.current.id,
    })
  }

  return {
    sendWebsocketMessage,
    lastWebsocketMessage: lastMessage,
    connectionState,
    socket: socketRef.current,
    subscribeChannel,
    unSubscribeChannel,
  }

  // const { sendJsonMessage, lastMessage, readyState } = useWebSocket(socketUrl, {
  //   fromSocketIO: true,
  //   //Will attempt to reconnect on all close events, such as server shutting down
  //   shouldReconnect: (closeEvent) => true,
  // });

  // return {
  //   sendWebsocketMessage : sendJsonMessage,
  //   lastWebsocketMessage : lastMessage,
  //   websocketState: readyState
  // };
}

// provider, provide authenticated, login, logout
export function WebsocketProvider({ children }: any) {
  const websocket = getWebsocket()

  return (
    <WebsocketContext.Provider value={websocket}>
      {children}
    </WebsocketContext.Provider>
  )
}

// consumer
export function useWebsocketContext() {
  const context = React.useContext(WebsocketContext)
  if (context === undefined) {
    throw new Error(
      'useWebsocketContext must be used within a WebsocketProvider'
    )
  }
  return context
}
