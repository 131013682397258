import * as api from '@/services/api'

import { configApiToken } from '@/services/request'

import {
  clearLocalStorage,
  getLocalStorage,
  setLocalStorage,
} from '@/utils/localStorage'
import _, { get } from 'lodash'
import React, { useCallback, useState } from 'react'

const AuthUserContext = React.createContext({})

function AuthUser() {
  const [authenticated, setAuthenticated] = React.useState(false)
  const [currentUser, setCurrentUser] = React.useState()
  const [allUserInfo, setAllUserInfo] = React.useState({})
  const [permissions, setPermissions] = React.useState({})
  const [schedules, setSchedules] = React.useState([]) // 用户今日计划
  const [followingUsers, setFollowingUsers] = useState([]) // 关注用户
  const [departments, setDepartments] = useState([]) // 部门: 目前只支持树形 TODO: SelectDepartment, DepartmentTree 可以使用
  const [space, setSpace] = React.useState()
  const [spaceSettings, setSpaceSettings] = useState({})
  const [userSettings, setUserSettings] = useState({})
  const [initializationProgress, setInitializationProgress] = useState(0) // 初始化进度
  const [itializeSteps, setinItializeSteps] = useState([]) // 初始化设置项
  const [showProgress, setShowProgress] = useState(true) // 是否展示进度条和初始化模块

  const [timeZones, setTimeZones] = useState([])
  const [alertMsg, setAlertMsg] = useState()

  const ACTIVE_SUBSCRIPTION_STATUS = ['active', 'trial']

  try {
    if (!currentUser?.jwt_token) {
      const localUser = JSON.parse(getLocalStorage('current_user'))
      if (localUser?.user?.jwt_token) {
        setAllUserInfo(localUser)
        const locations = localUser?.locations || []
        localUser.user.locations = locations
        setCurrentUser(localUser.user)
        setSpace(localUser.space)
        setAuthenticated(true)
        configApiToken(localUser.user.jwt_token)
      }
    }
  } catch (e) {
    console.log('error getCurrentUserFromStorage', e)
    clearLocalStorage()
  }

  async function saveSpaceSettings(settings: any) {
    const { response } = await api.putSettings({
      target_id: space.id,
      target_type: 'Space',
      settings: JSON.stringify(settings || spaceSettings),
    })

    console.debug('*** saved space setting response', response)
    setSpaceSettings(response)
    return response
  }

  function login(userInfo: any) {
    console.log('*** login', userInfo)

    // initialize tidio
    // document.tidioIdentify = {
    //   distinct_id: userInfo?.user?.id, // Unique visitor ID in your system
    //   email: userInfo?.user?.email, // visitor email
    //   name: userInfo?.user?.name, // Visitor name
    //   phone: userInfo?.user?.phone_num //Visitor phone
    // };

    if (userInfo?.user?.jwt_token) {
      setAllUserInfo(userInfo)
      userInfo.user.locations = userInfo?.locations || []
      setCurrentUser(userInfo.user)
      setSpace(userInfo.space)

      window._x_space = userInfo.space?.id
      setSpaceSettings(userInfo.space_settings)
      setLocalStorage('current_user', JSON.stringify(userInfo))
      configApiToken(userInfo?.user?.jwt_token)
      if (!getLocalStorage('current_location')) {
        const newLocation = userInfo?.home_location || userInfo?.locations?.[0]
        setLocalStorage(
          'current_location',
          newLocation ? JSON.stringify(newLocation) : ''
        )
        // setCurrentLocation(newLocation)
      }
      setAuthenticated(true)
      return true
    }
    return false
  }

  function logout(callback: any) {
    window._x_space = ''

    window._x_location = ''
    clearLocalStorage('current_user')

    setCurrentUser({})
    setAuthenticated(false)
    callback && callback()
  }

  // 判断用户是否有指定权限
  // @param permission - 指定的权限，如 location.lock.update, 必须带 space., location....
  const hasPermission = (permission: any) => {
    if (permissions) {
      // 判断是字符串
      if (typeof permission === 'string') {
        const hasPerm = get(permissions, `permissions.${permission}`)
        return hasPerm
      }
      // 判断是数组
      if (permission instanceof Array) {
        for (let i = 0; i < permission.length; i++) {
          const hasPerm = get(permissions, `permissions.${permission[i]}`)
          if (hasPerm) {
            return hasPerm
          }
        }
      }
    }
    return false
  }

  const findDepartmentById = (id: any) => {
    return _findDepartmentById(departments, id)
  }

  const _findDepartmentById = (departments: any, id: any) => {
    for (let index = 0; index < departments?.length; index++) {
      const department = departments[index]
      if (department.id == id) {
        return department
      }
      const dept = _findDepartmentById(department.children, id)
      if (dept) {
        return dept
      }
    }
  }

  // 获取roles
  const getRoles = () => {
    return get(permissions, 'space_roles', [])
  }

  const getModules = useCallback(() => {
    const subs = get(space, 'subscriptions', [])
    // console.log("*** subs", subs)

    const features = _.flattenDeep(
      subs.map((sub) => sub.plan.feature?.split(','))
    ).filter((feature) => !!feature)
    return features
  }, [space])

  const hasModule = (moduleName: any) => {
    const modules = getModules()
    if (Array.isArray(moduleName)) {
      for (let index = 0; index < moduleName.length; index++) {
        const element = moduleName[index]
        return modules && modules.includes(element)
      }
    } else {
      return modules && modules.includes(moduleName)
    }
  }

  // 验证是否符合订阅级别
  // feature: plan类型
  // level: plan等级（可以为String或者Array）

  const hasPlanLevel = useCallback((feature: any, level = 'basic') => {
    const SUB_LEVELS = {
      basic: 0, // 基础版
      pro: 10, // Pro版本
      enterprise: 100, // 企业版
    }
    const subs = get(space, 'subscriptions', [])
    let levels: any = []
    if (typeof level === 'string') {
      levels.push(level)
    } else if (level instanceof Array) {
      levels = level
    }
    // pro的功能包含basic, 所以basic权限即可查看的功能pro版本也可以查看
    Object.keys(SUB_LEVELS).forEach((_level) => {
      if (SUB_LEVELS[_level] > SUB_LEVELS[level]) {
        levels.push(_level)
      }
    })

    // 筛选有效的订阅
    const activeSubs = subs.filter((sub) => {
      return (
        ACTIVE_SUBSCRIPTION_STATUS.includes(sub.unified_status) &&
        sub.plan &&
        sub.plan.feature.includes(feature) &&
        levels.includes(sub.plan.level)
      )
    })
    return activeSubs && activeSubs.length > 0
  })

  const getPlanCanUse = useCallback((feature: any, level = 'basic') => {
    const subs = get(space, 'subscriptions', [])

    const state = subs.find((sub) =>
      sub?.plan?.feature.includes(feature)
    )?.unified_status
    const needSubscriptions = ['past_due', 'expired'].includes(state) // 需要订阅--已过期或者欠费
    const needUpgrade =
      ['active', 'trial'].includes(state) && !hasPlanLevel(feature, level) // 需要升级--订阅的level不够

    return {
      needSubscriptions,
      needUpgrade,
      canUse: !needSubscriptions && !needUpgrade,
    }
  })

  const hasFaceModule = () => {
    return (
      hasModule('face') ||
      hasModule('darwin') ||
      hasModule('hikvision') ||
      hasModule('lock')
    )
  }

  const getLocations = () => {
    return currentUser?.locations || []
  }

  // 获取空间货币单位
  const getCurrency = () => {
    const isChinaRegion = import.meta.env.VITE_REGION === 'China'
    const defaultCurrency = isChinaRegion ? '￥' : '$'
    return get(space, 'subscription.balance.symbol', defaultCurrency)
  }

  return {
    allUserInfo, // 所有home数据
    authenticated,
    setAuthenticated,
    currentUser,
    getCurrency,
    setCurrentUser,
    getRoles,
    permissions,
    setPermissions,
    hasPermission,
    timeZones,
    setTimeZones,
    departments,
    setDepartments,
    findDepartmentById,
    hasModule,
    hasFaceModule,
    getModules,
    hasPlanLevel,
    getPlanCanUse,
    getLocations,
    space,
    setSpace,
    spaceSettings,
    setSpaceSettings,
    userSettings,
    setUserSettings,
    saveSpaceSettings,
    schedules,
    setSchedules,
    followingUsers,
    setFollowingUsers,
    login,
    logout,
    alertMsg,
    setAlertMsg,
    initializationProgress,
    setInitializationProgress,
    itializeSteps,
    setinItializeSteps,
    showProgress,
    setShowProgress,
    setAllUserInfo,
  }
}

// provider, provide authenticated, login, logout
export function AuthenticationProvider({ children }: any) {
  const auth = AuthUser()

  return (
    <AuthUserContext.Provider value={auth}>{children}</AuthUserContext.Provider>
  )
}

// consumer
export function useAuthentication() {
  const context = React.useContext(AuthUserContext)
  if (context === undefined) {
    throw new Error('useAuthentication must be used within a AuthUserContext')
  }
  return context
}
