import { useEffect } from 'react'
import { useRoutes, useNavigate } from 'react-router-dom'

import { LocationProvider } from '@/contexts/LocationContext'

import { AuthenticationProvider } from '@/contexts/AuthContext'

import routes from '@/router.config'

import { VisitorProvider } from '@/contexts/VisitorContext'

import { LangProvider } from '@/contexts/LangContext'

import { ThemeProvider } from '@/contexts/ThemeContext'
import NiceModal from '@ebay/nice-modal-react'

import { WebsocketProvider } from './contexts/WebsocketContext'

import DocumentTitle from 'react-document-title'

import VersionCheck from './pages/VersionCheck'

import packageJson from '../package.json'

import { UserProvider } from '@/contexts/UserContext'

import * as Sentry from '@sentry/react'
import { wrapUseRoutes } from '@sentry/react'
import ErrorFallback from './components/ErrorBoundary/ErrorFallback'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

const useSentryRoutes = wrapUseRoutes(useRoutes)
const queryClient = new QueryClient()
function RouteContent() {
  const navigate = useNavigate()
  useEffect(() => {
    window.g_history = navigate
  }, [])
  return <>{useSentryRoutes(routes)}</>
}


function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <LangProvider>
        <ThemeProvider>
          <AuthenticationProvider>
            <WebsocketProvider>
              <LocationProvider>
                <VisitorProvider>
                  <UserProvider>
                    <NiceModal.Provider>
                      <VersionCheck currentVersion={packageJson.version} />
                      <DocumentTitle
                        title={`${import.meta.env.VITE_BRANDING_LOCALIZED_NAME}`}
                      >
                        <RouteContent />
                      </DocumentTitle>
                    </NiceModal.Provider>
                  </UserProvider>
                </VisitorProvider>
              </LocationProvider>
            </WebsocketProvider>
          </AuthenticationProvider>
        </ThemeProvider>
      </LangProvider>
    </QueryClientProvider>
  )
}

const myFallback = ({ error, resetError }: { error: any; resetError: any }) => (
  <ErrorFallback error={error} resetError={resetError} />
)

export default function () {
  if (import.meta.env.VITE_NODE_ENV === 'production') {
    return (
      <Sentry.ErrorBoundary fallback={myFallback}>
        <App />
      </Sentry.ErrorBoundary>
    )
  }

  return <App />
}
