import noSubsctiptionAnalytics from '@/assets/noSubscription/noSubsctiptionAnalytics.png'

import noSubsctiptionMeeting from '@/assets/noSubscription/noSubsctiptionMeeting.png'

import noSubsctiptionVisitor from '@/assets/noSubscription/noSubsctiptionVisitor.png'

// TODO: taoh - make this configurable from the backend
export const MenuWithPlanLevel = [
  {
    // 数据统计 =》 工位
    url: '/admin/locations/statistics/desk/analytics',
    feature: 'desk',
    level: 'pro',
    name: 'menu.desk.analytics',
    subscribeTitle: 'subscription.noSubscription.desk.analytics.subscribeTitle',
    subscribeSubtitle:
      'subscription.noSubscription.desk.analytics.subscribeSubtitle',
    upgradeTitle: 'subscription.noSubscription.desk.analytics.upgradeTitle',
    upgradeSubtitle:
      'subscription.noSubscription.desk.analytics.upgradeSubtitle',
    subscribeImg: noSubsctiptionAnalytics,
    upgradeImg: noSubsctiptionAnalytics,
  },
  {
    // 数据统计 =》 混合办公
    url: '/admin/locations/statistics/hybridWork/analytics',
    feature: 'desk',
    level: 'pro',
    name: 'menu.hybridWork.settings',
    subscribeTitle:
      'subscription.noSubscription.hybridWork.settings.subscribeTitle',
    subscribeSubtitle:
      'subscription.noSubscription.hybridWork.settings.subscribeSubtitle',
    upgradeTitle:
      'subscription.noSubscription.hybridWork.settings.upgradeTitle',
    upgradeSubtitle:
      'subscription.noSubscription.hybridWork.settings.upgradeSubtitle',
    subscribeImg: noSubsctiptionAnalytics,
    upgradeImg: noSubsctiptionAnalytics,
  },
  {
    // 数据统计 =》 会议室
    url: '/admin/locations/statistics/meeting/statistics',
    feature: 'meeting',
    name: 'menu.meeting.statistics',
    level: 'pro',
    subscribeTitle:
      'subscription.noSubscription.meeting.statistics.subscribeTitle',
    subscribeSubtitle:
      'subscription.noSubscription.meeting.statistics.subscribeSubtitle',
    upgradeTitle: 'subscription.noSubscription.meeting.statistics.upgradeTitle',
    upgradeSubtitle:
      'subscription.noSubscription.meeting.statistics.upgradeSubtitle',
    subscribeImg: noSubsctiptionAnalytics,
    upgradeImg: noSubsctiptionAnalytics,
  },
  {
    // 会议室 =》 会务设置 =》 会务管理
    url: '/admin/locations/meeting/business/management',
    feature: 'meeting',
    name: 'menu.meeting.business.management',
    level: 'pro',
    subscribeTitle:
      'subscription.noSubscription.meeting.business.management.subscribeTitle',
    subscribeSubtitle:
      'subscription.noSubscription.meeting.business.management.subscribeSubtitle',
    upgradeTitle:
      'subscription.noSubscription.meeting.business.management.upgradeTitle',
    upgradeSubtitle:
      'subscription.noSubscription.meeting.business.management.upgradeSubtitle',
    subscribeImg: noSubsctiptionMeeting,
    upgradeImg: noSubsctiptionMeeting,
  },
  {
    // 会议室 =》 会务设置 =》 会务订单
    url: '/admin/locations/meeting/business/detail',
    feature: 'meeting',
    name: 'menu.meeting.business.detail',
    level: 'pro',
    subscribeTitle:
      'subscription.noSubscription.meeting.business.detail.subscribeTitle',
    subscribeSubtitle:
      'subscription.noSubscription.meeting.business.detail.subscribeSubtitle',
    upgradeTitle:
      'subscription.noSubscription.meeting.business.detail.upgradeTitle',
    upgradeSubtitle:
      'subscription.noSubscription.meeting.business.detail.upgradeSubtitle',
    subscribeImg: noSubsctiptionMeeting,
    upgradeImg: noSubsctiptionMeeting,
  },
  {
    // 访客记录 =》 访客（数据分析）
    url: '/admin/space/visiter_settings/statistics',
    feature: 'visitor',
    name: 'menu.visitors.visitorStatistics',
    level: 'pro',
    subscribeTitle:
      'subscription.noSubscription.visitors.visitorStatistics.subscribeTitle',
    subscribeSubtitle:
      'subscription.noSubscription.visitors.visitorStatistics.subscribeSubtitle',
    upgradeTitle:
      'subscription.noSubscription.visitors.visitorStatistics.upgradeTitle',
    upgradeSubtitle:
      'subscription.noSubscription.visitors.visitorStatistics.upgradeSubtitle',
    subscribeImg: noSubsctiptionAnalytics,
    upgradeImg: noSubsctiptionAnalytics,
  },
  {
    // 访客记录 =》 法律文件
    url: '/admin/space/visiter_settings/legal_documents',
    feature: 'visitor',
    name: 'menu.visitors.legalDocument',
    level: 'pro',
    subscribeTitle:
      'subscription.noSubscription.visitors.legalDocument.subscribeTitle',
    subscribeSubtitle:
      'subscription.noSubscription.visitors.legalDocument.subscribeSubtitle',
    upgradeTitle:
      'subscription.noSubscription.visitors.legalDocument.upgradeTitle',
    upgradeSubtitle:
      'subscription.noSubscription.visitors.legalDocument.upgradeSubtitle',
    subscribeImg: noSubsctiptionVisitor,
    upgradeImg: noSubsctiptionVisitor,
  },
  {
    // 访客 =》 访客设置 =》 审批设置
    url: '/admin/locations/visitors/settings/approval',
    feature: 'visitor',
    name: 'menu.visitors.settings.approval',
    level: 'pro',
    subscribeTitle:
      'subscription.noSubscription.visitors.settings.approval.subscribeTitle',
    subscribeSubtitle:
      'subscription.noSubscription.visitors.settings.approval.subscribeSubtitle',
    upgradeTitle:
      'subscription.noSubscription.visitors.settings.approval.upgradeTitle',
    upgradeSubtitle:
      'subscription.noSubscription.visitors.settings.approval.upgradeSubtitle',
    subscribeImg: noSubsctiptionVisitor,
    upgradeImg: noSubsctiptionVisitor,
  },
  {
    // 数据统计 =》 访客
    url: '/admin/locations/statistics/visitors/statistics',
    name: 'menu.visitors.statistics',
    feature: 'visitor',
    level: 'pro',
    subscribeTitle:
      'subscription.noSubscription.visitors.statistics.subscribeTitle',
    subscribeSubtitle:
      'subscription.noSubscription.visitors.statistics.subscribeSubtitle',
    upgradeTitle:
      'subscription.noSubscription.visitors.statistics.upgradeTitle',
    upgradeSubtitle:
      'subscription.noSubscription.visitors.statistics.upgradeSubtitle',
    subscribeImg: noSubsctiptionAnalytics,
    upgradeImg: noSubsctiptionAnalytics,
  },
  {
    // 访客 =》 访客设置 =》 门禁设置
    url: '/admin/locations/visitors/settings/locks',
    name: 'menu.visitors.settings.locks',
    feature: 'visitor',
    level: 'pro',
    subscribeTitle:
      'subscription.noSubscription.visitors.settings.locks.subscribeTitle',
    subscribeSubtitle:
      'subscription.noSubscription.visitors.settings.locks.subscribeSubtitle',
    upgradeTitle:
      'subscription.noSubscription.visitors.settings.locks.upgradeTitle',
    upgradeSubtitle:
      'subscription.noSubscription.visitors.settings.locks.upgradeSubtitle',
    subscribeImg: noSubsctiptionVisitor,
    upgradeImg: noSubsctiptionVisitor,
  },
]
