// import { lazy } from 'react'

import reactLazyWithRetry from '@fatso83/retry-dynamic-import/react-lazy'
const Login = reactLazyWithRetry(() => import('../pages/User/Login'))

const RegisterLayout = reactLazyWithRetry(
  () => import('../layouts/RegisterLayout')
)

const Signup = reactLazyWithRetry(() => import('../pages/User/Signup'))

const ActivationEmail = reactLazyWithRetry(
  () => import('../pages/User/ActivationEmail')
)

const ChangePassword = reactLazyWithRetry(
  () => import('../pages/User/ChangePassword')
)

const ChangePasswordSuccess = reactLazyWithRetry(
  () => import('../pages/User/ChangePasswordSuccess')
)

const SignupSuccess = reactLazyWithRetry(
  () => import('../pages/User/SignupSuccess')
)

const LockEmail = reactLazyWithRetry(() => import('@/pages/User/LockEmail'))

const LockEmailSuccess = reactLazyWithRetry(
  () => import('@/pages/User/LockEmailSuccess')
)

const InitialPassword = reactLazyWithRetry(
  () => import('@/pages/User/InitialPassword')
)

const TeamsLogin = reactLazyWithRetry(() => import('@/pages/User/TeamsLogin'))

const TeamsRegister = reactLazyWithRetry(
  () => import('@/pages/User/TeamsRegister')
)

const routes = {
  path: '/',
  context: 'login',

  element: <RegisterLayout />,
  permission: 'all',
  children: [
    { path: '', element: <Login /> },

    { path: 'login', element: <Login /> },

    { path: 'teams_login', element: <TeamsLogin /> },

    { path: 'signup', element: <Signup /> },

    { path: 'signup_success', element: <SignupSuccess /> },

    { path: 'confirm_email', element: <ActivationEmail /> },

    { path: 'lock_email', element: <LockEmail /> },

    { path: 'lock_email_success', element: <LockEmailSuccess /> },

    { path: 'change_password', element: <ChangePassword /> },

    { path: 'initialize_password', element: <InitialPassword /> },

    { path: 'teams_register', element: <TeamsRegister /> },

    {
      path: 'change_password_success',
      element: <ChangePasswordSuccess />,
    },
  ],
}

export default routes
