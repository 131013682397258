import request from '@/services/request'
// import * as config from '../config';
// /*
// API请求地址
//  */

// const BASE_URL = `${urlScheme}://${import.meta.env.VITE_API_BASE_URL}`;

export function apiPOST(url: any, data = {}, restProps = {}) {
  return request({
    url,
    method: 'post',
    data,
    ...restProps,
  })
}

export function apiGET(url: any, params = {}, restProps = {}) {
  return request({
    url,
    method: 'get',
    params,
    ...restProps,
  })
}

export function apiPUT(url: any, data = {}, restProps = {}) {
  return request({
    url,
    method: 'put',
    data,
    ...restProps,
  })
}

export function apiDELETE(url: any, params = {}, restProps = {}) {
  return request({
    url,
    method: 'delete',
    params,
    ...restProps,
  })
}

export const apiFileUpload = (
  url: any,
  formData = {},
  method = 'put',
  restProps = {}
) => {
  return request({
    url,
    method,
    data: formData,
    timeout: 120000,
    config: { headers: { 'Content-Type': 'multipart/form-data' } },
    ...restProps,
  })
}
