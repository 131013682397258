import React from 'react'
import { Tooltip } from 'antd'

import styles from './KBText.module.less'

function KBText(props: any) {
  const {
    level = 2,
    style,
    children,
    strong = false,
    ellipsis = {},
    className,
    ...restProps
  } = props
  const { tooltip } = ellipsis
  let fontSize
  let lineHeight
  switch (level) {
    case 1:
      fontSize = 16
      lineHeight = '24px'
      break
    case 2:
      fontSize = 14
      lineHeight = '22px'
      break
    case 3:
      fontSize = 12
      lineHeight = '18px'
      break
    case 4:
      fontSize = 10
      lineHeight = '14px'
      break
  }
  const content = (
    <span
      className={
        ellipsis.tooltip
          ? `${styles.ellipsis} text_color ${className}`
          : `text_color ${className}`
      }
      style={{ fontSize, lineHeight, fontWeight: strong ? 700 : 400, ...style }}
      {...restProps}
    >
      {children || null}
    </span>
  )
  if (tooltip) {
    return <Tooltip title={tooltip}>{content}</Tooltip>
  }
  return content
}

export default KBText
