// color里定义的都是16进制的颜色
export const GREEN = '#22C55E'
export const GREEN2 = '#057A55'
export const GREEN3 = '#DEF7EC'
export const GREEN4 = '#31C48D'
export const RED = '#FF4747'
export const ORANGE = '#FF9800'
export const GRAY = '#5D6A83'
export const GRAY2 = '#4B5563'
export const GRAY3 = '#F3F4F6'
export const GRAY4 = '#D1D5DB'
export const BLUE = '#137EFF'
export const PURPLE = '#9C27B0'

export const chartColors = [
  '#3D7BE1',
  '#22C38E',
  '#FACC15',
  '#AF5FFB',
  '#6366F1',
  '#F59E0B',
  '#EC4899',
  '#EB5757',
  '#722ED1',
  '#13C2C2',
  '#21B468',
  '#009CCF',
  '#7CB305',
  '#D0D5DD',
]

export const mergeColorArray = (array: any, key = 'color') => {
  if (!array) return []

  return array?.map((item: any, index: any) => {
    if (item.notReplaceColor) {
      return item
    }

    return {
      ...item,
      [key]: chartColors[index],
    }
  })
}
