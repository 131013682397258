// import { lazy } from 'react'
import reactLazyWithRetry from '@fatso83/retry-dynamic-import/react-lazy'
import { Navigate } from 'react-router-dom'

const VisitorDetailPage = reactLazyWithRetry(
  () => import('@/pages/Visitor/VisitorDetail/VisitorDetailPage')
)

const ActivityLayout = reactLazyWithRetry(
  () => import('@/layouts/ActivityLayout')
)

const LocationLayout = reactLazyWithRetry(
  () => import('@/layouts/LocationLayout')
)

const MeetingRoomLayout = reactLazyWithRetry(
  () => import('@/layouts/MeetingRoomLayout')
)

const ActivityDetail = reactLazyWithRetry(
  () => import('@/pages/Activity/ActivityDetail')
)

const ActivityForm = reactLazyWithRetry(
  () => import('@/pages/Activity/ActivityForm')
)

const ActivityList = reactLazyWithRetry(
  () => import('@/pages/Activity/ActivityList')
)

const ActivityType = reactLazyWithRetry(
  () => import('@/pages/Activity/ActivityTypes')
)
const ActivityEmailSetting = reactLazyWithRetry(
  () => import('@/pages/Activity/EmailSetting')
)

const Announcements = reactLazyWithRetry(
  () => import('@/pages/Announcements/Announcements')
)

const AuditLogs = reactLazyWithRetry(() => import('@/pages/AuditLog/AuditLogs'))

const BorrowingForm = reactLazyWithRetry(
  () => import('@/pages/Borrowings/BorrowingForm')
)
const BorrowingHistory = reactLazyWithRetry(
  () => import('@/pages/Borrowings/BorrowingHistory')
)
const BorrowingLoanForm = reactLazyWithRetry(
  () => import('@/pages/Borrowings/BorrowingLoanForm')
)

const Borrowings = reactLazyWithRetry(
  () => import('@/pages/Borrowings/Borrowings')
)

const BindPadForm = reactLazyWithRetry(
  () => import('@/pages/Cabinets/BindPadForm')
)
const CabinetCreateForm = reactLazyWithRetry(
  () => import('@/pages/Cabinets/CabinetCreateForm')
)

const CabinetRecord = reactLazyWithRetry(
  () => import('@/pages/Cabinets/CabinetRecord')
)

const Cabinets = reactLazyWithRetry(() => import('@/pages/Cabinets/Cabinets'))

const EditLockerForm = reactLazyWithRetry(
  () => import('@/pages/Cabinets/EditLockerForm')
)
const ReserveLockerForm = reactLazyWithRetry(
  () => import('@/pages/Cabinets/ReserveLockerForm')
)

const CabinetSetting = reactLazyWithRetry(
  () => import('@/pages/Cabinets/Setting')
)
const RepairEmailSetting = reactLazyWithRetry(
  () => import('@/pages/ControlRepair/EmailSetting')
)

const RepairSetting = reactLazyWithRetry(
  () => import('@/pages/ControlRepair/RepairSetting')
)

const EnergyConsumptionStatistic = reactLazyWithRetry(
  () => import('@/pages/Statistics/SpaceDir/EnergyConsumptionStatistic')
)

const RepairTypes = reactLazyWithRetry(
  () => import('@/pages/ControlRepair/RepairTypes')
)

const TaskList = reactLazyWithRetry(
  () => import('@/pages/ControlRepair/TaskList')
)

const AddAreaForm = reactLazyWithRetry(
  () => import('@/pages/Desk/Areas/AreaFormModal')
)

const AssignMap = reactLazyWithRetry(
  () => import('@/pages/Desk/Assign/AssignMap')
)

const BatchArea = reactLazyWithRetry(() => import('@/pages/Desk/BatchArea'))
const ColleagueCalendar = reactLazyWithRetry(
  () => import('@/pages/Desk/Calendar/ColleagueCalendar')
)

const DeskGroups = reactLazyWithRetry(
  () => import('@/pages/Desk/DeskGroups/DeskGroups')
)

const DeskHome = reactLazyWithRetry(() => import('@/pages/Desk/Home/Home'))

const MapDesigner = reactLazyWithRetry(
  () => import('@/pages/Desk/MapEditor/MapDesigner')
)
const ReservationRecords = reactLazyWithRetry(
  () => import('@/pages/Desk/Reservations/ReservationRecords')
)
const ReserveRecordDetail = reactLazyWithRetry(
  () => import('@/pages/Desk/Reservations/ReserveRecordDetail')
)
const DeskEmailSetting = reactLazyWithRetry(
  () => import('@/pages/Desk/Settings/DeskEmailSettings')
)

const DeskSettings = reactLazyWithRetry(
  () => import('@/pages/Desk/Settings/DeskSettings')
)
const LocationDeskStatistic = reactLazyWithRetry(
  () => import('@/pages/Statistics/LocationDir/LocationDeskStatistic')
)
const LocationHybridWorkStatistic = reactLazyWithRetry(
  () => import('@/pages/Statistics/LocationDir/LocationHybridWorkStatistic')
)

const UsageRecords = reactLazyWithRetry(
  () => import('@/pages/Desk/UsageRecords/UsageRecords')
)

const AlertRecord = reactLazyWithRetry(
  () => import('@/pages/Device/AlertRecord')
)

const DeviceLayout = reactLazyWithRetry(
  () => import('@/pages/Device/DeviceLayout')
)

const Devices = reactLazyWithRetry(() => import('@/pages/Device/Devices'))

const DevicesAlarmNotification = reactLazyWithRetry(
  () => import('@/pages/Device/DevicesAlarmNotification')
)

const DeviceDashboard = reactLazyWithRetry(
  () => import('@/pages/Device/DeviceDashboard')
)

const IntelligentLinkageTab = reactLazyWithRetry(
  () => import('@/pages/Device/IntelligentLinkageTab')
)

const ExecutionRecord = reactLazyWithRetry(
  () => import('@/pages/Device/ExecutionRecord')
)

const DeviceSettings = reactLazyWithRetry(
  () => import('@/pages/Device/DeviceSettings')
)

const Health = reactLazyWithRetry(() => import('@/pages/Health/Health'))

const HealthLimiting = reactLazyWithRetry(
  () => import('@/pages/Health/HealthLimiting')
)

const HealthList = reactLazyWithRetry(() => import('@/pages/Health/HealthList'))

const Lighting = reactLazyWithRetry(() => import('@/pages/Light/Light'))

const BuildingForm = reactLazyWithRetry(
  () => import('@/pages/Locations/BuildingForm')
)
const EditLocationForm = reactLazyWithRetry(
  () => import('@/pages/Locations/EditLocationForm')
)

const LocationSettingBoarding = reactLazyWithRetry(
  () => import('@/pages/Locations/LocationSettingBoarding')
)
const LocationClientSettings = reactLazyWithRetry(
  () => import('@/pages/Locations/LocationTimeMode/LocationClientSettings')
)
const LocationTimeMode = reactLazyWithRetry(
  () => import('@/pages/Locations/LocationTimeMode/LocationTimeMode')
)

const NotifySetting = reactLazyWithRetry(
  () => import('@/pages/Locations/NotifySetting')
)
const SchedulerSettings = reactLazyWithRetry(
  () => import('@/pages/Locations/SchedulerSettings')
)
const LocationSetupGuide = reactLazyWithRetry(
  () => import('@/pages/Locations/Setup/LocationSetupGuide')
)

const AddLockForm = reactLazyWithRetry(() => import('@/pages/Lock/AddLockForm'))
const AddLockPermissionsForm = reactLazyWithRetry(
  () => import('@/pages/Lock/AddLockPermissionsForm')
)

const AddWorkingLeaves = reactLazyWithRetry(
  () => import('@/pages/Lock/AddWorkingLeaves')
)

const AddWorkingTrips = reactLazyWithRetry(
  () => import('@/pages/Lock/AddWorkingTrips')
)
const AttendanceSettings = reactLazyWithRetry(
  () => import('@/pages/Lock/AttendanceSettings')
)

const AttendanceStats = reactLazyWithRetry(
  () => import('@/pages/Lock/AttendanceStats.jsx')
)

const LeavesManage = reactLazyWithRetry(
  () => import('@/pages/Lock/LeavesManage')
)

const LockDetail = reactLazyWithRetry(() => import('@/pages/Lock/LockDetail'))

const LockEvents = reactLazyWithRetry(() => import('@/pages/Lock/LockEvents'))

const LockGroups = reactLazyWithRetry(() => import('@/pages/Lock/LockGroups'))

const LockManage = reactLazyWithRetry(() => import('@/pages/Lock/LockManage'))
const LockPermissionsManage = reactLazyWithRetry(
  () => import('@/pages/Lock/LockPermissionsManage')
)

const LockSetting = reactLazyWithRetry(() => import('@/pages/Lock/LockSetting'))

const TripsManage = reactLazyWithRetry(() => import('@/pages/Lock/TripsManage'))
const AddMeetingDeviceForm = reactLazyWithRetry(
  () => import('@/pages/Meetings/AddMeetingDeviceForm')
)

const AddMeetingForm = reactLazyWithRetry(
  () => import('@/pages/Meetings/AddMeetingForm')
)
const BusinessDetail = reactLazyWithRetry(
  () => import('@/pages/Meetings/BusinessDetail/BusinessDetail')
)
const BusinessManagement = reactLazyWithRetry(
  () => import('@/pages/Meetings/BusinessManagement/BusinessManagement')
)

const EditMeetingForm = reactLazyWithRetry(
  () => import('@/pages/Meetings/EditMeetingForm')
)

const MeetingRooms = reactLazyWithRetry(
  () => import('@/pages/Meetings/MeetingRooms')
)

const MeetingSensor = reactLazyWithRetry(
  () => import('@/pages/Meetings/MeetingSensor')
)

const MeetingSensors = reactLazyWithRetry(
  () => import('@/pages/Meetings/MeetingSensors')
)

const LocationMeetingStatisticBox = reactLazyWithRetry(
  () => import('@/pages/Statistics/LocationDir/LocationMeetingStatisticBox')
)
const LocationRepairStatistic = reactLazyWithRetry(
  () => import('@/pages/Statistics/LocationDir/LocationRepairStatistic')
)
const LocationDeliveryStatistic = reactLazyWithRetry(
  () => import('@/pages/Statistics/LocationDir/LocationDeliveryStatistic')
)

const NewMeetingInfo = reactLazyWithRetry(
  () => import('@/pages/Meetings/NewMeetingInfo')
)

const ReservationInfo = reactLazyWithRetry(
  () => import('@/pages/Meetings/ReservationInfo')
)

const ImportMeeting = reactLazyWithRetry(
  () => import('@/pages/Meetings/ImportMeeting')
)
const ReservationManage = reactLazyWithRetry(
  () => import('@/pages/Meetings/ReservationManage')
)
const ReservationRecord = reactLazyWithRetry(
  () => import('@/pages/Meetings/ReservationRecord')
)
const MeetingSettings = reactLazyWithRetry(
  () => import('@/pages/Meetings/Settings/MeetingSettings')
)

const AddMembersForm = reactLazyWithRetry(
  () => import('@/pages/Members/AddMembersForm')
)
const BatchEnterMembers = reactLazyWithRetry(
  () => import('@/pages/Members/BatchEnterMembers')
)

const MemberDeskForm = reactLazyWithRetry(
  () => import('@/pages/Members/MemberDeskForm')
)

const MemberInfo = reactLazyWithRetry(
  () => import('@/pages/Members/MemberInfo')
)

const Members = reactLazyWithRetry(() => import('@/pages/Members/Members'))
const MemberAddLocationRole = reactLazyWithRetry(
  () => import('@/pages/Roles/MemberAddLocationRole')
)

const MemberRoleManage = reactLazyWithRetry(
  () => import('@/pages/Roles/MemberRoleManage')
)

const Automation = reactLazyWithRetry(
  () => import('@/pages/Scene/Manage/Automation')
)

const SceneMode = reactLazyWithRetry(
  () => import('@/pages/Scene/Manage/SceneMode')
)

const ScreenForm = reactLazyWithRetry(
  () => import('@/pages/Screens/ScreenForm')
)

const ScreenManage = reactLazyWithRetry(
  () => import('@/pages/Screens/ScreenManage')
)
const ScreenTemplateForm = reactLazyWithRetry(
  () => import('@/pages/Screens/ScreenTemplateForm')
)
const ScreenTemplatesManage = reactLazyWithRetry(
  () => import('@/pages/Screens/ScreenTemplatesManage')
)

const UserSettingForm = reactLazyWithRetry(
  () => import('@/pages/User/UserSettingForm')
)
const AddVisitorDeviceForm = reactLazyWithRetry(
  () => import('@/pages/Visitor/AddVisitorDeviceForm')
)

const InviteForm = reactLazyWithRetry(
  () => import('@/pages/Visitor/InviteForm')
)
const SafetySetting = reactLazyWithRetry(
  () => import('@/pages/Visitor/Settings/SafetySetting')
)
const EditVisitorType = reactLazyWithRetry(
  () => import('@/pages/Visitor/Settings/Types/EditVisitorType')
)
const VisitorAddVisitorType = reactLazyWithRetry(
  () => import('@/pages/Visitor/Settings/Types/VisitorAddVisitorType')
)
const VisitorApprovalSetting = reactLazyWithRetry(
  () => import('@/pages/Visitor/Settings/VisitorApprovalSetting')
)
const VisitorEmail = reactLazyWithRetry(
  () => import('@/pages/Visitor/Settings/VisitorEmail')
)
const VisitorHelloSetting = reactLazyWithRetry(
  () => import('@/pages/Visitor/Settings/VisitorHelloSetting')
)
const VisitorInvitationSetting = reactLazyWithRetry(
  () => import('@/pages/Visitor/Settings/VisitorInvitationSetting')
)
const VisitorLockSetting = reactLazyWithRetry(
  () => import('@/pages/Visitor/Settings/VisitorLockSetting')
)
const VisitorNotify = reactLazyWithRetry(
  () => import('@/pages/Visitor/Settings/VisitorNotIfy')
)

const VisitorSign = reactLazyWithRetry(
  () => import('@/pages/Visitor/Settings/VisitorSign')
)
const VisitorSignout = reactLazyWithRetry(
  () => import('@/pages/Visitor/Settings/VisitorSignout')
)
const VisitorTypeSetting = reactLazyWithRetry(
  () => import('@/pages/Visitor/Settings/visitorTypeSetting')
)

const SignInForm = reactLazyWithRetry(
  () => import('@/pages/Visitor/SignInForm')
)

const VisitorDevices = reactLazyWithRetry(
  () => import('@/pages/Visitor/VisitorDevices')
)
const VisitorInviteList = reactLazyWithRetry(
  () => import('@/pages/Visitor/VisitorInviteList')
)

const VisitorLayout = reactLazyWithRetry(
  () => import('@/pages/Visitor/VisitorLayout')
)
const HybridWorkLayout = reactLazyWithRetry(
  () => import('@/pages/HybridWork/HybridWorkLayout')
)

const VisitorManage = reactLazyWithRetry(
  () => import('@/pages/Visitor/VisitorManage')
)

const LocationVisitorStatistic = reactLazyWithRetry(
  () => import('@/pages/Statistics/LocationDir/LocationVisitorStatistic')
)

const SpaceInitialize = reactLazyWithRetry(
  () => import('@/pages/Locations/SpaceInitialize')
)

const DeskLayout = reactLazyWithRetry(() => import('@/layouts/DeskLayout'))

const Screen = reactLazyWithRetry(() => import('@/pages/Screen/Screen'))
const HybridWorkSettings = reactLazyWithRetry(
  () => import('@/pages/HybridWork/Settings/HybridWorkSettings')
)
const EmployeeSignins = reactLazyWithRetry(
  () => import('@/pages/HybridWork/EmployeeSignins')
)

const EmployeeStats = reactLazyWithRetry(
  () => import('@/pages/HybridWork/EmployeeStats')
)

const Approvals = reactLazyWithRetry(
  () => import('@/pages/HybridWork/Approvals')
)
const LegalDocument = reactLazyWithRetry(
  () => import('@/pages/Visitor/Settings/Types/LegalDocument.jsx')
)

const Dashboard = reactLazyWithRetry(
  () => import('@/pages/Locations/Homepage/Dashboard')
)
const MeetingPadDevice = reactLazyWithRetry(
  () => import('@/pages/Meetings/MeetingPadDevice')
)
const SpaceHybridWorkAnalytics = reactLazyWithRetry(
  () => import('@/pages/HybridWork/SpaceHybridWorkAnalytics')
)

const AddScreen = reactLazyWithRetry(() => import('@/pages/Screen/AddScreen'))

const LocationAreaManage = reactLazyWithRetry(
  () => import('@/pages/LocationAreaManage')
)

const MediaLibrary = reactLazyWithRetry(() => import('@/pages/MediaLibrary'))

const NoSubscriptionContent = reactLazyWithRetry(
  () => import('@/pages/NoSubscriptionPage')
)

const UnsubscribedPage = reactLazyWithRetry(
  () => import('@/pages/NoSubscriptionPage')
)

const NoSubscriptionPage = reactLazyWithRetry(
  () => import('@/pages/NoSubscriptionPage')
)

const BusinessSetting = reactLazyWithRetry(
  () => import('@/pages/Meetings/BusinessSetting/BusinessSetting')
)

const isChina = import.meta.env.VITE_REGION === 'China'

const routes = {
  path: 'locations',

  element: <LocationLayout />,
  context: 'location',
  permission: 'all',
  children: [
    /**
     * 分店首页
     */
    {
      name: 'location.index',
      icon: 'menu-data_analysis_line',
      path: '',
      index: true,
      hideInMenu: true,
      permission: 'location.location.manage',

      element: <Navigate to="/admin/locations/dashboard" replace={true} />,
    },
    {
      name: 'location.dashboard',
      icon: 'menu-data_analysis_line',
      path: 'dashboard',
      permission: 'location.location.manage',
      hideInMenu: false,

      element: <Dashboard />,
    },
    // {
    //   path: "bookDesk",
    //   permission: "all",
    //   hideInMenu: true,
    //   element: <BookDesk />,
    // },
    // {
    //   name: "calendar",
    //   path: "calendar",
    //   icon: "menu-calendar_line",
    //   permission: "all",
    //   element: <ColleagueCalendar />,
    //   // hideInMenu: true,
    // },
    {
      name: 'hybridWork',
      icon: 'menu-large_data_screen',
      path: 'hybridWork',

      element: <HybridWorkLayout />,
      hideInMenu: false,
      permission: 'location.location.manage', // TODO: daming, 需要加权限判断，只有分店管理员们可见
      children: [
        {
          element: <EmployeeSignins />,
          name: 'hybridWork.employeeSignins',
          path: 'hybridWork/signins',
          permission: 'location.location.manage',
        },
        {
          element: <Approvals />,
          name: 'hybridWork.approvals',
          path: 'hybridWork/approvals',
          permission: 'location.location.manage',
        },

        {
          element: <EmployeeStats />,
          name: 'hybridWork.reportExport',
          path: 'hybridWork/stats',
          permission: 'location.location.manage',
        },
        {
          element: <HybridWorkSettings />,
          name: 'hybridWork.settings',
          path: 'settings',
          permission: 'location.location.manage',
        },
      ],
    },
    {
      permission: 'all',
      path: 'locationInitialize',

      element: <SpaceInitialize />,
      hideInMenu: true,
    },
    // 健康
    {
      name: 'health',
      icon: 'menu-healthy_line',
      modules: ['health'],
      path: 'health',
      permission: 'all',
      hideInMenu: false,
      children: [
        {
          name: 'healthSet',

          element: <Health />,
          permission: 'all',
          path: 'set',
        },
        {
          name: 'healthList',

          element: <HealthList />,
          permission: 'all',
          path: 'list',
        },
        {
          name: 'healthLimiting',

          element: <HealthLimiting />,
          permission: 'all',
          path: 'limiting',
        },
      ],
    },
    // {
    //   name: "calendar",
    //   path: "calendar",
    //   icon: "menu-calendar_line",
    //   permission: "all",
    //   element: <ColleagueCalendar />,
    // },

    // {
    //   name: "calendar",
    //   path: "calendars",
    //   icon: "menu-calendar_line",
    //   permission: "all",
    //   element: <WorkCalendar />,
    // },
    {
      name: 'desk.history',
      path: ':location_id/desk/reservations/:reservation_id',
      permission: 'all',

      element: <ReservationRecords />,
      hideInMenu: true,
    },

    // 工位
    {
      name: 'desk',
      modules: ['desk'],
      icon: 'menu-station_line',
      path: 'desk',
      permission: 'all',

      element: <DeskLayout />,
      children: [
        // // 分区管理
        // {
        //   name: "areaManage",
        //   path: "area_manage",
        //   // icon: "menu-conference_room_line",
        //   permission: "location.desk.manage",
        //   element: <LocationAreaManage />,
        // },
        {
          name: 'desk.edit_map',

          element: <MapDesigner />,
          permission: 'location.desk.manage',
          path: 'editor',
          hideInMenu: true,
        },
        {
          name: 'desk.assign',

          element: <AssignMap />,
          permission: ['location.desk.manage', 'location.desk.distribute'],
          path: 'assign',
        },
        // {
        //   name: "desk.areas",
        //   element: <AreaManage />,
        //   permission: ["location.desk.manage", "location.desk.distribute"],
        //   path: "areas",
        // },
        {
          name: 'desk.groups',

          element: <DeskGroups />,
          permission: ['location.desk.manage', 'location.desk.distribute'],
          path: 'groups',
        },

        // {
        //   name: "desk.usage_records",
        //   element: <UsageRecords />,
        //   permission: "location.desk.manage",
        //   path: "records",
        // },
        // {
        //   name: "desks",
        //   element: <Desks />,
        //   permission: "all",
        //   path: "desks",
        // },
        // {
        //   name: "list",
        //   element: <DeskManage />,
        //   permission: "location.area.desk.index",
        //   path: "list",
        // },
        // {
        //   name: "department_desk_list",
        //   element: <FloorInfo />,
        //   permission: "location.area.desk.department_manage",
        //   path: "department_desk_list",
        // },
        // {
        //   name: "floor",
        //   element: <FloorInfo />,
        //   permission: "location.area.desk.manage",
        //   path: "floor",
        //   hideInMenu: true,
        // },
        // {
        //   name: "area",
        //   path: "area",
        //   permission: "all",
        //   hideInMenu: true,
        //   element: <AreaDetail />,
        // },
        // {
        //   name: "desk_reservation",
        //   path: "reservation",
        //   permission: "all",
        //   element: <ReserveDesk />,
        // },
        {
          name: 'desk.reservations',
          path: 'reservations',
          permission: 'all',

          element: <ReservationRecords />,
        },
        {
          name: 'desk.reserveDetail',
          path: 'reserveDetail',
          permission: 'all',

          element: <ReserveRecordDetail />,
          hideInMenu: true,
        },
        {
          name: 'desk.emailSettings',
          path: 'email_settings',
          permission: 'location.desk.manage',

          element: <DeskEmailSetting />,
          hideInMenu: true,
        },
        {
          name: 'desk.settings',
          path: 'settings',
          permission: 'location.desk.manage',

          element: <DeskSettings />,
        },
        {
          name: 'desk.deskGroupSettings',
          path: 'deskGroup/settings',
          permission: 'location.desk.manage',

          element: <DeskSettings />,
          hideInMenu: true,
        },
      ],
    },
    {
      element: <ReservationInfo />,
      name: 'meeting.reservation_info',
      path: ':location_id/meetingReservation/:reservation_id',
      permission: 'all',
      hideInMenu: true,
    },
    {
      element: <ImportMeeting />,
      name: 'meeting.importFromCalendars',
      path: ':location_id/importMeeting',
      permission: 'all',
      hideInMenu: true,
    },
    // 会议室
    {
      name: 'meeting',
      modules: ['meeting'],
      path: 'meeting',
      icon: 'menu-conference_room_line',
      permission: 'all',
      hideInMenu: false,

      element: <MeetingRoomLayout />,
      children: [
        {
          name: 'meeting.reservation',
          path: 'reservation',
          permission: 'all',

          element: <ReservationManage />,
        },
        {
          name: 'meeting.manage',
          path: 'list',
          permission: 'all',

          element: <MeetingRooms />,
        },
        {
          name: 'meeting.reservations',
          path: 'reservations',
          permission: 'all',

          element: <ReservationRecord />,
        },

        {
          element: <AddMeetingForm />,
          name: 'meeting.add',
          path: 'list/add',
          permission: 'all',
          hideInMenu: true,
        },
        {
          name: 'meeting.edit',
          path: 'list/edit',

          element: <EditMeetingForm />,
          permission: 'all',
          hideInMenu: true,
        },
        {
          element: <NewMeetingInfo />,
          name: 'meeting.info',
          path: 'list/info',
          permission: 'all',
          hideInMenu: true,
        },
        {
          name: 'meeting.business',
          path: 'business',
          permission: 'location.reservation_product_order.manage',

          element: <BusinessSetting />,
          // children: [
          //   {
          //     name: "meeting.business.management",
          //     path: "management",
          //     permission: "location.reservation_product_order.manage",
          //     element: <BusinessDetail />,
          //   },
          //   {
          //     name: "meeting.business.detail",
          //     path: "detail",
          //     permission: "location.reservation_product_order.manage",
          //     // element: <BusinessDetail />,
          //     element: <BusinessManagement />,
          //   },
          // ],
        },
        {
          name: 'meeting.pad_devices',
          path: 'pad_devices',
          permission: 'location.area.manage',

          element: <MeetingPadDevice />,
        },
        {
          name: 'meeting.settings',
          path: 'settings',
          permission: 'location.area.manage',

          element: <MeetingSettings />,
        },
        // {
        //   name: 'business_detail',
        //   path: 'business_detail',
        //   permission: 'location.area.index',
        //   component: './Meetings/BusinessManagement/BusinessManagement',
        // },
        // {
        //   name: 'meeting.serviceOrders',
        //   path: 'meeting.serviceOrders',
        //   permission: 'location.area.index',
        //   component: './Meetings/BusinessDetail/BusinessDetail',
        // },
        // {
        //   name: 'emailSetting',
        //   path: 'emailSetting',
        //   permission: 'location.area.manage',
        //   element: <MeetingsEmailSetting />,
        // },
      ],
    },
    {
      name: 'alerts',
      path: 'alerts',

      element: <AlertRecord />,
      hideInMenu: true,
    },
    // 访客详情
    {
      name: 'visitors.detail',
      path: ':location_id/visitor/detail/:visitor_id/:visitor_type',
      hideInMenu: true,
      permission: 'all',

      element: <VisitorDetailPage />,
    },
    /**
     * 访客
     */
    {
      name: 'visitors',
      modules: ['visitor'],
      path: 'visitors',
      icon: 'menu-visitor_line',

      element: <VisitorLayout />,
      hideInMenu: false,
      permission: 'all',
      children: [
        {
          name: 'visitors.manage',
          path: 'manage',
          permission: 'location.visitors.visitor.index',

          element: <VisitorManage />,
        },
        {
          name: 'visitors.myVisitors',
          path: 'invites',
          permission: 'all',

          element: <VisitorInviteList />,
        },
        {
          name: 'visitors.visitor_devices',
          path: 'visitor_devices',
          permission: 'location.visitors.visitor.manage',

          element: <VisitorDevices />,
        },
        {
          name: 'visitors.settings',
          path: 'settings',
          permission: 'location.visitors.setting.index',
          children: [
            {
              name: 'visitors.settings.types',
              path: 'types',
              permission: 'location.visitors.setting.index',

              element: <VisitorTypeSetting />,
            },
            {
              name: 'visitors.settings.sign',
              path: 'signin',
              permission: 'location.visitors.setting.index',

              element: <VisitorSign />,
            },
            {
              name: 'visitors.settings.signout',
              path: 'signout',
              permission: 'location.visitors.setting.index',

              element: <VisitorSignout />,
            },
            {
              name: 'visitors.settings.guides',
              path: 'emailSettings',
              permission: 'location.visitors.setting.index',

              element: <VisitorEmail />,
            },
            {
              name: 'visitors.settings.hello',
              path: 'hello',
              permission: 'location.visitors.setting.index',

              element: <VisitorHelloSetting />,
            },
            {
              name: 'visitors.settings.invitation',
              path: 'invitation',
              permission: 'location.visitors.setting.index',

              element: <VisitorInvitationSetting />,
            },
            {
              name: 'visitors.settings.notifications',
              path: 'notifications',
              permission: 'location.visitors.setting.index',

              element: <VisitorNotify />,
            },
            {
              name: 'visitors.settings.approval',
              path: 'approval',
              permission: 'location.visitors.setting.index',

              element: <VisitorApprovalSetting />,
            },
            {
              name: 'visitors.settings.security',
              path: 'security',
              permission: 'location.visitors.setting.index',

              element: <SafetySetting />,
            },
            {
              name: 'visitors.settings.locks',
              path: 'locks',
              permission: 'location.visitors.setting.index',

              element: <VisitorLockSetting />,
            },
            {
              name: 'visitors.settings.edit_type',
              path: 'edit-type/:visitorTypeIndex',
              hideInMenu: true,
              permission: 'all',

              element: <EditVisitorType />,
            },
          ],
        },
        {
          name: 'visitors.devices.add',
          path: 'add-device',
          hideInMenu: true,
          permission: 'location.pad.create',

          element: <AddVisitorDeviceForm />,
        },
        {
          name: 'visitors.add_type',
          path: 'add-type',
          hideInMenu: true,
          permission: 'location.visitors.setting.manage',

          element: <VisitorAddVisitorType />,
        },
        {
          name: 'visitors.inviteForm',
          path: 'invite',
          hideInMenu: true,
          permission: 'all',

          element: <InviteForm />,
        },
        {
          name: 'visitors.SignInForm',
          path: 'signin',
          hideInMenu: true,
          permission: 'location.visitors.visitor.manage',

          element: <SignInForm />,
        },
      ],
    },

    /**
     * 智能储物柜
     */
    {
      name: 'cabinet',
      modules: ['locker'],
      icon: 'menu-locker',
      path: 'cabinet',
      permission: 'all',
      children: [
        {
          name: 'cabinet.manage',
          path: 'manage',
          permission: 'location.smart_locker.cabinet.index',

          element: <Cabinets />,
        },
        {
          name: 'cabinet.create',
          path: 'create',
          permission: 'location.smart_locker.cabinet.manage',

          element: <CabinetCreateForm />,
          hideInMenu: true,
        },
        {
          name: 'cabinet.records',
          path: 'records',
          permission: 'all',

          element: <CabinetRecord />,
        },
        {
          name: 'cabinet.bindPad',
          path: 'bind-pad',
          permission: 'location.smart_locker.cabinet.manage',

          element: <BindPadForm />,
          hideInMenu: true,
        },
        {
          name: 'cabinet.settings',
          path: 'settings',
          permission: 'location.smart_locker.cabinet.manage',

          element: <CabinetSetting />,
          hideInMenu: true,
        },
        {
          name: 'locker',
          path: 'locker',
          hideInMenu: true,
          permission: 'all',
          children: [
            {
              name: 'edit',
              path: 'edit',
              permission: 'all',

              element: <EditLockerForm />,
              hideInMenu: true,
            },
            {
              name: 'reserve',
              path: 'reserve',
              permission: 'all',

              element: <ReserveLockerForm />,
              hideInMenu: true,
            },
          ],
        },
      ],
    },
    /**
     * 智能灯光
     */
    {
      name: 'light',
      icon: 'light',
      path: 'light',
      modules: ['actuator'],
      permission: 'sensor.switch',
      children: [
        {
          element: <Lighting />,
          name: 'control',
          path: 'control',
          permission: 'sensor.switch',
        },
      ],
    },
    /**
     * 门禁管理
     */
    {
      name: 'locks',
      modules: ['lock'],
      path: 'locks',
      icon: 'menu-access_control',
      hideInMenu: false,
      permission: 'location.lock.manage',
      children: [
        {
          name: 'locks.list',
          path: 'list',
          permission: 'location.lock.manage',

          element: <LockManage />,
        },
        {
          name: 'locks.groups',
          path: 'groups',
          permission: 'location.lock.manage',

          element: <LockGroups />,
        },
        {
          name: 'locks.permissions',
          path: 'permissions',
          permission: 'location.lock_permission.index',

          element: <LockPermissionsManage />,
        },
        {
          name: 'locks.logs',
          path: 'logs',
          permission: 'location.lock_event.index',

          element: <LockEvents />,
        },
        {
          name: 'locks.setting',
          path: 'settings',
          permission: 'location.lock_event.index',

          element: <LockSetting />,
        },
        {
          name: 'locks.add_lock',
          path: 'add-lock',
          permission: 'location.lock.create',

          element: <AddLockForm />,
          hideInMenu: true,
        },
        {
          name: 'locks.add_lock_permissions',
          path: 'add-lock-permissions',
          permission: 'location.lock_permission.create',

          element: <AddLockPermissionsForm />,
          hideInMenu: true,
        },
        {
          name: 'locks.info',
          path: ':id',

          element: <LockDetail />,
          permission: 'location.lock.show',
          hideInMenu: true,
        },
        {
          name: 'locks.edit_lock',
          path: ':id/edit',

          element: <AddLockForm />,
          permission: 'location.lock.edit',
          hideInMenu: true,
        },
      ],
    },
    /**
     * 智能场景
     */
    {
      name: 'scene',
      path: 'scenes',
      icon: 'menu-intelligent_device_line',
      permission: 'location.sensor.index',
      hideInMenu: !isChina,
      element: <DeviceLayout />,
      children: [
        {
          name: 'devices.dashboard',
          path: 'dashboard',
          // permission: "location.scene.manage",
          permission: 'location.sensor.index',
          element: <DeviceDashboard />,
        },
        {
          name: 'device',
          path: 'devices',
          // permission: "location.scene.manage",
          permission: 'location.sensor.index',
          element: <Devices />,
        },
        {
          name: 'devices.alarmNotification',
          path: 'alarmNotification',
          // permission: "location.scene.manage",
          permission: 'location.sensor.index',
          element: <DevicesAlarmNotification />,
        },
        {
          name: 'devices.executionRecord',
          path: 'executionRecord',
          // permission: "location.scene.manage",
          permission: 'location.sensor.index',
          element: <ExecutionRecord />,
        },
        {
          name: 'devices.intelligentLinkage',
          path: 'intelligent-linkage',
          permission: 'location.sensor.index',
          element: <IntelligentLinkageTab />,
        },
        // {
        //   name: "scene.scenes",
        //   path: "scenes",
        //   permission: "location.scene.manage",
        //   element: <SceneMode />,
        // },
        // {
        //   name: "scene.automation",
        //   path: "automation",
        //   permission: "location.scenario_automation.manage",
        //   element: <Automation />,
        // },
        {
          name: 'scene.devices.add',
          path: 'add-device',
          hideInMenu: true,
          permission: 'location.sensor.manage',
          element: <AddVisitorDeviceForm />,
        },
        {
          name: 'devices.settings',
          path: 'settings',
          permission: 'location.sensor.manage',
          element: <DeviceSettings />,
          // hideInMenu: true,
        },
      ],
    },
    /**
     * 活动管理
     */
    {
      name: 'activity',
      path: 'activity',
      icon: 'menu-activity_line',
      permission: 'all',
      hideInMenu: false,
      modules: ['activity'],

      element: <ActivityLayout />,
      children: [
        /**
         * 创建活动
         */
        {
          name: 'activity',
          path: 'new',
          icon: 'activity',

          element: <ActivityForm />,
          permission: 'location.activities.activity.manage',
          hideInMenu: true,
        },
        /**
         * 活动详情
         */
        {
          name: 'activity',
          path: ':id',

          element: <ActivityDetail />,
          permission: 'all',
          hideInMenu: true,
        },
        {
          name: 'activity.list',
          path: 'list',

          element: <ActivityList />,
          permission: 'all',
        },
        {
          name: 'activity.types',
          path: 'types',

          element: <ActivityType />,
          permission: 'location.activities.activity.manage',
        },
        {
          name: 'activity.emailSettings',
          path: 'settings',

          element: <ActivityEmailSetting />,
          permission: 'location.activities.activity.manage',
        },
      ],
    },
    // {
    //   name: "attendance",
    //   path: "attendances",
    //   icon: "menu-attendance_management_line",
    //   permission: "location.attendances.attendance.manage",
    //   module: "attendance",
    //   children: [
    //     {
    //       name: "attendance.leaves",
    //       path: "leaves",
    //       permission: "location.attendances.working_leave.index",
    //       element: <LeavesManage />,
    //     },
    //     {
    //       name: "attendance.trips",
    //       path: "trips",
    //       permission: "location.attendances.working_trip.index",
    //       element: <TripsManage />,
    //     },
    //     {
    //       name: "attendance.stats",
    //       path: "stats",
    //       permission: "location.attendances.attendance.manage",
    //       // element: <AttendanceManage />,
    //       element: <AttendanceStats />
    //     },
    //     // {
    //     //   name: 'calendarsettings',
    //     //   path: 'calendarsettings',
    //     //   permission: 'location.attendances.working_schedule.index',
    //     //   component: './Lock/AttendanceCalendarSetting',
    //     // },
    //     // {
    //     //   name: "attendance.attendancesetting",
    //     //   path: "attendancesetting",
    //     //   permission: "location.attendances.attendance.manage",
    //     //   element: <AttendanceSettings />,
    //     // },
    //     {
    //       name: "attendance.addworkingtrips",
    //       path: "addworkingtrips",
    //       permission: "location.attendances.working_trip.create",
    //       element: <AddWorkingTrips />,
    //       hideInMenu: true,
    //     },
    //     {
    //       name: "attendance.addworkingleaves",
    //       path: "addworkingleaves",
    //       permission: "location.attendances.working_leave.create",
    //       element: <AddWorkingLeaves />,
    //       hideInMenu: true,
    //     },
    //     // {
    //     //   name: 'workingholiday',
    //     //   path: 'workingholiday',
    //     //   permission: 'location.attendances.working_holiday.index',
    //     //   component: './Lock/WorkingHoliday',
    //     // },
    //     // {
    //     //   name: 'addworkingholiday',
    //     //   path: 'addworkingholiday',
    //     //   permission: 'location.attendances.working_holiday.add',
    //     //   component: './Lock/AddWorkingHoliday',
    //     //   hideInMenu: true,
    //     // },
    //   ],
    // },
    /**
     * 借还
     */
    {
      name: 'borrow',
      modules: ['borrowing'],
      path: 'borrow',
      icon: 'borrow',
      permission: '', // 暂时关闭
      children: [
        {
          name: 'borrowing.list',
          path: 'list',

          element: <Borrowings />,
        },
        {
          name: 'borrowing.history',
          path: 'history',

          element: <BorrowingHistory />,
        },
        {
          name: 'borrowing.edit',
          path: 'edit',

          element: <BorrowingForm />,
          hideInMenu: true,
        },
        {
          name: 'borrowing.loan',
          path: 'loan',

          element: <BorrowingLoanForm />,
          hideInMenu: true,
        },
      ],
    },
    /**
     * 大屏
     */
    // {
    //   name: "screen",
    //   module: "signage",
    //   // module: 'all',
    //   path: "screen",
    //   icon: "daping",
    //   permission: "", // 暂时关闭
    //   children: [
    //     {
    //       element: <ScreenManage />,
    //       name: "manage",
    //       path: "manage",
    //     },
    //     {
    //       element: <ScreenForm />,
    //       name: "new_screen",
    //       path: "manage/new",
    //       hideInMenu: true,
    //     },
    //     {
    //       element: <ScreenTemplatesManage />,
    //       name: "templates",
    //       path: "templates",
    //     },
    //     {
    //       element: <ScreenTemplateForm />,
    //       name: "new_screen_template",
    //       path: "templates/new",
    //       hideInMenu: true,
    //     },
    //   ],
    // },
    {
      name: 'announcements',
      path: 'announcements',
      modules: ['announcements'],
      permission: 'location.quick_message.index',

      element: <Announcements />,
      icon: 'menu-quick_message_line',
    },
    {
      name: 'add_device',
      path: 'devices/devices/add_device',
      permission: 'location.smart_device.manage',

      element: <AddMeetingDeviceForm />,
      hideInMenu: true,
    },
    // 人体传感器页面
    {
      name: 'sensors',
      path: 'devices/sensors',
      permission: 'location.smart_device.index',

      element: <MeetingSensors />,
      hideInMenu: true,
    },
    // 传感器详情页
    {
      name: 'sensor',
      path: 'devices/sensors/:id', // 传感器详情页
      permission: 'location.sensor.show',

      element: <MeetingSensor />,
      hideInMenu: true,
    },
    {
      name: 'tasks',
      module: 'task',
      path: 'tasks',
      icon: 'menu-repair_order_line',
      permission: 'all',
      hideInMenu: false,
      children: [
        {
          element: <TaskList />,
          name: 'tasks.list',
          path: 'list',
          permission: 'all',
        },
        {
          element: <RepairTypes />,
          name: 'tasks.types',
          path: 'types',
          permission: 'location.task_type.manage',
          hideInMenu: false,
        },
        {
          element: <RepairEmailSetting />,
          name: 'tasks.emailSettings',
          path: 'emailSettings',
          permission: 'location.task_type.manage',
          hideInMenu: false,
        },
        // {
        //   element: <RepairStatistic />,
        //   name: "tasks.analytics",
        //   path: "analytics",
        //   permission: "location.task.manage",
        // },
        // 报修设置
        {
          element: <RepairSetting />,
          name: 'tasks.settings',
          path: 'repairSetting',
          permission: 'location.task_type.manage',
        },
      ],
    },
    // 快递
    {
      name: 'delivery',
      modules: ['task_delivery'],
      path: 'delivery',
      icon: 'archive-line',
      permission: 'all',
      hideInMenu: false,
      children: [
        {
          element: <TaskList />,
          name: 'delivery.list',
          path: 'list',
          permission: 'all',
        },
        {
          element: <RepairTypes />,
          name: 'delivery.types',
          path: 'types',
          permission: 'location.task_type.manage',
          hideInMenu: false,
        },
        {
          element: <RepairEmailSetting />,
          name: 'delivery.emailSettings',
          path: 'emailSettings',
          permission: 'location.task_type.manage',
          hideInMenu: false,
        },
        // {
        //   element: <RepairStatistic />,
        //   name: "delivery.analytics",
        //   path: "analytics",
        //   permission: "location.task.manage",
        // },
        // 快递设置
        {
          element: <RepairSetting />,
          name: 'delivery.settings',
          path: 'repairSetting',
          permission: 'location.task_type.manage',
        },
      ],
    },
    {
      name: 'location.setup',
      path: 'setup',
      icon: 'record',
      permission: 'location.location.update',

      element: <LocationSetupGuide />,
      hideInMenu: true,
    },
    // {
    //   name: "mediaLibrary",
    //   icon: "menu-large_data_screen",
    //   path: "medialibrary",
    //   element: <MediaLibrary />,
    //   hideInMenu: !isChina,
    //   permission: "location.location.manage", //TODO: daming, 需要加权限判断，只有分店管理员们可见
    //   children: [
    //     // {
    //     //   element: <EmployeeSignins />,
    //     //   name: "hybridWork.employeeSignins",
    //     //   path: "hybridWork/signins",
    //     //   permission: "location.location.manage",
    //     // },
    //   ],
    // },
    // {
    //   element: <Screen />,
    //   name: "dataScreen",
    //   icon: "menu-large_data_screen",
    //   path: "screen",
    //   hideInMenu: !isChina,
    //   permission: "location.location.manage",
    // },
    {
      element: <AddScreen />,
      name: 'dataScreen',
      path: 'screen/add',
      permission: 'location.location.manage',
      hideInMenu: true,
    },
    /**
     * 数据统计
     */
    {
      name: 'statistics',
      path: 'statistics',
      icon: 'menu-large_data_screen',
      permission: 'all',
      children: [
        {
          name: 'hybridWork.analytics',
          path: 'hybridWork/analytics',
          permission: 'location.desk.manage',

          element: <LocationHybridWorkStatistic />,
          modules: ['desk'],
          moduleLevel: 'pro',
        },
        {
          name: 'meeting.statistics',
          path: 'meeting/statistics',
          permission: 'location.area.manage',

          element: <LocationMeetingStatisticBox />,
          modules: ['meeting'],
          moduleLevel: 'pro',
          // breadcrumb: "统计",
        },
        {
          name: 'desk.deskAnalytics',
          path: 'desk/analytics',
          permission: 'location.desk.manage',

          element: <LocationDeskStatistic />,
          modules: ['desk'],
          moduleLevel: 'pro',
        },
        {
          name: 'visitors.statistics',
          path: 'visitors/statistics',
          permission: 'location.statistic.index',
          modules: ['visitor'],
          moduleLevel: 'pro',

          element: <LocationVisitorStatistic />,
        },
        {
          element: <LocationRepairStatistic />,
          name: 'tasks.analytics',
          path: 'tasks/analytics',
          permission: 'location.task.manage',
          modules: ['task'],
          moduleLevel: 'pro',
        },
        {
          element: <LocationDeliveryStatistic />,
          name: 'delivery.analytics',
          path: 'delivery/analytics',
          permission: 'location.task.manage',
          modules: ['task_delivery'],
          moduleLevel: 'pro',
        },

        {
          element: <EnergyConsumptionStatistic />,
          name: 'statistics.energyConsumpt',
          path: 'energy_consumption/statistics',
          permission: 'location.task.manage',
          hideInMenu: !isChina,
          // module: "tasks",
          // moduleLevel: "pro",
        },
      ],
    },
    {
      name: 'location_roles',
      path: 'roles',
      icon: 'menu-permission_management_line',

      element: <MemberRoleManage />,
      permission: 'location.access_role.manage',
    },
    /**
     * 公司员工
     */
    {
      name: 'location_members',
      icon: 'menu-employee_management_line',
      path: 'members',
      permission: 'all',

      element: <Members />,
    },
    {
      name: 'location_audit_logs',
      path: 'audit_logs',
      icon: 'menu-operation_record_line',
      permission: 'location.audit_log.index',

      element: <AuditLogs />,
    },
    {
      name: 'system_settings',
      icon: 'menu-branch_setting_line',
      path: 'settings',
      permission: 'location.location.manage',
      children: [
        {
          name: 'location_settings_basic',
          path: 'basic',
          permission: 'location.location.manage',

          element: <EditLocationForm />,
        },
        // 分区管理
        {
          name: 'areaManage',
          path: 'area_manage',
          // icon: "menu-conference_room_line",
          permission: 'location.desk.manage',

          element: <LocationAreaManage />,
        },
        {
          name: 'location_settings_map',

          element: <DeskHome />,
          permission: 'location.location.manage',
          path: 'map',
        },
        {
          name: 'location_settings_time_modes',
          path: 'time-modes',
          permission: 'location.location.manage',

          element: <LocationTimeMode />,
        },
        {
          name: 'location_settings_client',

          element: <LocationClientSettings />,
          permission: 'location.location.manage',
          path: 'client',
        },
        {
          name: 'location_settings_notifications',
          path: 'notifications',
          permission: 'location.location.manage',

          element: <NotifySetting />,
        },
        {
          name: 'location_settings',
          path: 'scheduler',
          permission: 'location.location.manage',

          element: <SchedulerSettings />,
        },
      ],
    },
    {
      name: 'location.member_info',
      path: 'members/:member_id',
      icon: 'member',

      element: <MemberInfo />,
      permission: 'all',
      hideInMenu: true,
    },
    {
      element: <MemberDeskForm />,
      name: 'location.desk_member',
      path: 'members/:member_id/desk-member',
      permission: 'location.member.update',
      hideInMenu: true,
    },
    {
      path: 'setting_boarding',
      name: 'setting_boarding',
      permission: 'setting.update',

      element: <LocationSettingBoarding />,
      hideInMenu: true,
    },

    {
      name: 'edit_building',
      path: 'edit_building',
      permission: 'location.building_info.update',

      element: <BuildingForm />,
      hideInMenu: true,
    },
    /**
     * 储物柜
     */
    // 隐藏菜单
    {
      name: 'user_setting',
      path: 'user-setting',
      icon: 'spaceSettings',

      element: <UserSettingForm />,
      permission: 'all',
      hideInMenu: true,
    },
    {
      path: 'add-area',
      name: 'add_area',
      hideInMenu: true,
      permission: 'area.create',

      element: <AddAreaForm />,
    },
    {
      path: 'batch-area/:floorId',
      name: 'batch_area',
      permission: 'location.area.batch_create',
      hideInMenu: true,

      element: <BatchArea />,
    },
    {
      element: <AddMembersForm />,
      name: 'location.add_member',
      path: 'members/add',
      permission: 'location.member.create',
      hideInMenu: true,
    },
    {
      element: <BatchEnterMembers />,
      name: 'batch_enter_members',
      path: 'members/batch_enter',
      permission: 'location.member.import_members',
      hideInMenu: true,
    },
    {
      element: <MemberAddLocationRole />,
      name: 'location.add_role',
      path: 'members/add-role',
      permission: 'location.member.create',
      hideInMenu: true,
    },
    {
      element: <NoSubscriptionPage />,
      name: 'location.noSubscription',
      path: 'subscriptions/noSubscription',
      hideInMenu: true,
    },
  ],
}
export default routes
