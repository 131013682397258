import defaultImg from '@/assets/default.png'

import excelImg from '@/assets/excel_icon.png'

import musicImg from '@/assets/music.png'

import ocmpressedCackageImg from '@/assets/ocmpressedCackage.png'

import pdfImg from '@/assets/pdf.png'

import pptImg from '@/assets/ppt.png'

import videoImg from '@/assets/video.png'

import wordImg from '@/assets/Word.png'

import KBTitle from '@/components/KBTitle'

import {
  BLUE_500_COLOR,
  GREEN_500_COLOR,
  ORANGE_400_COLOR,
  PURPLE_500_COLOR,
} from '@/constants/textColors'

import * as api from '@/services/api'

import { GEO_MAP_KEY } from '@/utils/constants/commonConstants'

import accounting from 'accounting'
import { Space, message, Modal, notification, Tooltip } from 'antd'
import dayjs from 'dayjs' // no timezone
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { compressAccurately } from 'image-conversion'
import { get, isEqualWith, remove, truncate } from 'lodash'

import { parse } from 'qs'
import intl from 'react-intl-universal'
import { RRule } from 'rrule'
import Cookies from 'universal-cookie'

import { SUPPORTED_LOCALES } from './constants/supportedLocales'

import { redirectTo } from './routeUtil'
import { URL_PATTERN } from './validatorUtils'

import { getLocalStorage } from '@/utils/localStorage'
import EventEmitterClass from 'eventemitter3'

import { DATE_TIME_FORMATS } from '@/utils/constants'

dayjs.extend(customParseFormat)

export const EventEmitter = new EventEmitterClass()

// TODO: taoh
// 1. 删除不必要的函数
// 2. 统一format时间/日期
// 3. 非全局函数移到相应模块目录
export function getLocationSetting(
  target_type: any,
  target_id: any,
  settingName: any,
  callback: any
) {
  const params = {
    target_type,
    target_id,
    inherit: true,
  }

  api.getSettings(params).then(({ response }: any) => {
    const settings = get(response, `${settingName}`, null)
    callback && callback(settings)
  })
}

// 将时间归为往前最近的时间刻度，半小时, 如 8:19 -> 8:00
export const roundToNearestPeriod = (time: any, step = 30) => {
  const stepMinutes = Math.floor(time.minute() / step) * step
  return dayjs(time).startOf('hour').add(stepMinutes, 'minutes')
}

// 计算同比
export function calculateYearOverYearPercentage(
  currentCount: any,
  previousCount: any,
  num = 2
) {
  if (previousCount === 0) {
    return 0
  }

  const percentageChange =
    ((currentCount - previousCount) / previousCount) * 100
  return parseFloat(percentageChange.toFixed(num)) // 保留两位小数
}

// 获取详细地址
export async function searchMapAddress(keywords: any, city: any) {
  return new Promise((resolve, reject) => {
    fetch(
      `https://restapi.amap.com/v3/assistant/inputtips?key=${GEO_MAP_KEY}&keywords=${keywords}&city=${city}&citylimit=true`,
      { method: 'get' }
    )
      .then((res) => res.json())
      .then((data) => resolve(data))
      .catch((err) => reject(err))
  })
}

export function getCurrentLocale() {
  const currentLocale = intl.getInitOptions()
  let currentLocaleData = SUPPORTED_LOCALES.find(
    (x) => x.value === currentLocale.currentLocale
  )
  if (!currentLocaleData) {
    currentLocaleData = SUPPORTED_LOCALES.find((x) => x.value === 'en-US')
  }
  return currentLocaleData
}

export const capitalize = (name: any) => {
  return name[0].toUpperCase() + name.slice(1)
}

export const getImageWidthAndHeight = (file: any) => {
  return new Promise((resolve, reject) => {
    const _URL = window.URL || window.webkitURL
    const img = new Image()
    img.onload = () => {
      return resolve({ width: img.width, height: img.height })
    }
    img.src = _URL.createObjectURL(file)
  })
}

// easier format message
export function tr(messageId: any, params?: any) {
  // HACK: 因为constants.jsx里面有tr调用，而react-intl-universal
  // 还没有init，所以会报warning。hack了一下直接跳过

  if (!window.intl_inited) return messageId
  if (!messageId) return null
  return intl.get(messageId, params) || `tr(${messageId})`
}

// easier format message
export function trHTML(messageId: any, params: any) {
  // HACK: 因为constants.jsx里面有tr调用，而react-intl-universal
  // 还没有init，所以会报warning。hack了一下直接跳过

  if (!window.intl_inited) return messageId
  if (!messageId) return null
  return intl.getHTML(messageId, params) || `tr(${messageId})`
}

// 国际化：对model进行操作
export function trModel(model: any) {
  return tr(`model.${model}`)
}

// 国际化：对model进行操作
export function trAction(action: any, model: any) {
  return tr(`model.actions.${action}`, { model: tr(`model.${model}`) })
}

// 国际化：所有model
export function trAll(model: any) {
  return tr(`model.actions.all`, { model: tr(`model.${model}`) })
}
// 国际化：新增model
export function trAdd(model: any) {
  return tr(`model.actions.add`, { model: tr(`model.${model}`) })
}
// 国际化：编辑model
export function trEdit(model: any) {
  return tr(`model.actions.edit`, { model: tr(`model.${model}`) })
}
// 国际化：删除model
export function trDelete(model: any) {
  return tr(`model.actions.delete`, { model: tr(`model.${model}`) })
}
// 国际化：选择model
export function trSelect(model: any) {
  return trAction('select', model)
}
// 国际化：填写model
export function trInput(model: any) {
  return trAction('input', model)
}
// 国际化：搜索model
export function trSearch(model: any) {
  return trAction('search', model)
}

// 密度数据转换
export function densityDataTransform(data: any, dataName: any) {
  let densityData = data.map((item: any) => {
    return [item['day'], item['time'], item[dataName]]
  })

  densityData = densityData.sort((a: any, b: any) => {
    return a[1] - b[1]
  })
  return densityData
}

// 字符串，时间戳转为dayjs object
const toDayjsObject = (time: any) => {
  try {
    if (typeof time === 'object' || typeof time === 'string') {
      return dayjs(time)
    }
    if (typeof time === 'number') {
      return dayjs.unix(time)
    }
    return null
  } catch (err) {
    return null
  }
}

// 返回两个日期时间差（分钟）
export function dayjsDiff(startTime: any, endTime: any, unit = 'minutes') {
  let time = 0
  startTime = toDayjsObject(startTime)
  endTime = toDayjsObject(endTime)
  if (startTime && endTime) {
    time = endTime.diff(startTime, unit)
  }
  return time
}

// format time as "xxx小时xx分钟"
export function minTransform(time: any, options = {}) {
  if (options?.hours) {
    const hours = tr('slidehoteldetail.hours', { hours: parseInt(time / 60) })
    return hours
  }

  if (time >= 60) {
    const hours = tr('slidehoteldetail.hours', { hours: parseInt(time / 60) })
    if (time < 60 * 24) {
      return hours
    }

    const minutes = parseInt(time % 60)
      ? tr('slidehoteldetail.minutes', { minutes: parseInt(time % 60) })
      : ''
    return hours + (minutes ? ` ${minutes}` : '')
  }

  // 是否显示0，不带单位

  if (options?.isZeroNotUnit) {
    return time
  }

  return tr('slidehoteldetail.minutes', { minutes: time })
}

// 重复时间规则parse
export function ifrrule(value: any, repeat: any, byWeekday = []) {
  const byweekday: any = []
  byWeekday.map((item) => byweekday.push(RRule[item]))
  const repeat_time =
    repeat || new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 365)
  switch (value) {
    case 'no':
      return null
    case 'week':
      // eslint-disable-next-line no-case-declarations
      const week = new RRule({
        freq: RRule.WEEKLY,
        interval: 1,
        byweekday,
        until: repeat_time,
      })

      return week.toString()
    case 'month':
      // eslint-disable-next-line no-case-declarations
      const month = new RRule({
        freq: RRule.MONTHLY,
        interval: 1,
        until: repeat_time,
      })

      return month.toString()
    case 'twoweek':
      // eslint-disable-next-line no-case-declarations
      const twoweek = new RRule({
        freq: RRule.WEEKLY,
        interval: 2,
        byweekday,
        until: repeat_time,
      })
      return twoweek.toString()
    case 'workDay':
      // eslint-disable-next-line no-case-declarations
      const workDay = new RRule({
        freq: RRule.DAILY,
        interval: 1,
        byweekday: [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR],
        until: repeat_time,
      })
      return workDay.toString()
    case 'day':
      // eslint-disable-next-line no-case-declarations
      const day = new RRule({
        freq: RRule.DAILY,
        interval: 1,
        until: repeat_time,
      })
      return day.toString()
    case 'workWeek':
      // eslint-disable-next-line no-case-declarations
      const workWeek = new RRule({
        freq: RRule.WEEKLY,
        interval: 1,
        until: repeat_time,
      })

      return workWeek.toString()
    case 'year':
      // eslint-disable-next-line no-case-declarations
      const year = new RRule({
        freq: RRule.YEARLY,
        interval: 1,
        until: repeat_time,
      })

      return year.toString()
    default:
      return null
  }
}

export function baseIdGetData(ids: any, list: any, attr: any) {
  if (ids && list) {
    const listArr: any = []
    ids.forEach((id: any) => {
      list.forEach((item: any) => {
        if (item.id === id) {
          listArr.push(item[attr])
        }
      })
    })
    return listArr
  }
  return []
}

export function renderDeskItem(
  desk: any,
  index: any,
  deskClick: any,
  type: any
) {
  const { state, serial_number: serialnNmber } = desk
  let avatar

  const { renderUserAvatar } = require('@/utils/uiUtil')
  const tooltip = serialnNmber

  // 灵活工位 不显示 预定头像
  switch (state) {
    case 'assigned': {
      const user = get(desk, 'assignee')
      avatar =
        desk.desk_type !== 'hotel' ? (
          renderUserAvatar(user, { fontSize: 56 })
        ) : (
          <img
            src={desksFreeImg}
            style={type === 'wx' ? wxIconStyle : iconStyle}
            title={serialnNmber}
            alt={serialnNmber}
          />
        )
      break
    }
    case 'free': {
      avatar = (
        <img
          src={desksFreeImg}
          style={type === 'wx' ? wxIconStyle : iconStyle}
          title={serialnNmber}
          alt={serialnNmber}
        />
      )
      break
    }
    case 'pending_approval': {
      avatar = (
        <img
          src={desksPendingImg}
          style={type === 'wx' ? wxIconStyle : iconStyle}
          title={serialnNmber}
          alt={serialnNmber}
        />
      )
      break
    }
    case 'assigned_to_department': {
      avatar = (
        <img
          src={desksPendingImg}
          style={type === 'wx' ? wxIconStyle : iconStyle}
          title={serialnNmber}
          alt={serialnNmber}
        />
      )
      break
    }
    case 'disabled': {
      avatar = (
        <img
          src={desksDisabledImg}
          style={type === 'wx' ? wxIconStyle : iconStyle}
          title={serialnNmber}
          alt={serialnNmber}
        />
      )
      break
    }
    default:
      break
  }
  return (
    <div
      onClick={deskClick}
      key={index}
      style={{
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginLeft: '12px',
        marginRight: '12px',
        marginBottom: '24px',
      }}
    >
      <Tooltip title={tooltip} style={{ height: '56px' }}>
        {avatar}
      </Tooltip>

      <span
        className="title_color"
        style={{ fontSize: '14px', marginTop: '6px' }}
        title={serialnNmber}
      >
        {truncate(serialnNmber, { length: 5 })}
      </span>
    </div>
  )
}

export function visitorScroll(event: any, state: any, callback: any) {
  // event是每一次scroll的数据
  // state就是父级传过来的state
  // callback就是当滑到底部侯要执行的方法
  const maxScroll =
    event.target.scrollingElement.scrollHeight -
    event.target.scrollingElement.clientHeight
  const currentScroll = event.target.scrollingElement.scrollTop
  const tableBody = document.querySelector('.visitor .ant-table-body')

  const tableBodyHeight = tableBody.clientHeight
  if (currentScroll === maxScroll) {
    const {
      response,
      filters: { per_page },
    } = state
    if (checkArray(response)) {
      if (response.length === per_page) {
        tableBody.style.height = `${tableBodyHeight + 100}px`
      }
      callback && callback()
      setTimeout(() => {
        tableBody.style.height = 'auto'
      }, 500)
    }
  }
}

// 显示最大宽度图片
export function cdnImgW(imgUrl: any, width: any) {
  return `${imgUrl}?imageView2/0/w/${width}`
}

// 显示最大高度图片
export function cdnImgH(imgUrl: any, height: any) {
  return `${imgUrl}?imageView2/0/h/${height}`
}

export function fixedZero(val: any) {
  return val * 1 < 10 ? `0${val}` : val
}

export function retainDecimals(data = 0, num = 2, isFixed = true) {
  if (!isFixed && data === 0) {
    return 0
  }

  return parseFloat(isNaN(data) ? 0 : data).toFixed(num)
}

export function retainDecimalsOfStr(data = 0, num = 2, isFixed = true) {
  if (!isFixed && data === 0) {
    return 0
  }

  return `${data}`.substring(0, `${data}`.indexOf('.') + num + 1)
}

export const getIcon = (icon: any) => {
  if (typeof icon === 'string' && URL_PATTERN.test(icon)) {
    return <img src={icon} alt="icon" style={{ width: 14 }} />
  }
  if (typeof icon === 'string') {
    return <span className={`icon-${icon} iconfont`} />
    // return  <span className={`icon-${icon}_ent`}/>
  }
  return icon
}

export const getEllipsis = (value: any, length = 20) => {
  if (!value) {
    return ''
  }
  if (value.length > length) {
    return `${value.slice(0, length)}...`
  }
  return value
}

// 数组里面，如果两个对象id一样的话，就返回新对象替换掉旧对象，其他对象不变
export function replaceObjectOfArray(oldArray: any, obj: any) {
  const newArray = oldArray.map((item: any) => {
    if (item.id === obj.id) {
      return { ...item, ...obj }
    }
    return item
  })
  return newArray
}

// convert HH:MM to minutes from start of day: 9:30=9*60+30
export const convertTimeToMinutes = (value: any) => {
  const values = value.split(':')
  if (values.length !== 2) {
    return 0
  }
  return parseInt(values[0]) * 60 + parseInt(values[1])
}

// 修改页面url,加时间戳重新刷新
export function reloadWithTimestamp(preUrl?: any) {
  const currentUrl = preUrl || window.location.href
  let newUrl

  // 检测当前URL是否已有'?'，即是否存在其他参数
  if (currentUrl.indexOf('?') !== -1) {
    // 存在其他参数，检测是否已有时间戳参数
    const urlParts = currentUrl.split('?')
    const queryParams = new URLSearchParams(urlParts[1])

    // 如果存在时间戳参数，更新它的值
    if (queryParams.has('t')) {
      queryParams.set('t', new Date().getTime())
    } else {
      // 否则添加时间戳参数

      queryParams.append('t', new Date().getTime())
    }

    newUrl = `${urlParts[0]}?${queryParams.toString()}`
  } else {
    // 没有其他参数，直接添加时间戳参数
    newUrl = `${currentUrl}?t=${new Date().getTime()}`
  }

  // 使用新的URL重新加载页面
  window.location.href = newUrl
}

export const getDateDiff = (dateTimeStamp: any) => {
  const minute = 1000 * 60
  const hour = minute * 60
  const day = hour * 24
  // const halfamonth = day * 15;
  const month = day * 30
  const now = new Date().getTime()
  const diffValue = now - dateTimeStamp
  let result = ''
  if (diffValue < 0) {
    return
  }
  const monthC = diffValue / month
  const weekC = diffValue / (7 * day)
  const dayC = diffValue / day
  const hourC = diffValue / hour
  const minC = diffValue / minute
  if (monthC >= 1) {
    result = tr('getDateDiff.month', { time: parseInt(monthC) })
  } else if (weekC >= 1) {
    result = tr('getDateDiff.week', { time: parseInt(weekC) })
  } else if (dayC >= 1) {
    result = tr('getDateDiff.day', { time: parseInt(dayC) })
  } else if (hourC >= 1) {
    result = tr('getDateDiff.hour', { time: parseInt(hourC) })
  } else if (minC >= 1) {
    result = tr('getDateDiff.min', { time: parseInt(minC) })
  } else result = tr('getDateDiff.just')
  return result
}

export const getCookie = (name: any, options: any) => {
  const cookies = new Cookies()
  return cookies.get(name, options)
}

export const setCookie = (key: any, value: any) => {
  const cookies = new Cookies()
  cookies.set(key, value, { path: '/' })
}

// TODO: 统一为1个format函数: formatDateTime(dt, 'format')
// dt 可以是timestamp，也可以是string，也可以是date, 也可以是dayjs
// export const formatDateTime = (time) =>
//   time ? time.format("YYYY-MM-DD HH:mm:ss") : null;

// export const formatDate = (time) => (time ? time.format("YYYY-MM-DD") : null);

// export const formatDateAsFormat = (time, format) =>
//   time
//     ? format
//       ? dayjs(time).format(format)
//       : dayjs(time).format("YYYY-MM-DD HH:mm")
//     : "";

// time可以为dayjs, Date, String  如果为string，必须带时区信息
export const formatMs = (time: any, format = 'datetime') => {
  if (!time) return ''
  const localEnv = getCurrentLocale()?.value || 'zh-CN'
  format = DATE_TIME_FORMATS[localEnv][format] || format
  try {
    time = toDayjsObject(time)
    return time ? time.tz().format(format) : ''
  } catch (err) {
    return ''
  }
}

export const formatMsMoment = (time: any, format = 'YYYY-MM-DD HH:mm') =>
  time ? dayjs.unix(time, format) : ''

export const formatStartAtToEndAt = (startAt: any, endAt: any) => {
  let _end = formatMs(endAt, 'HH:mm')
  if (_end === '00:00') {
    _end = '23:59'
  }
  return `${formatMs(startAt, 'HH:mm')} - ${_end}`
}

// 初始化时间, 传入开始时间和结束时间,返回开始时间从0点到结束时间23:59
export const parseStartEndReservationDateTime = (reservationTime: any) => [
  reservationTime[0].startOf('day'),
  reservationTime[1].endOf('day'),
]

/**
 * 设置字符串格式的时间到 dayjs 日期对象.
 * @param {Dayjs} dateObj - Dayjs 日期对象.
 * @param {string} timeStr - 字符串格式的时间（例如 '8:00 AM'）.
 * @return {Dayjs} 返回设置了时间的 dayjs 日期对象的新实例.
 * */
export function setTimeOnDate(dateObj: any, timeStr: any, timezone: any) {
  // 国外的时间是12小时制会带上AM/PM，国内时间是24小时制
  const time = dayjs(timeStr, timeStr?.length > 5 ? 'h:mm A' : 'h:mm')
  if (timezone) {
    time.tz(timezone)
  }
  return dateObj
    .hour(time.hour())
    .minute(time.minute())
    .second(0)
    .millisecond(0)
}

// momentSetDate 把time的date改成date的date日期
export const momentSetDate = (time: any, date: any) => {
  const momentDate = dayjs(date)
  return dayjs(time)
    .set('year', momentDate.year())
    .set('month', momentDate.month())
    .set('date', momentDate.date())
}

// momentSetTime 把time的date改成date的time
export const momentSetTime = (time: any, date: any) => {
  const momentDate = dayjs(date)
  return dayjs(time)
    .set('hour', momentDate.hour())
    .set('minute', momentDate.minute())
    .set('second', momentDate.second())
}

// 删除null的attribute
export const removeEmpty = (obj: any) => {
  // eslint-disable-next-line no-param-reassign
  Object.keys(obj).forEach((key) => obj[key] === null && delete obj[key])
  return obj
}
// 没有用到
// export const jwtTokenVerify = (str) => {
//   if (!str) {
//     return false;
//   }
//   const b = new Base64();
//   str = b.decode(str);
//   let a = str;
//   a = a.slice(a.indexOf("exp"), a.length);
//   a = a.slice(a.indexOf(":") + 1, a.indexOf(":") + 11);
//   if (!a) {
//     return false;
//   }
//   const timeDate = `${a}000`;
//   return dayjs(parseInt(timeDate)).isAfter(dayjs());
// };

// // 返回是否登录
// export const checkLoginStatus = (currentUser) => {
//   if (!currentUser || !Object.keys(currentUser).length) {
//     window.location.href = "/login";
//     return false;
//   }

//   if (
//     currentUser &&
//     currentUser.jwt_token &&
//     !jwtTokenVerify(currentUser.jwt_token)
//   ) {
//     clearLocalStorage();
//     Modal.error({
//       title: tr("login.information.expired"),
//       okText: tr("app.login"),
//       onOk() {
//         window.location.href = "/login";
//       },
//     });
//     return false;
//   }

//   return true;
// };

// TODO: taoh - 重写这个逻辑
export const redirectAfterLogin = (response: any) => {
  const urlParams = new URL(window.location.href)
  const params = parse(window.location.href.split('?')[1])
  const sessionRedirectUrl = sessionStorage.getItem('redirectUrl')
  if (sessionRedirectUrl) {
    window.location.replace(sessionRedirectUrl)
    sessionStorage.removeItem('redirectUrl')
    return
  }

  let { redirect } = params
  if (redirect) {
    const redirectUrlParams = new URL(redirect)
    if (redirectUrlParams.origin === urlParams.origin) {
      redirect = redirect.substr(urlParams.origin.length)
      if (redirect.match(/^\/.*#/)) {
        redirect = redirect.substr(redirect.indexOf('#') + 1)
      }

      window.location.replace(redirect)
      return
    }
  }

  // user API 返回 roles
  const locations = get(response, 'locations', [])
  const homeLocation = get(response, 'home_location', locations && locations[0])

  const roles = get(response, 'roles', [])
  const hasSpacePermissions = roles.find(
    (x: any) => !x.location_id && !x.department_id
  )
  let redirectUrl = '/'

  if (hasSpacePermissions) {
    redirectUrl = '/admin'
  } else if (homeLocation) {
    redirectUrl = `/user`
  } else {
    // 如果用户没有分店
    redirectUrl = '/exception/no-location'
  }

  window.location.replace(redirectUrl)
}

export const checkPhoneSign = (phone: any, props: any, params = {}) => {
  const api = require('../services/api')
  return new Promise((resolve) => {
    api
      .checkUserStatus({
        phone_num: phone,
      })
      .then(({ response }: any) => {
        const users = response

        const text = tr('login.information.createCompany')

        resolve({
          isUsers: users.length,
          errorText: text,
        })

        if (users.length) {
          return Modal.confirm({
            title: tr('desk.floorPlan.notice'),
            content: text,

            okText: tr('login.information.createNewCompany'),

            cancelText: tr('user.signup.cancel'),
            onOk() {
              window.current_user = users[0]
              props.navigate('/user/spaces')
            },
          })
        }

        // 返回无 user id

        if (!params.ignoreNotUsers && !users.length) {
          props.navigate('/user/signup', {
            state: {
              phone_num: phone,
            },
          })
        }
      })
  })
}

// 把tree元素的name转为title，id转为key和value
export const deepReplaceKey = (array: any) =>
  array.map((a: any) => {
    a.title = a.name
    a.value = a.id
    a.key = a.id
    if (a.children) {
      deepReplaceKey(a.children)
    }
    return a
  })

// 把tree元素id删除
export const deepRemoveKey = (array: any, id: any) => {
  remove(array, (a: any) => a.id === id)
  if (array.children) {
    deepRemoveKey(array.children)
  }
  return array
}

// 把tree元素查找id
export const deepFindKey = (array: any, id: any) => {
  return array.find((x: any) => {
    if (x.id === id) {
      return true
    }

    let el
    if (x.children) {
      el = deepFindKey(x.children, id)
    }

    if (el !== undefined) {
      return true
    }
  })
}

export const getTreeNodeByNodeId = (treeData: any, nodeId: any) => {
  const treeDataArray = Array.isArray(treeData) ? treeData : [treeData]
  for (const node of treeDataArray) {
    if (node.id === nodeId) {
      return node
    }
    if (node.children && node.children.length > 0) {
      const findNode = getTreeNodeByNodeId(node.children, nodeId)
      if (findNode) {
        return findNode
      }
    }
  }
  return null
}

// replace an element in the array with the same key of given object
export const updateArrayWithKey = (
  oldArray: any,
  newObject: any,
  key = 'id'
) => {
  let arr = newObject
  if (!Array.isArray(newObject)) {
    arr = [newObject]
  }

  const s = oldArray.map((x: any) => {
    const found = arr.findIndex(
      (object: any) => get(x, key) === get(object, key)
    )
    if (found === -1) {
      return x
    }
    return arr[found]
  })
  return s
}

const _updateOrMergeArrayWithKey = (
  oldArray: any,
  newObject: any,
  key = 'id'
) => {
  if (!oldArray) {
    return [newObject] // nothing existed
  }
  if (oldArray?.find((x: any) => x[key] === newObject[key])) {
    return updateArrayWithKey(oldArray, newObject, key) // inplace update
  }
  return [newObject, ...oldArray] // push into array for new object
}
// 如果有，update，没有，merge, newObject可以是数组或单个object
export const updateOrMergeArrayWithKey = (
  oldArray: any,
  newObject: any,
  key = 'id'
) => {
  if (Array.isArray(newObject)) {
    return newObject.reduce((acc, curr) => {
      return _updateOrMergeArrayWithKey(acc, curr, key)
    }, oldArray || [])
  }
  return _updateOrMergeArrayWithKey(oldArray, newObject, key)
}

// treeData为父节点下所有叶子节点数组
// 每个节点数据结构中子元素要包含到children关键字中，父元素ID包含在parent关键字中
// treeData = [{children: [treeData], parent: {treeNode}}]
export const updateOrMergeTreeDataWithKey = (
  treeData: any,
  newNode: any,
  key = 'id'
) => {
  // 顶级节点，直接加入treeData
  if (!newNode.parent) {
    return updateOrMergeArrayWithKey(treeData, newNode, key)
  }
  let isMerged = false
  return treeData.map((treeNode: any) => {
    // 已经Merge，后面节点之间返回，不再进行逻辑处理
    if (isMerged) {
      return treeNode
    }

    // 属于当前节点的叶子节点，更新children
    if (treeNode[key] === newNode.parent[key]) {
      treeNode.children = updateOrMergeArrayWithKey(
        treeNode.children || [],
        newNode
      )
      isMerged = true
      return treeNode
    }

    // 如果该节点存在叶子节点，递归循环
    if (treeNode.children && treeNode.children.length > 0) {
      treeNode.children = updateOrMergeTreeDataWithKey(
        treeNode.children,
        newNode,
        key
      )
    }
    return treeNode
  })
}

export function DAY_TIME_HM(start = 0, end = 24) {
  const time = []
  const startIsInteger = Number.isInteger(start)
  const endIsInteger = Number.isInteger(end)
  let currentStart = startIsInteger ? start : Math.ceil(start)
  const currentEnd = endIsInteger ? end : Math.floor(end)
  const count = currentEnd - currentStart

  if (!startIsInteger) {
    time.push({
      id: `${currentStart - 1},` + '2',
      name: `${fullZero(currentStart - 1)}:` + '30',
    })
  }

  for (let i = 1; i <= count; i++) {
    const st = fullZero(currentStart)
    time.push({
      id: `${currentStart},` + '1',
      name: `${st}:` + '00',
    })

    time.push({
      id: `${currentStart},` + '2',
      name: `${st}:` + '30',
    })

    ++currentStart
  }

  time.push({
    id: `${currentEnd},` + '1',
    name: currentEnd === 24 ? `${23}:` + '59' : `${currentEnd}:` + '00',
  })

  if (!endIsInteger) {
    time.push({
      id: `${currentEnd},` + '2',
      name: `${fullZero(currentEnd)}:` + '30',
    })
  }

  function fullZero(number: any) {
    if (number <= 9) {
      return `0${number}`
    }

    return number
  }

  return time
}

export const copyText = (text: any, callback: any) => {
  const tag = document.createElement('input')
  tag.setAttribute('id', 'cp_hgz_input')
  tag.value = text
  document.getElementsByTagName('body')[0].appendChild(tag)

  document.getElementById('cp_hgz_input').select()
  document.execCommand('copy')

  document.getElementById('cp_hgz_input').remove()
  notification.success({
    message: tr('copied_to_clipboard'),
  })
  if (callback) {
    callback(text)
  }
}

// 判断场地状态
export const getMeetingState = (meeting: any) => {
  const { unexpiredReservations, unified_status } = meeting
  const currentTime = dayjs()
  let currentState = unified_status
  const reservation = unexpiredReservations && unexpiredReservations[0]
  if (!reservation) {
    return currentState
  }
  if (reservation && reservation.start_at) {
    const startAt = reservation.start_at
    const endAt = reservation.end_at
    const current = dayjs(currentTime)
    if (unified_status === 'free' && current.isBefore(dayjs(startAt))) {
      currentState = 'free'
    }
    if (unified_status === 'free' && current.isAfter(dayjs(endAt))) {
      currentState = 'free'
    }
    currentState = 'reserved'
  }
  return currentState
}

// convert a.b.c => {a: {b: {c: null}}}
export const propertyToHash = (str: any, value: any) => {
  return str.split('.').reduceRight((accumulator: any, currentValue: any) => {
    return { [currentValue]: accumulator }
  }, value)
}

// 字符串替换
export const replicesStr = (str: any) => {
  const arr: any = []
  str.split('').forEach(() => {
    arr.push('*')
  })
  return arr.join('')
}

// 黑名单类型判断
export function getBlockItemsType(blockItem: any) {
  if (blockItem && !!blockItem.phone_num) return 'phone_num'
  if (blockItem && !!blockItem.name) return 'name'
  if (blockItem && !!blockItem.email) return 'email'
  if (blockItem && !!blockItem.keywords) return 'keywords'
  return ''
}

export function checkArray(data: any) {
  if (get(data, 'length', 0) > 0) {
    return true
  }
  return false
}

// 判断x，y是否相等, 用x,y的元素id判断
function _equalCustomizer(objValue: any, othValue: any) {
  if (objValue.id === othValue.id) {
    return true
  }
}

export function isEqual(x: any, y: any) {
  isEqualWith(x, y, _equalCustomizer)
}

export function safeJSONParse(json: any) {
  try {
    return JSON.parse(json)
  } catch (e) {
    return null
  }
}

// 页面内容高度
// export function getPageContentHeight() {
//   // 页面高度
//   const rootHeight = document.getElementById("root").clientHeight;
//   // 页面头部高度
//   const headerHeight =
//     document.getElementsByClassName("ant-layout-header")[0].clientHeight;

//   return rootHeight - headerHeight;
// }

// 通过XMLHttpRequest()请求图片链接，然后获取返回的Blob，下载图片
export function formatBlob(attachmentUrl: any, fileName: any) {
  // 用户语言
  const currentLocaleData = getCurrentLocale()
  const x = new XMLHttpRequest()
  x.open('GET', attachmentUrl, true)

  x.setRequestHeader('Accept-Language', `${currentLocaleData.value},zh;q=0.9`)
  x.responseType = 'blob'
  x.onload = function () {
    const url = window.URL.createObjectURL(x.response)
    const a = document.createElement('a')
    a.href = url
    a.download = fileName || ''
    a.click()
  }
  x.send()
}

// 判断显示附件图片类型 val:对象 suffix:此对象中带文件后缀名带字段名  判断对象中的后缀名
export function fileImageType(val: any, suffix: any) {
  const type = val[suffix]
  // img
  if (/\.(jpe?g|png|gif|bmp)$/i.test(type)) {
    return <img src={val.url} alt="" style={{ width: '100%' }} />
  }
  // ppt
  if (/\.(ppt?x|pps)$/i.test(type)) {
    return <img src={pptImg} alt="" />
  }
  // word
  if (/\.(doc?x|docm|dotx|dotm)$/i.test(type)) {
    return <img src={wordImg} alt="" />
  }
  // music
  if (
    /\.(aca|acf|ape|asf|asx|aif|aifc|aiff|au|cd|cda|cmf|flac|mid|midi|mp1|mp2|mp3|qmc|ra|ram|rmi|vqf|wax|wave|wav|wma)$/i.test(
      type
    )
  ) {
    return <img src={musicImg} alt="" />
  }
  // video
  if (/\.(avi|asf|dat|divx|mov|mpeg|mpg|navi|qt|rm)$/i.test(type)) {
    return <img src={videoImg} alt="" />
  }
  // Compressed package
  if (/\.(rar|zip|cab|arj|lzh|ace|tar}gzip|uue|jar|iso|z)$/i.test(type)) {
    return <img src={ocmpressedCackageImg} alt="" />
  }
  // excel
  if (/\.(xlsx|xlsm|xltx|xltm|xlsb|xlam)$/i.test(type)) {
    return <img src={excelImg} alt="" />
  }
  // pdf
  if (/\.(pdf)$/i.test(type)) {
    return <img src={pdfImg} alt="" />
  }
  // default

  return <img src={defaultImg} alt="" />
}

// 对array中的index元素执行moveUp, mutate array，返回array本身
export function arrayMoveUp(arr: any, index: any) {
  if (index > 0) {
    const removed = arr.splice(index - 1, 2)
    arr.splice(index - 1, 0, removed[1], removed[0])
    return arr
  }
  return arr
}

// 对array中的index元素执行moveDown, mutate array，返回array本身
export function arrayMoveDown(arr: any, index: any) {
  if (index < arr.length - 1) {
    const removed = arr.splice(index, 2)
    arr.splice(index, 0, removed[1], removed[0])
    return arr
  }
  return arr
}

// 因为API返回的时间为timestamp integer，转为moment
export function getFieldAsTime(object: any, field: any, defaultValue: any) {
  const fieldValue = get(object, field)
  if (fieldValue) {
    if (typeof fieldValue === 'number') {
      return dayjs.unix(fieldValue)
    }
    return defaultValue
  }
  return defaultValue
}

// 生成数组
export function createArray(start = 0, end: any) {
  const array = []
  for (let i = start; i < end; i++) {
    array.push(i)
  }
  return array
}

export function retainPercent(data = 0, num = 2) {
  if (!data) {
    return 0
  }

  return `${parseInt(retainDecimals(data, 2 + num) * 100 * 10 ** 2) / 100}`
}

// redirect based on notification data
export function messageToRouter(data: any) {
  const { keyType, location } = data
  const location_id = location && location.id
  const id = data && data.trackable && data.trackable.id
  if (keyType === 'reservation') {
    if (!id) {
      notification.info({
        message: tr('activity.thisRecordIsDeleted'),
      })
      return
    }

    redirectTo(`/locations/${location_id}/meetingReservation/${id}`)
  }
  if (keyType === 'desk' || keyType === 'desk_reservation') {
    if (!id) {
      notification.info({
        message: tr('activity.thisRecordIsDeleted'),
      })
      return
    }
    const reservationId =
      data && data.trackable && data.trackable.desk_reservation_id

    redirectTo(`/locations/${location_id}/desk/reservations/${reservationId}`)
  }
  if (keyType === 'area') {
    redirectTo('/locations/desk/manage', {
      state: {
        location_id,
      },
    })
  }
  if (keyType === 'location') {
    redirectTo(`/admin/space/locations/manage`)
  }
  if (
    keyType === 'smart_locker_cabinet_locker' ||
    keyType === 'SmartLocker::Cabinet'
  ) {
    redirectTo('/locations/cabinet/manage', {
      state: {
        location_id,
      },
    })
  }
  if (
    keyType === 'smart_locker_cabinet_reservation' ||
    keyType === 'SmartLocker::CabinetReservation'
  ) {
    redirectTo('/locations/cabinet/record', {
      state: {
        location_id,
      },
    })
  }
  if (keyType === 'visitor') {
    if (!id) {
      notification.info({
        message: tr('activity.thisRecordIsDeleted'),
      })
      return
    }
    const type = get(data, 'parameters.request_type')

    redirectTo(`/locations/${location_id}/visitor/detail/${id}/${type}`)
  }
  if (keyType === 'borrowing_inventory') {
    redirectTo('/locations/borrow/history', {
      state: {
        location_id,
      },
    })
  }
}

// 压缩图片
export function compressImg(file: any) {
  return new Promise((resolve) => {
    compressAccurately(file, 170).then((res) => {
      // The res in the promise is a compressed Blob type (which can be treated as a File type) file;
      const files = new File([res], file.name, { type: file.type })
      resolve(files)
    })
  })
}

export function colorRgba(color: any, x: any) {
  const reg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/
  let sColor = color
  if (sColor && reg.test(sColor)) {
    if (sColor.length === 4) {
      let sColorNew = '#'
      for (let i = 1; i < 4; i += 1) {
        sColorNew += sColor.slice(i, i + 1).concat(sColor.slice(i, i + 1))
      }
      sColor = sColorNew
    } // 处理六位的颜色值
    const sColorChange = []
    for (let i = 1; i < 7; i += 2) {
      sColorChange.push(parseInt(`0x${sColor.slice(i, i + 2)}`))
    }
    if (x) {
      return `rgba(${sColorChange.join(',')},${x})`
    }
    return `rgb(${sColorChange.join(',')})`
  }
  return sColor
}

// 判断考勤时间模式是否存在
export const isExitWorkingScheduleId = (
  timeRuleList: any,
  workingScheduleId: any
) => {
  const workingSchedule = timeRuleList.find(
    (timeRule: any) => timeRule.id === workingScheduleId
  )
  return get(workingSchedule, 'id', '')
}

export const thumbImage = (image: any, width = 68 * 3, height = 55 * 3) => {
  return `${image}?imageView2/1/w/${width}/h/${height}/interlace/1`
}

// merge arr2 into arr1, with same id key, new objects are not added
// [{id: 1}, {id: 2}], [{id: 1}, {id: 3}] => [{id: 1}, {id: 2}]
export const mergeArrayWithKey = (arr1: any, arr2: any, key = 'id') => {
  return arr1?.map((desk: any) => {
    const replaceObject = arr2?.find((x: any) => x[key] === desk[key]) || {}
    return {
      ...desk,
      ...replaceObject,
    }
  })
}

// eslint-disable-next-line no-unused-vars
export function showError(error: any, defaultMessage: any) {
  // Hack: because we handle error in axios request,
  // no need to show error here
  // notification[type]({
  //   message: getServiceErrorMessage(error) || error || defaultMessage,
  //   style: { zIndex: 9999 },
  // });
}
export function showSuccess(message: any, description: any) {
  notification.success({ message, description: description || null })
}

// 对象数组去重
export const objectArrayUniqueWithKey = (data: any, key = 'id') => {
  const obj = {}
  return data.reduce((item: any, next: any) => {
    obj[get(next, `${key}`)]
      ? ''
      : (obj[get(next, `${key}`)] = true && item.push(next))
    return item
  }, [])
}

// 比较两个对象数组,取出不同的值
export const objectArraySetxorWithKey = (
  array1 = [],
  array2 = [],
  key = 'id'
) => {
  const result: any = []
  if (!(array1.length && array2.length)) {
    return [...array1, ...array2]
  }
  for (let i = 0; i < array1.length; i++) {
    const obj = array1[i]
    const num = obj[key]
    let isExist = false
    for (let j = 0; j < array2.length; j++) {
      const aj = array2[j]
      const n = aj[key]
      if (n == num) {
        isExist = true
        break
      }
    }
    if (!isExist) {
      result.push(obj)
    }
  }
  return result
}

// 处理跨天时间
export const handleCrossDayTime = (startAt: any, endAt: any) => {
  const startDate = toDayjsObject(startAt)
  const endDate = toDayjsObject(endAt)

  const startHour = startDate.format('HH:mm')

  const endHour = endDate.format('HH:mm')

  const ymdDate = startDate.format('YYYY-MM-DD')

  const ymdEndDate = endDate.format('YYYY-MM-DD')

  const isSameDay = startDate.isSame(endDate, 'day')

  return {
    isSameDay,
    // 完整的一天时间
    wholeDayTime: `${formatMs(ymdDate, 'date')} ${startHour}-${endHour}`,
    // 跨天时间的日期
    dayTime: ymdDate,
    // 跨天时间的开始时间
    startHour,
    // 跨天时间的结束时间
    endHour,
    // 跨天时间的开始时间
    crossDayStartTime: `${ymdDate} ${startHour}`,
    // 跨天时间的结束时间
    crossDayEndTime: `${ymdEndDate} ${endHour}`,
  }
}

// 操作记录内容解析
export const parseChangeLogContent = (content: any) => {
  let changes_notes = content.replace(
    /《/g,
    `<span style="
  margin: 0 0.2em;
  padding-right: 3px;
  padding-top: 1px;
  font-size: 85%;
  background: rgba(0, 0, 0, 0.06);
  border: 1px solid rgba(0, 0, 0, 0.06);
  width:max-content;
  height:22px;
  color:#212b35;
  border-radius: 3px;"> `
  )
  changes_notes = changes_notes.replace(/》/g, `</span > `)
  changes_notes = `<p>${changes_notes}</p> `
  return changes_notes
}
// 把分钟数转化为时间
export const getTimeFromMins = (mins: any) => {
  if (mins === 24 * 60) {
    mins = 24 * 60 - 1
  }
  return formatMs(dayjs().startOf('day').add(mins, 'minute'), 'time')
}

// 把分钟数转化为时间
export const getTimeFromMinsDay = (mins: any) => {
  if (mins === 24 * 60) {
    mins = 24 * 60 - 1
  }
  return dayjs().startOf('day').add(mins, 'minute')
}

// 把分钟数转化为数组[HH,mm]
export const getTimeFromArray = (mins: any) => {
  if (mins === 24 * 60) {
    mins = 24 * 60 - 1
  }
  const dateTime = dayjs().startOf('day').add(mins, 'minute')
  const h = dateTime.hour()
  const m = dateTime.minute()

  return [h, m]
}

// 生成一天的时间数组[00:00,00:15,00:20,...,23:45,23.59], sorted
// currentDate: 日期
// addCurrentTime 是否添加当前时间
export const timeSlot = (
  step = 15,
  currentDate = dayjs(),
  addCurrentTime = true
) => {
  //  step = 间隔的分钟(步长)
  const date = currentDate.startOf('day') // 时分秒设置从零点开始

  const timeArr = []
  const slotNum = (24 * 60) / step // 算出多少个间隔
  for (let f = 0; f < slotNum; f++) {
    //  stepM * f = 24H*60M
    const time = date.set('minutes', f * step) // 获取：零点的时间 + 每次递增的时间
    timeArr.push(time)
  }
  // 最后一分钟
  timeArr.push(currentDate.endOf('day'))
  if (addCurrentTime) {
    // 当前时间
    timeArr.push(dayjs())
    // sort
    timeArr.sort((a, b) => a.diff(b))
  }

  return timeArr
}

/**
 *
  decimalToPercentString(0.5);
  console.log(percentString); // 输出: "50.00%"
 */
export const decimalToPercentString = (decimal: any, precision = 2) => {
  if (isNaN(decimal)) {
    return '0%'
  }

  if (!decimal) {
    return '0%'
  }

  if (typeof decimal === 'number') {
    if (decimal / 10 > 0) {
      // 如果小数点后第一位不是0，直接返回
      return `${decimal.toFixed(precision)}%`
    }

    // 将小数转为百分比并修约到指定的小数位数
    const percentage = (decimal * 100).toFixed(precision)
    return `${percentage}%`
  }
  throw new Error('Input must be a number')
}

// 格式化金额
/*  参考： http://openexchangerates.github.io/accounting.js/#methods
interface options<TFormat> {
  symbol?: string | undefined;     // default currency symbol is '$'
  format?: TFormat | undefined;    // controls output: %s = symbol, %v = value/number
  decimal?: string | undefined;    // decimal point separator
  thousand?: string | undefined;   // thousands separator
  precision?: number | undefined;   // decimal places
}
*/
export const formatMoney = (amount: any, options: any) => {
  return accounting.formatMoney(amount, options)
}

/**
 * 图片裁剪URL拼接
 */
export const toImageProxy = (url: any, imgProxyObj: any) => {
  const isChinaRegion = import.meta.env.VITE_REGION === 'China'
  if (!isChinaRegion) {
    return url
  }
  if (!url) {
    return
  }

  imgProxyObj = {
    resizing_type: 'fill',
    width: 400,
    height: 400,
    gravity: 'ce',
    enlarge: 1,
    extension: 'png',
    ...imgProxyObj,
  }

  const imgProxyUrl = import.meta.env.VITE_IMAGE_PROXY_URL

  const imgProxyUrlIsLocal = import.meta.env.VITE_IMAGE_LOCAL

  const imageBaseUrl = import.meta.env.VITE_API_BASE_URL

  if (url.split(imageBaseUrl).length > 1 && imgProxyUrlIsLocal !== 'false') {
    url = url.split(imageBaseUrl)[1]
    const path = `/insecure/rs:${imgProxyObj.resizing_type}:${imgProxyObj.width}:${imgProxyObj.height}:${imgProxyObj.enlarge}/g:${imgProxyObj.gravity}/plain/local://${url}@${imgProxyObj.extension}`
    return imgProxyUrl + path
  }
  const path = `/insecure/rs:${imgProxyObj.resizing_type}:${imgProxyObj.width}:${imgProxyObj.height}:${imgProxyObj.enlarge}/g:${imgProxyObj.gravity}/plain/${url}@${imgProxyObj.extension}`
  return imgProxyUrl + path
}

/**
 * 图片裁剪处理
 */
export const imageResize = (
  url: any,
  width: any,
  height: any,
  resizing_type = 'fill'
) => {
  if (!url) {
    return
  }

  // face++图片不裁切，直接展示
  if (url.indexOf('43.227.255.237') >= 0) {
    return url
  }

  // 国内版使用七牛裁切

  if (false && import.meta.env.VITE_FILE_UPLOAD != 'server') {
    return `${url}?imageView2/1/w/${width}/h/${height}/interlace/1/ignore-error/1`
  }

  return toImageProxy(url, {
    resizing_type,
    width,
    height,
  })
}

export const pick = (obj: any, props = []) => {
  return props.reduce((result, prop) => {
    result[prop] = obj[prop]
    return result
  }, {})
}

export const omit = (obj: any, props = []) => {
  const result = { ...obj }
  props.forEach((prop) => {
    delete result[prop]
  })
  return result
}

export const intersect = (array1: any, array2: any) => {
  return array1?.filter((el: any) => {
    return array2?.indexOf(el) >= 0
  })
}

export const diffArray = (array1: any, array2: any) => {
  return array1.filter((item: any) => !array2.includes(item))
}

export const isIntersected = (array1: any, array2: any) => {
  return intersect(array1, array2)?.length > 0
}

export const isSameArray = (array1: any, array2: any) => {
  return intersect(array1, array2)?.length === array1.length
}

export const chartsDataFormat = (data = [], key = '') => {
  return data.map((usage) =>
    parseFloat(Number(get(usage, key) || 0).toFixed(2))
  )
}

// 获取是否当天第一次进入该分店
export const recordLocationTime = (location_id: any) => {
  const firstDate = localStorage.getItem(`${location_id}_firstDate`)
  // 获取当前时间转换成时间戳
  const time = dayjs(dayjs().format('YYYY-MM-DD')).unix()
  if (localStorage.getItem(`${location_id}_firstDate`)) {
    // 同一天

    if (dayjs.unix(firstDate).diff(dayjs(), 'day') == 0) {
      console.log('time', '今天非第一次进入该分店')
      localStorage.setItem(`${location_id}_firstDate`, JSON.stringify(time))
      return false
    }
    // 非同一天
    console.log('time', '今天第一次进入该分店')
    localStorage.setItem(`${location_id}_firstDate`, JSON.stringify(time))
    return true
  }
  console.log('time', '今天第一次进入该分店')
  localStorage.setItem(`${location_id}_firstDate`, JSON.stringify(time))
  return true
}

// 数组根据某个字段分组
/**
 *
 *  data //操作数组
 *  key //根据key区分
 *  name //返回name值
 *  children //分组名称
 *
 */
export const dataGroup = (
  data = [],
  key = 'id',
  name = 'name',
  children = 'data'
) => {
  const map = {}
  const dest = []
  for (let i = 0; i < data.length; i++) {
    const ai = data[i]
    if (!map[get(ai, key)]) {
      // key 依赖字段
      dest.push({
        [key]: get(ai, key), // key  依赖字段
        name: get(ai, name),
        [children]: [ai],
        id: get(ai, key),
      })
      map[get(ai, key)] = ai
    } else {
      for (let j = 0; j < dest.length; j++) {
        const dj = dest[j]
        if (dj[key] == get(ai, key)) {
          // key 依赖字段
          dj[children].push(ai)
          break
        }
      }
    }
  }
  return dest
}

/*  delSameObjValue 数组对象相同值相加去重
    arr 需要处理的数组
    resultNum 最终计算结果的键名
    keyName 用于计算判断的键名
    keyValue 用于计算结果的键名 --> 对应的键值为number类型 */
export const delSameObjValue = (
  arr: any,
  resultNum: any,
  keyName: any,
  keyValue: any
) => {
  const warp = new Map()
  arr.forEach((i: any) => {
    const str = keyName.map((v: any) => i[v]).join('_')
    i[resultNum] = keyValue.reduce((p: any, c: any) => (p += i[c]), 0)
    warp.has(str)
      ? (warp.get(str)[resultNum] += i[resultNum])
      : warp.set(str, i)
  })
  return Array.from(warp).map(([, v]) => v)
}

// 将数组内容使用connector连接，用户工位/会议室位置展示，例如： 企业中心店 - 1F - 工位2
export const ArrayInfoJoinWithConnector = (infos: any, connector = '·') => {
  return infos.filter((dataInfo: any) => dataInfo).join(` ${connector} `)
}

// 去掉字符串中所有空格
export const trimAll = (result: any) => {
  return result.split(' ').join('')
}

/**
 * 自定义字段列表dynamic_attributes，拆分成form表单
 * 例：{dynamic_attributes: [{"name"=>"n19880001", "value"=>"test"}]} ==> {n19880001: 'test'}
 */
export const dynamicAttributeList2Form = (form: any) => {
  if (form === undefined || form === null) {
    return form
  }

  const dynamicAttributes = {}
  get(form, 'dynamic_attributes', []).forEach((item: any) => {
    if (item.datatype === 'boolean') {
      dynamicAttributes[item.name] = item?.value?.toString()
    } else {
      dynamicAttributes[item.name] = item.value
    }
  })

  return {
    ...dynamicAttributes,
    ...form,
  }
}

// 堆叠图源叫处理
export const seriesBorder = (series: any, type = 'across') => {
  if (series.length) {
    // 系列堆叠效果，只需要给每一个分类最后一个柱子添加圆角效果，所以循环采用倒叙遍历
    for (let i = series[0].data.length - 1; i > -1; i--) {
      for (let j = series.length - 1; j > -1; j--) {
        if (series[j].data[i].value != 0) {
          series[j].data[i]['itemStyle'] = {
            barBorderRadius: type === 'across' ? [0, 2, 2, 0] : [2, 2, 0, 0],
          }
          break
        }
      }
    }
    if (type === 'across') {
      for (let i = 0; i < series[0].data.length; i++) {
        for (let j = 0; j < series.length; j++) {
          if (series[j].data[i].value != 0) {
            if (series[j].data[i]['itemStyle']?.barBorderRadius) {
              series[j].data[i]['itemStyle'] = {
                barBorderRadius: [2, 2, 2, 2],
              }
            } else {
              series[j].data[i]['itemStyle'] = {
                barBorderRadius: [2, 0, 0, 2],
              }
            }
            break
          }
        }
      }
    }
  }
  return series
}

export const meetingReservationState = (reservation: any) => {
  switch (reservation?.unified_status) {
    case 'approving': // 待审批
      return (
        <KBTitle level={8}>
          <Space size={4} style={{ color: ORANGE_400_COLOR }}>
            <i
              style={{ fontSize: '14px' }}
              className="iconfont icon-wait_for_fill"
            />
            {tr('meeting.status.approving')}
          </Space>
        </KBTitle>
      )

    case 'reserved': // 已预订
    case 'upcoming': // 未开始
      return (
        <KBTitle level={8}>
          <Space size={4} style={{ color: PURPLE_500_COLOR }}>
            <i
              style={{ fontSize: '14px' }}
              className="iconfont icon-yes_fill"
            />
            {tr('meeting.status.reserved')}
          </Space>
        </KBTitle>
      )

    case 'starting_soon': // 即将开始
      return (
        <KBTitle level={8}>
          <Space size={4} style={{ color: BLUE_500_COLOR }}>
            <i
              style={{ fontSize: '14px' }}
              className="iconfont icon-time_fill"
            />
            {tr('reservationInfo.starting_soon')}
          </Space>
        </KBTitle>
      )

    case 'ongoing': // 使用中|待签到
      return (
        <KBTitle level={8}>
          <Space size={4} style={{ color: '#0694A2' }}>
            <i
              style={{ fontSize: '14px' }}
              className="iconfont icon-time_fill"
            />
            {tr('meeting.status.ongoing')}
          </Space>
        </KBTitle>
      )
    case 'checked_in': // 已签到
      return (
        <KBTitle level={8}>
          <Space size={4} style={{ color: GREEN_500_COLOR }}>
            <i
              style={{ fontSize: '14px' }}
              className="iconfont icon-yes_fill"
            />
            {tr('activity.haveSignin')}
          </Space>
        </KBTitle>
      )

    case 'ending_soon': // 即将结束
      return (
        <KBTitle level={8}>
          <Space size={4} style={{ color: ORANGE_400_COLOR }}>
            <i
              style={{ fontSize: '14px' }}
              className="iconfont icon-sigh_fill"
            />
            {tr('meeting.status.ending_soon')}
          </Space>
        </KBTitle>
      )
  }
}

export const deskReservationState = (reservation: any) => {
  if (reservation?.check_out_at) {
    return (
      <KBTitle level={8}>
        <Space size={4} style={{ color: 'rgba(4, 32, 64, 0.6)' }}>
          <i style={{ fontSize: '14px' }} className="iconfont icon-select" />
          {tr('desk.status.checkedOut')}
        </Space>
      </KBTitle>
    )
  }
  switch (reservation?.unified_status) {
    case 'approving': // 待审批
      return (
        <KBTitle level={8}>
          <Space size={4} style={{ color: ORANGE_400_COLOR }}>
            <i
              style={{ fontSize: '14px' }}
              className="iconfont icon-yes_fill"
            />
            {tr('visitor.status.waiting_for_approval')}
          </Space>
        </KBTitle>
      )

    case 'assigned': // 已预订
    case 'reserved': // 已预订
    case 'upcoming': // 未开始
      return (
        <KBTitle level={8}>
          <Space size={4} style={{ color: PURPLE_500_COLOR }}>
            <i
              style={{ fontSize: '14px' }}
              className="iconfont icon-yes_fill"
            />
            {tr('reservationInfo.active')}
          </Space>
        </KBTitle>
      )

    case 'starting_soon': // 即将开始
      return (
        <KBTitle level={8}>
          <Space size={4} style={{ color: BLUE_500_COLOR }}>
            <i
              style={{ fontSize: '14px' }}
              className="iconfont icon-time_fill"
            />
            {tr('reservationInfo.starting_soon')}
          </Space>
        </KBTitle>
      )

    case 'ongoing': // 使用中
      return (
        <KBTitle level={8}>
          <Space size={4} style={{ color: '#0694A2' }}>
            <i
              style={{ fontSize: '14px' }}
              className="iconfont icon-time_fill"
            />
            {tr('desk.status.ongoing')}
          </Space>
        </KBTitle>
      )
    case 'checked_in': // 已签到
      return (
        <KBTitle level={8}>
          <Space size={4} style={{ color: GREEN_500_COLOR }}>
            <i
              style={{ fontSize: '14px' }}
              className="iconfont icon-yes_fill"
            />
            {tr('desk.status.checked_in')}
          </Space>
        </KBTitle>
      )

    case 'ending_soon': // 即将结束
      return (
        <KBTitle level={8}>
          <Space size={4} style={{ color: ORANGE_400_COLOR }}>
            <i
              style={{ fontSize: '14px' }}
              className="iconfont icon-sigh_fill"
            />
            {tr('desk.status.ending_soon')}
          </Space>
        </KBTitle>
      )
  }
}

export const chartsTooltipFormatter = (data: any) => {
  let res = `<div style="font-size:14px;color:#666;font-weight:400;line-height:1;">${data?.[0]?.name}</div>`
  for (let i = 0, length = data.length; i < length; i++) {
    res += `${data[i]?.marker}<span style="font-size:14px;color:#666;font-weight:400;margin-left:2px">${data[i]?.seriesName}</span>
      <span style="float:right;margin-left:20px;font-size:14px;color:#666;font-weight:900">${data[i]?.data}%</span><br/>`
  }
  return res
}

export const isIOS = !!navigator.userAgent.match(
  /\(i[^;]+;( U;)? CPU.+Mac OS X/
)
