import React from 'react'

const BodySpacer = (props: any) => {
  const { size = 'medium', bgColor, noSize = false } = props
  let height = 8
  // debugger
  if (size === 'small') {
    height = 8
  } else if (size === 'medium') {
    height = 16
  } else if (size === 'large') {
    height = 24
  } else if (size && typeof size === 'number') {
    height = size
  }
  /*
  l：24
  m:16
  s:8
  */
  return (
    <div
      style={{ height: noSize ? 0 : `${height}px`, bgColor }}
      className="body-spacer"
    />
  )
}
export default BodySpacer
