import Dingding from '@/assets/Dingding.png'

import Dingding2 from '@/assets/Dingding2.png'

import EnterpriseWeChat from '@/assets/EnterpriseWeChat.png'

import EnterpriseWeChat2 from '@/assets/EnterpriseWeChat2.png'

import reserveCancelled from '@/assets/reserveCancelled.png'

import reserveCheckin from '@/assets/reserveCheckin.png'

import WeChat2 from '@/assets/WeChat2.png'

import WeChats from '@/assets/WeChats.png'

import { tr } from '@/utils/utils'
import {
  CheckOutlined,
  CloseOutlined,
  QuestionOutlined,
} from '@ant-design/icons'

import orgImg from '@/assets/web/org.png'

import { ORANGE, RED, GREEN, GRAY, BLUE, PURPLE } from '@/constants/colors'

// 页面没有用到
export const ATTENDWILLING = {
  not_reply: {
    icon: <CloseOutlined style={{ color: '#5E6C84' }} />,
  },
  attend: {
    icon: <CheckOutlined style={{ color: '#4CAF50' }} />,
  },
  not_attend: {
    icon: <CloseOutlined style={{ color: '#FE4D62' }} />,
  },
  possible_attend: {
    icon: <QuestionOutlined style={{ color: '#F3724A' }} />,
  },
}

export const MEETING_DETAIL_ANCHORS = [
  {
    href: '#detailBasicInfo',
    title: 'reservationInfo.basic',
  },
  {
    href: '#reservationMeeting',
    title: 'reservation.add',
  },
  {
    href: '#meetingDevice',
    title: 'meetingInfo.meetingDevice',
  },
  {
    href: '#meetingReserveRule',
    title: 'meetingInfo.meetingRoomReserveRule',
  },
  {
    href: '#bookingInformation',
    title: 'visitor.bookingInformation',
  },
  {
    href: '#useInformation',
    title: 'visitor.useInformation',
  },
]

export const DEFAULT_SPACE_LOGO = orgImg

export const VISITOR_GUIDE = {
  wifi: 'visitorEmail.wifi',
  lobby: 'visitorEmail.lobby',
  restroom: 'visitorEmail.restroom',
  storage: 'visitorEmail.storage',
  waiting: 'visitorEmail.waiting',
  emergency: 'visitorEmail.emergency',
  beverage: 'visitorEmail.beverage',
  other: 'visitorEmail.other',
  sign_out: 'visitorEmail.sign_out',
  traffic: 'visitorEmail.traffic',
  park: 'visitorEmail.park',
  elevator: 'visitorEmail.elevator',
  proscenium: 'visitorEmail.proscenium',
  necessity: 'visitorEmail.necessity',
}

export const LOCKER_RESERVATION_STATUS = {
  reserved: 'locker.reservationStatus.reserved',
  active: 'locker.reservationStatus.completed',
  cancelled: 'locker.reservationStatus.cancelled',
}

export const DESK_RESERVATION_STATE = {
  reserved: 'desk_reservation_state0',
  cancelled: 'desk_reservation_state1',
}

// 员工权限
export const MEMBER_ROLES_OBJ = {
  super_admin: 'member.roles.superAdmin',
  admin: 'member.roles.spaceAdmin',
  location_manager: 'member.roles.locationManager',
  location_operator: 'member.roles.locationOperator',
  department_admin: 'member.roles.departmentAdmin',
  reception: 'memberRoles.reception',
}

export const MEMBER_ROLES_ARRAY = [
  {
    id: 'super_admin',
    name: 'member.roles.superAdmin',
  },
  {
    id: 'admin',
    name: 'member.roles.spaceAdmin',
  },
  {
    id: 'location_manager',
    name: 'member.roles.locationManager',
  },
  {
    id: 'location_operator',
    name: 'member.roles.locationOperator',
  },
  {
    id: 'department_admin',
    name: 'member.roles.departmentAdmin',
  },
  {
    id: 'reception',
    name: 'memberRoles.reception',
  },
]

export const CABINET_STATUS_ARRAY = [
  {
    id: 'normal',
    name: 'abinet_status_array0',
  },
  {
    id: 'disabled',
    name: 'abinet_status_array1',
  },
  {
    id: 'damaged',
    name: 'abinet_status_array2',
  },
]

// 动态类型
export const ACTIVITIES_TYPE = [
  //   {
  //   id: 'area',
  //   name: '分区'
  // },
  {
    id: '',
    name: 'activities_type0',
  },
  {
    id: 'desk',
    name: 'activities_type1',
  },
  {
    id: 'location',
    name: 'activities_location',
  },
  {
    id: 'meeting',
    name: 'activities_type2',
  },
  {
    id: 'visitor',
    name: 'activities_type3',
  },
  {
    id: 'smart_locker_cabinet_locker',
    name: 'activities_type4',
  },
  {
    id: 'smart_locker_cabinet_reservation',
    name: 'activities_type5',
  },
  {
    id: 'borrowing_inventory',
    name: 'activities_type6',
  },
]

export const RESERVATION_ATTEND_WILLING_OBJ = {
  not_reply: 'reservation.attendWilling.notReplied',
  attend: 'reservation.attendWilling.attend',
  not_attend: 'reservation.attendWilling.doAttend',
  possible_attend: 'reservation.attendWilling.possiblyAttend',
}

export const AREA_CAPACITY_OBJ = {
  '0_to_2': `0-2${'meeting_capacity_array'}`,
  '3_to_6': `3-6${'meeting_capacity_array'}`,
  '7_to_10': `7-10${'meeting_capacity_array'}`,
  '11_to_15': `11-15${'meeting_capacity_array'}`,
  more_than_15: 'area_capacity_obj',
}

export const MEETING_DEVICES = {
  sensors: 'sensors',
  actuators: 'actuators',
  locks: 'locks',
  pads: 'pads',
  meeting_display: 'meeting_display',
  fog_glass: 'fog_glass',
}

export const deskOptionsTag = {
  update: 'cyan',
  create: 'green',
}

export const CONNECT_THIRDPARTY = [
  {
    img: EnterpriseWeChat,
    name: 'visitor.qiye_wechat',
    btnText: 'associated',
  },
  {
    img: WeChats,
    name: 'userSettingForm.bindingWeChat',
    btnText: 'associated',
  },
  {
    img: Dingding,
    name: 'userSettingForm.bindingNailing',
    btnText: 'associated',
  },
]

export const CANCELCONNECT_THIRDPARTY = [
  {
    img: EnterpriseWeChat2,
    name: 'visitor.qiye_wechat',
    btnText: 'cancelAssociated',
  },
  {
    img: WeChat2,
    name: 'userSettingForm.bindingWeChat',
    btnText: 'cancelAssociated',
  },
  {
    img: Dingding2,
    name: 'userSettingForm.bindingNailing',
    btnText: 'cancelAssociated',
  },
]

export const deskType = [
  {
    text: 'locationHomePage105',
    value: 'fixed',
    color: '#9E1068',
  },
  {
    text: 'ReservationTypeHotel',
    value: 'hotel',
    color: '#237804',
  },
  {
    text: 'ReservationTypePrompt',
    value: 'hot',
    color: '#AD4E00',
  },
]

export const REPAIR_HANDLE_STATUS = [
  {
    value: 'completed',
    text: '处理',
  },
  {
    value: 'received',
    text: '接单',
  },
  {
    value: 'rejected',
    text: '拒绝',
  },
]

export const AREA_EVENTS = new Map([
  ['create', 'op.create'],
  ['update', 'op.update'],
  ['destroy', 'op.delete'],
])

export let TRUE_OT_FALSE = [
  { id: 'true', color: RED, status: 'success' },
  { id: 'false', color: GREEN, status: 'error' },
]

export const DATE_TIME_FORMATS = {
  'zh-CN': {
    date: 'YYYY-MM-DD',
    time: 'HH:mm',
    datetime: 'YYYY-MM-DD HH:mm',
    year: 'YYYY',
    day: 'dddd',
    'YYYY-MM': 'YYYY-MM',
    'YYYY-MM-DD': 'YYYY-MM-DD',
    'YYYY/MM/DD': 'YYYY/MM/DD',
    'HH:mm': 'HH:mm',
    'HH:mm:ss': 'HH:mm:ss',
    'YYYY-MM-DD HH:mm': 'YYYY-MM-DD HH:mm',
    'YYYY-MM-DD HH:mm:ss': 'YYYY-MM-DD HH:mm:ss',
    'MM-DD': 'MM月DD日',
    DD: 'DD',
    'MM-DD HH:mm': 'MM月DD日 HH:mm',
  },
  'zh-TW': {
    date: 'YYYY-MM-DD',
    time: 'HH:mm',
    datetime: 'YYYY-MM-DD HH:mm',
    year: 'YYYY',
    day: 'dddd',
    'YYYY-MM': 'YYYY-MM',
    'YYYY-MM-DD': 'YYYY-MM-DD',
    'YYYY/MM/DD': 'YYYY/MM/DD',
    'HH:mm': 'HH:mm',
    'HH:mm:ss': 'HH:mm:ss',
    'YYYY-MM-DD HH:mm': 'YYYY-MM-DD HH:mm',
    'YYYY-MM-DD HH:mm:ss': 'YYYY-MM-DD HH:mm:ss',
    'MM-DD': 'MM月DD日',
    DD: 'DD',
    'MM-DD HH:mm': 'MM月DD日 HH:mm',
  },
  'en-US': {
    date: 'MMM D, YYYY',
    time: 'h:mm A',
    datetime: 'MMM D, YYYY h:mm A',
    'YYYY-MM-DD': 'MMM D, YYYY',
    'YYYY/MM/DD': 'YYYY/MM/DD',
    'HH:mm': 'h:mm A',
    'HH:mm:ss': 'h:mm:ss A',
    'YYYY-MM-DD HH:mm': 'MMM D, YYYY h:mm A',
    'YYYY-MM-DD HH:mm:ss': 'MMM D, YYYY h:mm:ss A',
    year: 'YYYY',
    day: 'dddd',
    DD: 'D',
    'YYYY-MM': 'YYYY-MM',
    'MM-DD': 'MMM D',
    'MM-DD HH:mm': 'MMM D h:mm A',
  },
  'pt-BR': {
    date: 'DD-MM-YYYY',
    time: 'h:mm A',
    datetime: 'MMM D, YYYY h:mm A',
    'YYYY-MM-DD': ' DD-MM-YYYY',
    'YYYY/MM/DD': 'DD/MM/YYYY',
    'HH:mm': 'h:mm A',
    'YYYY-MM-DD HH:mm': 'DD/MM/YYYY h:mm A',
    'YYYY-MM-DD HH:mm:ss': 'MMM D, YYYY h:mm:ss A',
    year: 'YYYY',
    day: 'dddd',
    DD: 'D',
    'YYYY-MM': 'YYYY-MM',
    'MM-DD': 'MMM D',
    'MM-DD HH:mm': 'MMM D h:mm A',
  },
  'es-LA': {
    date: 'DD-MM-YYYY',
    time: 'h:mm A',
    datetime: 'MMM D, YYYY h:mm A',
    'YYYY-MM-DD': 'DD-MM-YYYY',
    'YYYY/MM/DD': 'DD/MM/YYYY',
    'HH:mm': 'h:mm A',
    'YYYY-MM-DD HH:mm': 'DD/MM/YYYY h:mm A',
    'YYYY-MM-DD HH:mm:ss': 'MMM D, YYYY h:mm:ss A',
    year: 'YYYY',
    day: 'dddd',
    DD: 'D',
    'YYYY-MM': 'YYYY-MM',
    'MM-DD': 'MMM D',
    'MM-DD HH:mm': 'MMM D h:mm A',
  },
}
