import { isIOS } from '@/utils/utils'
import { useLocation } from 'react-router-dom'

const teamsTabsPages = [
  '/user/locations/homepage',
  '/user/locations/calendar',
  '/user/user-settings',
]

export default (component: any) => {
  const { pathname } = useLocation()
  const isInTeams = !!sessionStorage.getItem('inTeams')
  const isH5 = localStorage.getItem('isH5') === 'true'
  const isH5Teams = isInTeams && isH5

  if (teamsTabsPages.includes(pathname) && isH5Teams && isIOS) {
    // ios&&teams&&特定页面不展示loading

    return <></>
  }

  return component
}
