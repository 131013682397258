import React from 'react'

import { tr } from '@/utils/utils'

import Exception from '@/components/Exception'
import { Link } from 'react-router-dom'

function Exception404() {
  return (
    <Exception
      type="404"
      desc={tr('app.exception.description.404')}
      linkElement={Link}
      backText={tr('app.exception.back')}
    />
  )
}

export default Exception404
