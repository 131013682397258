import React, { useState } from 'react'

import * as api from '@/services/api'

import { tr } from '@/utils/utils'
import { message, notification } from 'antd'

import { useLocationContext } from './LocationContext'

const VisitorContext = React.createContext({})

function getVisitor() {
  const [visitorConfig, setVisitorConfig] = useState({})
  const [visitors, setVisitors] = useState([])
  const [visitor, setVisitor] = useState({})

  const { currentLocation } = useLocationContext()
  async function saveVisitorConfig(config: any) {
    const { response } = await api.setVisitorConfig(currentLocation?.id, config)
    console.debug('*** save visitor config response', response)
    notification.success({ message: tr('saveSuccess') })
    setVisitorConfig(response)
    return response
  }
  return {
    visitorConfig,
    setVisitorConfig,
    saveVisitorConfig,
    visitors,
    setVisitors,
    visitor,
    setVisitor,
  }
}

// provider, provide authenticated, login, logout
export function VisitorProvider({ children }: any) {
  const visitor = getVisitor()

  return (
    <VisitorContext.Provider value={visitor}>
      {children}
    </VisitorContext.Provider>
  )
}

// consumer
export function useVisitor() {
  const context = React.useContext(VisitorContext)
  if (context === undefined) {
    throw new Error('useVisitor must be used within a VisitorProvider')
  }
  return context
}
