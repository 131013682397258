import { useEffect } from 'react'

export default (loaded: any) => {
  const globalLoadingInHtml = document.getElementById('globalLoadingInHtml')
  // 防止在夜间模式使用白色的背景板
  const isDark = localStorage.getItem('theme') === 'theme-dark'
  if (globalLoadingInHtml) {
    globalLoadingInHtml.style.backgroundColor = isDark ? '#141414' : '#ffffff'
  }
  useEffect(() => {
    if (loaded) {
      // 清空html的loading--主要为了防止html中的loading和layout的loading切换的时候造成抖动的问题
      // 定时器的目的是为了防止react-render的操作影响loading动画造成卡顿问题
      setTimeout(() => {
        globalLoadingInHtml?.remove()
      }, 0)
    }
  }, [loaded])
}
