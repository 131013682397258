import enUS from '@/assets/web/en-US.png'

import zhCN from '@/assets/web/zh-CN.png'

import zhTW from '@/assets/web/zh-CN.png'

// antd locales
import antD_zhCN from 'antd/locale/zh_CN'
import antD_zhTW from 'antd/locale/zh_TW'
import antD_enUS from 'antd/locale/en_US'
import antD_ptBR from 'antd/locale/pt_BR'
import antD_esES from 'antd/locale/es_ES'
// our locales
// import ZHCNJSON from "@/locales/zh-CN.json";
// import ZHTWJSON from "@/locales/zh-TW.json";
// import ENUSJSON from "@/locales/en-US.json";

// dayjs locales
import 'dayjs/locale/zh-cn'
import 'dayjs/locale/zh-tw'
import 'dayjs/locale/pt-br'
import 'dayjs/locale/es'
// import 'dayjs/locale/es'

export const SUPPORTED_LOCALES = [
  {
    name: 'English',
    value: 'en-US',
    logo: enUS,
    antD: antD_enUS,
    braftEditor: 'en',
  },
  {
    name: '简体中文',
    value: 'zh-CN',
    logo: zhCN,
    antD: antD_zhCN,
    braftEditor: 'zh',
  },
  {
    name: '繁體中文',
    value: 'zh-TW',
    logo: zhTW,
    antD: antD_zhTW,
    braftEditor: 'zh-hant',
  },
  {
    name: 'Português do brasil',
    value: 'pt-BR',
    logo: enUS,
    antD: antD_ptBR,
    braftEditor: 'pt-BR',
  },
  {
    name: 'Español latinoamericano',
    value: 'es-LA',
    logo: enUS,
    antD: antD_esES,
    braftEditor: 'en',
  },

  // {
  //   name: "français",
  //   value: "fr-FR"
  // },
  // {
  //   name: "日本語",
  //   value: "ja-JP"
  // }
]
