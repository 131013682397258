// stateColor里定义的是颜色名称和颜色状态
//状态颜色
export const STATE_SUCCESS = '#22C55E'
export const STATE_ERROR = 'rgba(255, 71, 71, 1)'
export const STATE_WARNING = 'warning'
export const STATE_PROCESSING = 'processing'
export const STATE_DEFAULT = 'default'

//颜色名称
export const STATE_GREEN = 'green'
export const STATE_RED = 'red'
export const STATE_ORANGE = 'orange'
export const STATE_GRAY = 'gray'
export const STATE_BLUE = 'blue'
export const STATE_PURPLE = 'purple'
export const STATE_MAGENTA = 'magenta'
export const STATE_VOLCANO = 'volcano'
export const STATE_GOLD = 'gold'
export const STATE_LIME = 'lime'
export const STATE_CYAN = 'cyan'
export const STATE_GRRKBULT = 'geekblue'
