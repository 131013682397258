import React from 'react'

import { tr } from '@/utils/utils'

import Exception from '@/components/Exception'
import { Link } from 'react-router-dom'

function Exception403() {
  return (
    <Exception
      type="403"
      desc={tr('app.exception.description.403')}
      linkElement={Link}
      backText={tr('app.exception.back')}
    />
  )
}

export default Exception403
