import React from 'react'

import styles from './index.module.less'

import globalLoading from '@/assets/web/globalLoading.svg'

import useTeamsLoading from '@/hooks/useTeamsLoading'

export default (props: any) => {
  const loadingNode = (
    <div className={styles['loading-wrap']}>
      <img src={globalLoading} />
    </div>
  )

  return useTeamsLoading(loadingNode)
  // return (
  //   <div className={styles['loading-wrap']}>
  //     <KBSpin size="large" spinning/>
  //   </div>
  // );
}
