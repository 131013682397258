import { apiDELETE, apiFileUpload, apiGET, apiPOST, apiPUT } from './api_utils'

// 登录
export async function login(params: any) {
  return apiPOST(`/sessions`, params)
}

// 海康登录
export async function hiKvisionLogin(params: any) {
  return apiPOST(`/sessions/hikvision_login`, params)
}

// teams登录
export async function teamsLogin(params: any, token: any) {
  return apiPOST(`/sessions/teams_login`, params, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    hideError: true,
  })
}

// 企业微信登录
export async function qiyeWechatLogin(token: any) {
  return apiPOST(
    `/user`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )
}

// 绑定飞书
export async function bindFeiShu(params: any) {
  return apiPOST(`/feishus/bind_space`, params)
}

// 绑定钉钉
export async function bindDingTalks(params: any) {
  return apiPOST(`/dingtalks/bind_space`, params)
}

// 登录用户权限列表
export async function getUserPermissions(params: any) {
  return apiGET(`/user/permissions`, params)
}

// 根据手机号或email确认是否存在可以创建空间的用户，如果返回empty，表示没有可用的用户
export async function checkUserStatus(params: any) {
  return apiPOST(`/sessions/status`, params)
}

// 创建空间
export async function createSpace(params: any, header: any) {
  return apiPOST(`/spaces`, params, header)
}

// 注册用户
export async function signupUser(params: any) {
  return apiPOST(`/sessions/signup`, params)
}

// 忘记密码
export async function sendPassWordEmail(params: any) {
  return apiPOST(`/passwords`, params)
}

// 重发验证邮件
export async function confirmations(params: any) {
  return apiPOST(`/confirmations`, params)
}

// 应用模块列表
export async function getAppModule(params: any) {
  return apiGET(`/app_modules`, params)
}

// 创建应用模块
export async function createAppModule(params: any) {
  return apiPOST(`/app_modules`, params)
}

// 删除应用模块
export async function deleteAppModule(id: number) {
  return apiDELETE(`/app_modules/${id}`)
}

// 删除应用模块
export async function updateAppModule(id: number, params: any) {
  return apiPUT(`/app_modules/${id}`, params)
}

// 放弃修改邮箱
export async function cancelEditEmail() {
  return apiPUT(`/user/cancel_change_email`)
}

// 重新发送员工激活邮件
export async function postResendConfirmation(id: any) {
  return apiPOST(`/members/${id}/resend_confirmation`)
}

// 省份列表
export async function getProvincesList() {
  return apiGET(`/cities/provinces_list`)
}

// 城市列表
export async function getCities(params: any) {
  return apiGET(`/cities`, params)
}

// 高德地图API 解析地址
export async function getTrapezeOfGaode(params: any) {
  return apiGET(`/utils/reverse_geocode`, params)
}

// 消息推送
export async function postCentrifugeActivities() {
  return apiPOST(`/realtime/token`)
}

// 设备列表
export async function getDevices(params: any) {
  return apiGET(`/devices`, params)
}

// 添加设备
export async function createDevice(params: any) {
  return apiPOST(`/devices`, params)
}

// 删除设备
export async function deleteDevice(id: any) {
  return apiDELETE(`/devices/${id}`)
}

// 更新设备
export async function updateDevice(id: any, params: any) {
  return apiPUT(`/devices/${id}`, params)
}

// 添加设备报修
export async function createDeviceRepairConfig(params: any) {
  return apiPOST(`/device_repair_configs`, params)
}

// 设备报修列表
export async function getServiceRepairConfigs(params: any) {
  return apiGET(`/device_repair_configs`, params)
}

// 更新设备报修
export async function updateDeviceRepairConfig(id: any, params: any) {
  return apiPUT(`/device_repair_configs/${id}`, params)
}

// 删除设备报修
export async function deleteServiceRepairConfigs(id: any) {
  return apiDELETE(`/device_repair_configs/${id}`)
}

// 修改当前用户信息
export async function putUser(params: any) {
  return apiPUT(`/user`, params)
}

// 获取当前用户
export async function getUser(params: any) {
  return apiGET(`/user`, params)
}

// 获取settings
export async function getSettings(params: any) {
  return apiGET(`/settings`, params)
}

// 设置settings
export async function putSettings(params: any) {
  return apiPUT(`/settings`, params)
}

// 部门列表
export async function getDepartments(params: any) {
  return apiGET(`/departments`, { show: 'flat', ...params })
}

// 创建部门
export async function createDepartment(params: any) {
  return apiPOST(`/departments`, params)
}

// 删除部门
export async function deleteDepartment(id: any) {
  return apiDELETE(`/departments/${id}`)
}

// 修改部门
export async function updateDepartment(id: any, params: any) {
  return apiPUT(`/departments/${id}`, params)
}

// 分店列表
export async function getLocations(params: any, headers: any) {
  return apiGET(`/locations`, params, headers)
}

// 创建分店
export async function postLocation(params: any) {
  return apiPOST(`/locations`, params)
}

// 签退签到 导出
export async function getMeetingAttenddancesCsv(meeting_room_id: any) {
  return apiGET(
    `/meeting_rooms/${meeting_room_id}/reservations/reservation_attendances_stats_xls`
  )
}

// GET /managements/${import.meta.env.VITE_API_VERSION}/meeting_rooms/{meeting_room_id}/reservations/reservation_attendances_stats_xls

// 编辑分店
export async function putLocation(id: any, params: any) {
  return apiPUT(`/locations/${id}`, params)
}

// 分店详情
export async function getLocationInfo(id: any) {
  return apiGET(`/locations/${id}`)
}

// // 分店首页动态
export async function getActivitiesList(params: any) {
  return apiGET(`/system_notifications`, params)
}

// 删除分店
export async function deleteLocation(id: any) {
  return apiDELETE(`/locations/${id}`)
}

// 列出分店员工
export async function getMembers(params: any) {
  return apiGET(`/members`, params)
}

// 员工详情
export async function getMemberInfo(id: any, params: any) {
  return apiGET(`/members/${id}`, params)
}

// 上传人脸识别头像
export async function visitorsUploadFacePhoto(id: any, formData: any) {
  return apiFileUpload(
    `/visitors/visitors/${id}/upload_face_photo`,
    formData,
    'put'
  )
}

// 员工 人脸上传
export async function uploadFacePhoto(formData: any) {
  return apiFileUpload('/members/upload_face_photo', formData, 'post')
}

export async function uploadFile(formData: any) {
  return apiFileUpload('/uploads/upload_file', formData, 'post')
}
// 添加新员工
export async function postMember(params: any) {
  return apiPOST(`/members`, params)
}

// 编辑员工
export async function putMember(id: any, params: any) {
  return apiPUT(`/members/${id}`, params)
}

// 重置员工的开门密码
export async function resetMemberPasscode(id: any) {
  return apiPUT(`/members/${id}/reset_passcode`, null)
}

// 员工离职
export async function dismissMember(id: any, params = {}) {
  return apiPUT(`/members/${id}/dismiss`, params)
}

// 撤销员工离职
export async function cancelDismissMember(id: any, params = {}) {
  return apiPUT(`/members/${id}/cancel_dismiss`, params)
}

// 删除员工
export async function deleteMember(id: any) {
  return apiDELETE(`/members/${id}`)
}

// 获取角色列表
export async function getAccessRoles(params: any) {
  return apiGET(`/access_roles`, params)
}

// 获取角色权限详情
export async function getAccessRole(id: any) {
  return apiGET(`/access_roles/${id}`)
}

// 获取默认权限设置
export async function getAccessPermissions(params: any) {
  return apiGET(`/access_roles/permissions`, params)
}

// 更新角色权限
export async function updateAccessRole(id: any, params: any) {
  return apiPUT(`/access_roles/${id}`, params)
}

// 创建角色权限
export async function createAccessRole(params: any) {
  return apiPOST(`/access_roles`, params)
}

// 删除角色
export async function deleteAccessRole(id: any) {
  return apiDELETE(`/access_roles/${id}`)
}

// 场地列表
export async function getMeetingRooms(locationId: any, params: any) {
  return apiGET(`/meeting_rooms`, { ...params, location_id: locationId })
}

// 场地列表CSV
export async function getMeetingRoomsCSV(id: any, params: any) {
  return apiGET(`/meeting_rooms.csv`, { ...params, location_id: id })
}

// 场地详情
export async function getMeetingInfo(id: any, params: any) {
  return apiGET(`/meeting_rooms/${id}`, params)
}

// 创建场地
export async function postMeetingRooms(id: any, params: any) {
  return apiPOST(`/meeting_rooms`, { ...params, location_id: id })
}

// 场地预定列表
export async function getReservations(locationId: any, params: any) {
  return apiGET(`/reservations`, { ...params, location_id: locationId })
}

// 确认会议开始
export async function confirmReservation(id: any) {
  return apiPUT(`/reservations/${id}/confirm`)
}

// 确认会议结束
export async function terminateReservation(id: any, params: any) {
  return apiPUT(`/reservations/${id}/terminate`, params)
}

// 会议室 外部参会人 微信 邀请码
export async function getWxqrcodeReservations(id: any, space_id: any) {
  return apiGET(`/reservations/${id}/wx_qrcode?space_id=${space_id}`)
}

// 场地预定列表CSV
export async function getReservationsCSV(id: any, params: any) {
  return apiGET(`/reservations.csv`, { ...params, location_id: id })
}

// 场地预定详情
export async function getReservationInfo(id: any) {
  return apiGET(`/reservations/${id}`)
}

// 更新会议预定
export async function putReservation(id: any, params: any, hashId: any) {
  return apiPUT(
    `/reservations/${id}`,
    params,
    hashId
      ? {
          headers: {
            'X-sign': hashId,
          },
        }
      : {}
  )
}

// 参会人员列表

export async function getReservationAttendees(
  id: any,
  params: any,
  format = 'json'
) {
  return apiGET(`/reservations/${id}/attendances.${format}`, { ...params })
}

// 取消会议预定
export async function deleteReservation(id: any, cancel_recurring: any) {
  return apiDELETE(`/reservations/${id}`, { cancel_recurring })
}

// 预定场地
export async function createMeetingReservation(
  areaId: any,
  params: any,
  hideError: any,
  hashId: any
) {
  return apiPOST(
    `/meeting_rooms/${areaId}/reservations`,
    params,
    hashId
      ? {
          headers: {
            'X-sign': hashId,
          },
          hideError,
        }
      : {
          hideError,
        }
  )
}

// 修改场地
export async function putMeeting(id: any, params: any) {
  return apiPUT(`/meeting_rooms/${id}`, params)
}

// 删除场地
export async function deleteMeeting(id: any) {
  return apiDELETE(`/meeting_rooms/${id}`)
}

// 获取分区列表
export async function getAreas(params: any) {
  return apiGET(`/areas`, params)
}

// managements/v1/areas/desk_groups?location_id=486 获取工位规则
export async function getAreasDeskGroups(params: any) {
  return apiGET(`/areas/desk_groups`, params)
}

// 删除分区
export async function deleteArea(id: any) {
  return apiDELETE(`/areas/${id}`)
}

// 添加分区
export async function createArea(locationId: any, params: any) {
  return apiPOST(`/areas`, { ...params, location_id: locationId })
}

// 批量上传分区excel
export async function importAreas(locationId: any, formData: any) {
  return apiFileUpload(`/areas/import_areas`, formData, 'put')
}

// 批量导入员工
export async function importMembers(params: any) {
  return apiFileUpload(`/members/import_members`, params)
}

// 批量删除员工 /managements/v1/members/batch_destroy
export async function deletemMembers(params: any) {
  return apiDELETE(`/members/batch_destroy`, params)
}

// 批量导入部门
export async function importDepartments(params: any) {
  return apiFileUpload(`/departments/import`, params)
}

// 批量导入参会人
export async function importReservationsUserId(params: any) {
  return apiFileUpload(`/reservations/user_list`, params, 'post')
}

// 分区详情
export async function getAreaInfo(id: any, params: any) {
  return apiGET(`/areas/${id}`, params)
}

export async function getAreaOccupancyHistory(id: any, params: any) {
  return apiGET(`/areas/${id}/occupancy_history`, params)
}

// 编辑分区
export async function updateArea(id: any, params: any) {
  return apiPUT(`/areas/${id}`, params)
}

// 修改分区状态
export async function editAreasState(id: any, params: any) {
  return apiPUT(`/areas/${id}/area_state`, params)
}

// 创建智能储物柜
export async function postCabinets(params: any) {
  return apiPOST(`/smart_locker/cabinets`, params)
}

// 修改智能储物柜
export async function putCabinets(id: any, params: any) {
  return apiPUT(`/smart_locker/cabinets/${id}`, params)
}

// 删除智能储物柜
export async function deleteCabinets(id: any) {
  return apiDELETE(`/smart_locker/cabinets`, { id })
}

// 智能储物柜列表
export async function getCabinets(params: any) {
  return apiGET(`/smart_locker/cabinets`, params)
}

// 智能储物柜详情
export async function getCabinetInfo(id: any) {
  return apiGET(`/smart_locker/cabinets/${id}`)
}

// Create lockers in the cabinet
export async function postCreateLockers(id: any, params: any) {
  return apiPOST(`/smart_locker/cabinets/${id}/create_lockers`, params)
}

// 智能储物柜格子列表
export async function getCabinetLockers(id: any, params: any) {
  return apiGET(`/smart_locker/cabinets/${id}/lockers`, params)
}

// 修改智能储物柜格子
export async function putCabinetLockers(cabinetId: any, id: any, params: any) {
  return apiPUT(`/smart_locker/cabinets/${cabinetId}/lockers/${id}`, params)
}

// 删除智能储物柜格子
export async function deleteCabinetLocker(cabinetId: any, id: any) {
  return apiDELETE(`/smart_locker/cabinets/${cabinetId}/lockers/${id}`)
}

// Reset locker status to free
export async function postResetLockerToFree(cabinet_id: any, id: any) {
  return apiPOST(`/smart_locker/cabinets/${cabinet_id}/lockers/${id}/reset`, {
    cabinet_id,
    id,
  })
}

export async function postCabinetAllocate(data: any) {
  const { cabinet_id, id } = data
  return apiPOST(
    `/smart_locker/cabinets/${cabinet_id}/lockers/${id}/allocate`,
    data
  )
}

export async function postCabinetUnlock(cabinet_id: any, id: any) {
  return apiPOST(`/smart_locker/cabinets/${cabinet_id}/lockers/${id}/unlock`, {
    cabinet_id,
    id,
  })
}

// 智能储物柜格子详情
export async function getCabinetLockerInfo(cabinetId: any, id: any) {
  return apiGET(`/smart_locker/cabinets/${cabinetId}/locker/${id}`)
}

// 智能储物柜预定列表
export async function getLockerReservations(params: any) {
  return apiGET(`/smart_locker/reservations`, params)
}

// 智能储物柜预定详情
export async function getLockerReservationInfo(id: any) {
  return apiGET(`/smart_locker/reservations/${id}`)
}

// Reserve a locker
export async function postLockerReservation(params: any) {
  return apiPOST(`/smart_locker/reservations`, params)
}

// Complete reservation, reset a locker to free
export async function postCompleteResetLockerToFree(id: any) {
  return apiPOST(`/smart_locker/reservations/${id}/complete`)
}

// Delete reservation, not typically used
export async function deleteReservationNotUsed(id: any) {
  return apiDELETE(`/smart_locker/reservations/${id}`)
}

// 个人消息列表(弃用)
export async function getMessages(params: any) {
  return apiGET(`/messages`, params)
}

// 未读消息数(弃用)
export async function getUnreadMessageCount() {
  return apiGET(`/messages/unread_count`)
}

// 把消息标记为已读(弃用)
export async function putMarkAsRead(params: any) {
  return apiPUT(`/messages/mark_as_read`, params)
}

// 把所有消息标记为已读(弃用)
export async function putMarkAllAsRead() {
  return apiPUT(`/messages/mark_all_as_read`)
}

// 个人消息列表
export async function getActivitiesReceived(params: any) {
  return apiGET(`/system_notifications/received`, params)
}

// 未读消息数
export async function getActivitiesUnreadCount() {
  return apiGET(`/system_notifications/unread_count`)
}

// 把消息标为已读
export async function putActivitiesMaskAsRead(id: any) {
  return apiPUT(`/system_notifications/${id}/mark_as_read`)
}

// 把所有消息标为已读
export async function putActivitiesMarkAllAsRead() {
  return apiPUT(`/system_notifications/mark_all_as_read`)
}

// 删除所有消息
export async function putActivitiesDeleteAllAsRead() {
  return apiDELETE(`/system_notifications/delete_all_read`)
}

// 获取上传token
export async function getUploadToken() {
  return apiPOST(`/uploads/token?bucket=kuban`)
}

// 获取访客设置
export async function getVisitorConfig(locationId: any) {
  return apiGET(`/visitors/settings`, { location_id: locationId })
}

// 获取访客签到统计
export async function getVisitorTodayData(params: any) {
  return apiGET(`/visitors/visitors/today_data`, params)
}

// 审批同意 /visitors/visitors/{id}/approve
export async function approveVisitor(visitorId: any) {
  return apiPUT(`/visitors/visitors/${visitorId}/approve`)
}

// 审批拒绝 /managements/${import.meta.env.VITE_API_VERSION}/visitors/visitors/{id}/disapprove
export async function disapproveVisitor(visitorId: any, params: any) {
  return apiPUT(`/visitors/visitors/${visitorId}/disapprove`, params)
}

// 访客访问历史
export async function getVisitorHistories(visitorId: any, params: any) {
  return apiGET(`/visitors/visitors/${visitorId}/histories`, params)
}

// 获取访客详情
export async function getVisitors(params: any) {
  return apiGET(`/visitors/visitors`, params)
}

// 获取访客详情
export async function getVisitor(visitorId: any, params: any) {
  return apiGET(`/visitors/visitors/${visitorId}`, params)
}

// 创建访客
export async function createVisitor(params: any) {
  return apiPOST(`/visitors/visitors`, params)
}

export async function setVisitorConfig(locationId: any, params: any) {
  return apiPUT(`/visitors/settings`, { location_id: locationId, ...params })
}

// 创建访客类型
export async function createVisitorType(data: any) {
  return apiPUT(`/visitors/settings/create_visitor_type`, data)
}

// 登录用户可访问的门禁列表
export async function getLocksOfPermissions() {
  return apiGET(`/user/lock_permissions`)
}

// 门禁列表
export async function getLocks(params: any) {
  return apiGET(`/locks`, params)
}

// 创建门禁
export async function postLock(params: any) {
  return apiPOST(`/locks`, params)
}

// 修改门禁
export async function putLock(id: any, params: any) {
  return apiPUT(`/locks/${id}`, params)
}

// 获取门禁详情
export async function getLock(id: any, params: any) {
  return apiGET(`/locks/${id}`, params)
}

// 联网开门
export async function unlockByNetwork(params: any) {
  return apiPOST(`/locks/unlock_by_networking`, params)
}

// 批量联网开门
export async function unlockByNetworkBatch(params: any) {
  return apiPOST(`/locks/unlock_by_networking`, params)
}

// 门禁权限列表
export async function getLockPermissions(params: any) {
  return apiGET(`/lock_permissions`, params)
}

// 批量创建门禁权限
export async function postLockPermissionsBatchCreate(params: any) {
  return apiPOST(`/lock_permissions/batch_create`, params)
}

// 批量删除门禁权限
export async function deleteLockPermissionsBatchDestroy(params: any) {
  return apiDELETE(`/lock_permissions/batch_destroy`, params)
}

export async function deletePermissionBySource(params: any) {
  return apiDELETE(`/lock_permissions/batch_destroy_by_source`, params)
}

// 开门记录列表
export async function getLockEvents(params: any) {
  return apiGET(`/lock_events`, params)
}

// 导出开门记录
export async function exportLockEvents(params: any) {
  return apiGET(`/lock_events.csv`, params)
}

// 创建门禁组
export async function postLockGroups(params: any) {
  return apiPOST('/lock_groups', params)
}
// 更新门禁组
export async function putLockGroups(id: any, params: any) {
  return apiPUT(`/lock_groups/${id}`, params)
}
// 删除门禁组
export async function deleteLockGroups(id: any) {
  return apiDELETE(`/lock_groups/${id}`)
}
// 门禁组列表
export async function getLockGroups(params: any) {
  return apiGET('/lock_groups', params)
}

// 门禁组列表
export async function getLockGroup(id: any) {
  return apiGET(`/lock_groups/${id}`)
}

// 获取访客导引
export async function getVisitorGuides(params: any) {
  return apiGET(`/visitors/guides`, params)
}

// 更新访客导引
export async function updateVisitorGuides(params: any) {
  return apiPUT(`/visitors/guides/batch_update`, params)
}

// 批量删除访客
export async function batchDestroyVisitors(params: any) {
  return apiPOST(`/visitors/visitors/batch_destroy`, params)
}

// 批量匿名化
export async function batchAnonymizedVisitors(params: any) {
  return apiPOST(`/visitors/visitors/batch_anonymized`, params)
}

// 批量签退
export async function batchSignout(params: any) {
  return apiPOST(`/visitors/visitors/batch_signout`, params)
}

// 批量签到
export async function batchSignin(params: any) {
  return apiPOST(`/visitors/visitors/batch_signin`, params)
}

// 批量创建访客
export async function batchInvite(params: any) {
  return apiPOST(`/visitors/visitors/batch_invite`, params)
}

// 获取访客
export async function getVistors(params: any) {
  return apiGET(`/visitors/visitors`, params)
}

// 批量审批
export async function batchApprove(params: any) {
  return apiPOST(`/visitors/visitors/batch_approve`, params)
}

// 保存访客详情
export async function updateVisitor(id: any, params: any) {
  return apiPUT(`/visitors/visitors/${id}`, params)
}

// 删除访客详情
export async function deleteVisitor(params: any) {
  return apiDELETE(`/visitors/visitors/${params.id}`)
}

// 获取访客记录
export async function getInvites(params: any) {
  return apiGET(`/visitors/visitors/invites`, params)
}

// 导出访客
export async function getVisitorCSV(params: any) {
  return apiGET(`/visitors/visitors.csv`, params)
}

// 导出访客记录
export async function getInvitesCSV(params: any) {
  return apiGET(`/visitors/visitors/invites.csv`, params)
}

export async function signOutVisitor(id: any, params: any) {
  return apiPUT(`/visitors/visitors/${id}/signout`, params)
}

export async function signinVisitor(id: any, params: any) {
  return apiPUT(`/visitors/visitors/${id}/signin`, params)
}

// 添加访客黑(观察)名单
export async function postBlockItems(params: any) {
  return apiPOST(`/visitors/block_items`, params)
}

// 删除访客黑(观察)名单
export async function deleteBlockItems(id: any) {
  return apiDELETE(`/visitors/block_items/${id}`)
}

// 更新访客黑名单
export async function updateBlockItems(params: any) {
  return apiPUT(`/visitors/block_items/${params.id}`, params)
}

// 标记访客为已审核
export async function markVisitorIdChecked(visitorId: any, params: any) {
  return apiPUT(`/visitors/visitors/${visitorId}/id_check`, params)
}

// 获取访客黑(观察)名单
export async function getBlockItem(id: any) {
  return apiGET(`/visitors/block_items/${id}`)
}

export async function getBlockItems(params: any) {
  return apiGET(`/visitors/block_items`, params)
}

// 申请访问或邀请
export async function byRequestType(params: any) {
  return apiGET(`/statistics/visitors/by_request_type`, params)
}

// 按每周统计签到
export async function signInByDayOfWeek(params: any) {
  return apiGET(`/statistics/visitors/sign_in_by_day_of_week`, params)
}

// 按每日时间统计
export async function signInByWeekDayHourOfDay(params: any) {
  return apiGET(`/statistics/visitors/sign_in_by_week_day_hour_of_day`, params)
}

// 签到按每日时间统计
export async function signInByHourOfDay(params: any) {
  return apiGET(`/statistics/visitors/sign_in_by_hour_of_day`, params)
}

// 访客签到按天统计
export async function signInByDate(params: any) {
  return apiGET(`/statistics/visitors/sign_in_by_date`, params)
}

// 访客邀请按天统计
export async function inviteByDate(params: any) {
  return apiGET(`/statistics/visitors/visitor_by_date`, params)
}

// 最多访问部门
export async function mostVisitedDepartments(params: any) {
  return apiGET(`/statistics/visitors/most_visited_departments`, params)
}

// 最多访问用户
export async function mostVisitedUsers(params: any) {
  return apiGET(`/statistics/visitors/most_visited_users`, params)
}

// 访客类型
export async function byVisitorType(params: any) {
  return apiGET(`/statistics/visitors/by_visitor_type`, params)
}

// 从另一个分店拷贝访客配置
export async function cloneLocation(params: any) {
  return apiPOST(`/visitors/settings/clone`, params)
}

//

// 门禁卡列表
export async function getLockIdCards(params: any) {
  return apiGET(`/ic_cards`, params)
}

// 更新门禁卡
export async function putLockIdCard(id: any, params: any) {
  return apiPUT(`/ic_cards/${id}`, params)
}

// 添加门禁卡
export async function postLockIdCard(params: any) {
  return apiPOST(`/ic_cards`, params)
}

// 删除门禁卡
export async function deleteLockIdCard(id: any, params: any) {
  return apiDELETE(`/ic_cards/${id}`, params)
}

// 获取分店的房态图
export async function getLocationFloorPlans(locationId: any, params: any) {
  return apiGET(`/locations/${locationId}/floorplans`, params)
}

// 按区域列出智能电源
export async function getLightsByAreas(locationId: any, params: any) {
  return apiGET(`/smart_plugs`, params)
}

// 控制电源
export async function putLightSwitch(params: any) {
  return apiPUT(`/smart_plugs/switch`, params)
}

// 分店map详情
// export async function getLocationMapInfo(id, params) {
//   return apiGET(`/locations/${id}/map_info`, params)
// }

// 分店
export async function getReservableDesks(params: any) {
  return apiGET(`/desk_reservations/desks`, params)
}

// 根据日期获取是否预定
export async function getReservedTimes(locationId: any, params: any) {
  return apiGET(`/locations/${locationId}/desks/reserved_times`, params)
}

// 获取工位占用记录
export async function getDeskOccupancyHistory(
  locationId: any,
  deskId: any,
  params: any
) {
  return apiGET(
    `/locations/${locationId}/desks/${deskId}/occupancy_history`,
    params
  )
}

// 登录工位
export async function checkinDesk(deskReservationDeskId: any) {
  return apiPUT(`/desk_reservations/checkin`, {
    desk_reservation_desk_id: deskReservationDeskId,
  })
}

// 登出工位
export async function checkoutDesk(deskReservationDeskId: any) {
  return apiPUT(`/desk_reservations/checkout`, {
    desk_reservation_desk_id: deskReservationDeskId,
  })
}

// 空间搜索
export async function search(params: any) {
  return apiGET(`/space/search`, params)
}

// 取消工位预订
export async function cancelDeskReservations(id: any, params: any) {
  return apiPOST(`/locations/${id}/desks/cancel_reservations`, params)
}

// 获取分区详情
export async function getLocationAreaId(locationId: any, params: any) {
  return apiGET(`/areas/${params.id}`, {
    locationId,
    includes: params.includes,
  })
}

// 创建工位
export async function createDesks(locationId: any, params: any) {
  return apiPOST(`/locations/${locationId}/desks`, params)
}

// 工位详情接口
export async function getDesk(locationId: any, params: any) {
  return apiGET(`/locations/${locationId}/desks/${params.id}`, params)
}

// 删除员工权限(role)
export async function deleteUserSpaceRole(params: any) {
  return apiDELETE(`/space_roles`, params)
}

// 获取员工权限(role)
export async function getSpaceRoles(params: any) {
  return apiGET(`/space_roles`, params)
}

// 修改员工权限(role)
export async function putUserSpaceRole(params: any) {
  return apiPUT(`/space_roles`, params)
}

// 可用的工位号
export async function availableDeskNumbers(id: any, params: any) {
  return apiGET(`/locations/${id}/desks/available`, params)
}

// 解除工位入驻
export async function freeDesk(id: any, deskId: any) {
  return apiPUT(`/locations/${id}/desks/${deskId}/free`)
}

// Create a pad with given unique code
export async function postPadValidateCode(params: any) {
  return apiPOST(`/pads/validate_code`, params)
}

// 创建平板信息
export async function postMeetingScreen(params: any) {
  return apiPOST(`/meeting_screens`, params)
}

// Update pad detail
export async function putPad(id: any, params: any) {
  return apiPUT(`/pads/${id}`, params)
}

// Show pads liat
export async function getPadsList(params: any) {
  return apiGET(`/pads`, params)
}

// 考勤记录
export async function getAttendances(params: any, format = 'json') {
  return apiGET(`/attendances/attendances.${format}`, params)
}

// 按日期考勤明细列表
export async function getAttendanceStats(params: any, format = 'json') {
  return apiGET(`/attendances/attendances/stats.${format}`, params)
}

// 工作日历列表
export async function getCalendarList(id: any, params: any) {
  return apiGET(`/attendances/working_schedules/${id}/working_dates`, params)
}

// 设置日期是否计入考勤
export async function updateCalendar(id: any, params: any) {
  return apiPUT(`/attendances/working_schedules/${id}/working_date`, params)
}

// 个人考勤明细
export async function getMemberAttendances(params: any) {
  return apiGET(`/attendances/attendances`, params)
}

// 场地预定分析
export async function getSumTotalByArea(params: any) {
  return apiGET(`/statistics/meeting_rooms/sum_totals_by_area`, params)
}

// 获取节假日列表
export async function getWorkingHolidays(params: any) {
  return apiGET(`/attendances/working_holidays`, params)
}

// 创建节假日
export async function createWorkingHoliday(params: any) {
  return apiPOST(`/attendances/working_holidays`, params)
}

// 编辑节假日
export async function editWorkingHoliday(id: any, params: any) {
  return apiPUT(`/attendances/working_holidays/${id}`, params)
}

// 删除节假日
export async function deleteWorkingHoliday(id: any) {
  return apiDELETE(`/attendances/working_holidays/${id}`)
}

// 获取外勤管理列表
export async function getWorkingTrips(params: any) {
  return apiGET(`/attendances/working_trips`, params)
}

// 创建外勤
export async function createWorkingTrip(params: any) {
  return apiPOST(`/attendances/working_trips`, params)
}

// 编辑外勤
export async function updateWorkingTrip(id: any, params: any) {
  return apiPUT(`/attendances/working_trips/${id}`, params)
}

// 删除外勤
export async function deleteWorkingTrip(id: any) {
  return apiDELETE(`/attendances/working_trips/${id}`)
}

// 外勤详情
export async function getWorkingTripDetail(id: any) {
  return apiGET(`/attendances/working_trips/${id}`)
}

// 获取请假管理列表
export async function getWorkingLeaves(params: any) {
  return apiGET(`/attendances/working_leaves`, params)
}

// 获取外勤管理列表
export async function getWorkingApprovals(params: any) {
  return apiGET(`/attendances/working_approvals`, params)
}

// 创建节假日
export async function createWorkingLeave(params: any) {
  return apiPOST(`/attendances/working_leaves`, params)
}

// 编辑节假日
export async function updateWorkingLeave(id: any, params: any) {
  return apiPUT(`/attendances/working_leaves/${id}`, params)
}

// 请假详情
export async function getWorkingLeaveDetail(id: any) {
  return apiGET(`/attendances/working_leaves/${id}`)
}

// 删除节假日
export async function deleteWorkingLeave(id: any) {
  return apiDELETE(`/attendances/working_leaves/${id}`)
}

// 订阅
export async function getSaasSubscriptions(spaceId: any, params: any) {
  return apiGET(`/pay/subscriptions`, params)
}

// 更新订阅
export async function updateSaasSubscription(
  spaceId: any,
  subId: any,
  params: any
) {
  return apiPUT(`/saas/subscriptions/${subId}`, params)
}

// 取消订阅
export async function deleteSaasSubscription(
  spaceId: any,
  subId: any,
  params: any
) {
  return apiDELETE(`/saas/subscriptions/${subId}`, params)
}

// 订阅交易历史
export async function getTransactions(params: any) {
  return apiGET(`/saas/subscription_invoices`, params)
}

// 订阅交易详情
export async function getTransaction(id: any, params: any) {
  return apiGET(`/saas/subscription_invoices/${id}`, params)
}

// 短信历史
export async function getSmsRecords(params: any) {
  return apiGET(`/sms_records`, params)
}

// 短信历史统计
export async function getSmsRecordStats(params: any) {
  return apiGET(`/sms_records/stats`, params)
}

// 订阅历史
export async function getVersions(id: any, params: any) {
  return apiGET(`/saas/subscriptions/${id}/versions`, params)
}

// 更新订阅信息
export async function updateSubscription(id: any, params: any) {
  return apiPUT(`/saas/subscriptions/${id}`, params)
}

// 生成订阅账单
export async function createSubscriptionInvoice(params: any) {
  return apiPOST(`/saas/subscriptions/create_invoice_and_pay`, params)
}

// 计算订阅账单费用，用于页面展示
export async function calculateMoney(params: any) {
  return apiPUT(`/saas/subscriptions/calculate_money`, params)
}

// 导出考勤记录
export async function exportAttendances(params: any) {
  return apiGET(`/lock_events/attendances.csv`, params)
}

// 获取空间详情
export async function getSpace(params: any) {
  return apiGET(`/space`, params)
}

// 设置空间基本信息
export async function setSpace(params: any) {
  return apiPUT(`/space`, params)
}

// 返回用户所属的空间
export async function chooseSpaces(params: any) {
  return apiPOST(`/sessions/choose_spaces`, params)
}

// 发送登录短信验证码
export async function sendSmsCode(params: any) {
  return apiPOST(`/sessions/send_sms_code`, params)
}

// 借用物品列表
export async function getBorrowings(params: any) {
  return apiGET(`/borrowings`, params)
}

// 借用物品历史列表
export async function getBorrowingsHistory(params: any) {
  return apiGET(`/borrowings/history`, params)
}

// 添加借用物品
export async function postBorrowings(params: any) {
  return apiPOST(`/borrowings`, params)
}

// 更新借用物品
export async function putBorrowings(id: any, params: any) {
  return apiPUT(`/borrowings/${id}`, params)
}

// 删除物品
export async function deleteBorrowings(id: any) {
  return apiDELETE(`/borrowings/${id}`)
}

// 借用物品详情
export async function getBorrowingInfo(id: any, params: any) {
  return apiGET(`/borrowings/${id}`, params)
}

// 借用物品
export async function postBorrowingsBorrow(id: any, params: any) {
  return apiPOST(`/borrowings/${id}/borrow`, params)
}

// 归还物品
export async function postBorrowingsReturnBack(params: any) {
  return apiPOST(`/borrowings/return_back`, params)
}

// 分店楼宇列表
export async function getLocationBuildings(id: any) {
  return apiGET(`/locations/${id}/building_infos`, null)
}

// 新建分店楼宇
export async function postLocationBuilding(id: any, params: any) {
  return apiPOST(`/locations/${id}/building_infos`, params)
}

// 修改分店楼宇信息
export async function putLocationBuilding(locId: any, id: any, params: any) {
  return apiPUT(`/locations/${locId}/building_infos/${id}`, params)
}

// 删除分店楼宇信息
export async function deleteLocationBuilding(locId: any, id: any) {
  return apiDELETE(`/locations/${locId}/building_infos/${id}`, null)
}

// 添加分店楼层
export async function createFloor(locationId: any, params: any) {
  return apiPOST(`/locations/${locationId}/floor_infos`, params)
}

// 修改分店楼层
export async function updateFloor(locId: any, id: any, params: any) {
  return apiPUT(`/locations/${locId}/floor_infos/${id}`, params)
}

// 分店楼宇列表
export async function getBuildings(params: any) {
  return apiGET(`/locations/${params.locationId}/building_infos`, params)
}

// 楼层分店区域 树 ceshi
export async function getBuildingFloorDeskList(params: any) {
  return apiGET(
    `/locations/${params.locationId}/floor_infos/floor_desk_list`,
    params
  )
}

// 查看分店楼层
export async function getBuildingFloor(locationId: any, floorId: any) {
  return apiGET(`/locations/${locationId}/floor_infos/${floorId}`)
}

// 查看分店所有楼层
export async function getBuildingFloors(locationId: any, params: any) {
  return apiGET(`/locations/${locationId}/floor_infos`, params)
}

// 分店楼层详情
export async function getBuildingFloorOption(params: any) {
  return apiGET(
    `/locations/${params.locationId}/floor_infos/${params.floor_id}`
  )
}

// 删除分店楼层
export async function updateFloorInfo(
  locationId: any,
  floorInfoId: any,
  params: any
) {
  return apiPUT(`/locations/${locationId}/floor_infos/${floorInfoId}`, params)
}

// 删除分店楼层
export async function deleteBuildingFloor(locId: any, id: any) {
  return apiDELETE(`/locations/${locId}/floor_infos/${id}`, null)
}

// 创建充值
export async function createPayment(params: any) {
  return apiPOST(`/saas/payments`, params)
}

// 前端验证收款成功
export async function verifyPayment(params: any) {
  return apiPOST(`/saas/payments/verify`, params)
}

// Stripe 获取支付账户信息
export async function getPayMethods(params: any) {
  return apiGET(`/pay/payment_method`, params)
}

export async function postStripeClientSecret(params: any) {
  return apiPOST(`/pay/payment_method/client_secret`, params)
}

export async function getStripePaymentClientSecret(params: any) {
  return apiGET(`/saas/payment_methods/payment_intent_client_secret`, params)
}

/**
 * 传感器 相关 api 批量开启
 */

// 传感器列表/灯控列表
export async function getSensors(params: any) {
  return apiGET(`/sensors`, params)
}

export async function getSensor(sensorId: any) {
  return apiGET(`/sensors/${sensorId}`)
}

// 获取传感器历史数据
export async function getSensorData(sensorId: any, params: any, format = '') {
  return apiGET(`/sensors/${sensorId}/data${format}`, params)
}

// 批量开启/批量关闭
export async function sensorsBatchSwitchAll(params: any) {
  return apiPUT(`/sensors/batch_switch`, params)
}

// 开启/关闭
export async function switchSensor(id: any, params: any) {
  return apiPUT(`/sensors/${id}/switch`, params)
}
// 空调开启/关闭
export async function airConditionControl(id: any, params: any) {
  return apiPUT(`/smart_devices/air_condition_control`, params)
}
// 窗帘开启/关闭
export async function smartDevicesSwitch(params: any) {
  return apiPUT(`/smart_devices/switch`, params)
}
/**
 * Pad 平板相关 api
 */

// 通过设备 code 创建一个平板设备
export async function createPadWithCode(params: any) {
  return apiPOST(`/pads/validate_code`, params)
}

// 平板列表
export async function getPads(params: any) {
  return apiGET(`/pads`, params)
}

// 修改平板信息
export async function updatePad(id: any, params: any) {
  return apiPUT(`/pads/${id}`, params)
}

// 平板详情
export async function getPadDetail(id: any, params: any) {
  return apiGET(`/pads/${id}`, params)
}

// 删除平板
export async function deletePad(id: any) {
  return apiDELETE(`/pads/${id}`, null)
}

// 分店楼宇列表
export async function getLocationBuildingInfos(id: any, params: any) {
  return apiGET(`/locations/${id}/building_infos`, params)
}

// 楼宇详情
export async function getLocationBuildingDetail(
  id: any,
  buildId: any,
  params: any
) {
  return apiGET(`/locations/${id}/building_infos/${buildId}`, params)
}

/**
 * 数字显示模板 api
 */
// 创建数字显示模板
export async function createScreenTemplate(params: any) {
  return apiPOST(`/signage/templates`, params)
}

// 数字显示模板列表
export async function getScreenTemplates(params: any) {
  return apiGET(`/signage/templates`, params)
}

// 修改数字显示模板信息
export async function updateScreenTemplate(id: any, params: any) {
  return apiPUT(`/signage/templates/${id}`, params)
}

// 数字显示模板详情
export async function getScreenTemplateDetail(id: any, params: any) {
  return apiGET(`/signage/templates/${id}`, params)
}

// 删除数字显示模板
export async function deleteScreenTemplate(id: any) {
  return apiDELETE(`/signage/templates/${id}`, null)
}

// 批量入驻员工
export async function batchUpdateMember(params: any) {
  return apiPUT(`/members/batch_update`, params)
}

/**
 * 场地统计 api
 */
// 楼层使用
export async function getBuildingDesks(params: any) {
  return apiGET(`/statistics/desks/current_usage_rate`, params)
}

// 当前工位使用率统计
export async function getDayDesks(params: any) {
  return apiGET(`/statistics/desks/by_location`, params)
}

// 按员工部门分布统计
export async function getUsersByDepartment(params: any) {
  return apiGET(`/statistics/users/by_department`, params)
}

// 按员工在职离职状态统计
export async function getUsersByState(params: any) {
  return apiGET(`/statistics/users/by_state`, params)
}

// 按日统计总会议次数，参与人数，总时长
export async function getMeetingSumTotals(params: any) {
  return apiGET(`/statistics/meeting_rooms/sum_totals`, params)
}

// 今日场地使用率统计
export async function getMeetingSumToday(params: any) {
  return apiGET(`/statistics/meeting_rooms/today`, params)
}

// 场地使用率统计
export async function getMeetingUsageRate(params: any, format = 'json') {
  return apiGET(`/statistics/meeting_rooms/usage_rate.${format}`, params)
}

//
// export async function getMeetingUsageRate(params, format='json') {
//   return apiGET(`/statistics/meeting_rooms/usage_rate.${format}`, params)
// }

// 每日场地使用密度统计
export async function getMeetingUsageDensity(params: any) {
  return apiGET(`/statistics/meeting_rooms/usage_density`, params)
}

// 占用统计
export async function getOccupancyDensity(params: any) {
  return apiGET(`/statistics/meeting_rooms/occupancy_density`, params)
}

// 最（不）活跃场地统计
export async function getMostReservedMeetingRooms(params: any) {
  return apiGET(`/statistics/meeting_rooms/most_reserved_rooms`, params)
}

// 最（不）活跃部门统计
export async function getMostReservingDepartments(params: any) {
  return apiGET(`/statistics/meeting_rooms/most_reserving_departments`, params)
}

// 最多预定但无占用的部门
export async function getMostReservedNotOccupiedDepartments(params: any) {
  return apiGET(
    `/statistics/meeting_rooms/most_reserved_not_occupied_departments`,
    params
  )
}

// 占用次数最多的会议室排名
export async function getMostReservedNotOccupiedAreas(params: any) {
  return apiGET(`/statistics/meeting_rooms/most_occupied_areas`, params)
}

// 会议室预定与占用关系
export async function getReservedOccupancyRate(params: any) {
  return apiGET(`/statistics/meeting_rooms/reserve_occupancy_rate`, params)
}

// 最多预定但无占用员工
export async function getMostReservedNotOccupiedUsers(params: any) {
  return apiGET(
    `/statistics/meeting_rooms/most_reserved_not_occupied_users`,
    params
  )
}

// 预定次数最多用户
export async function getMostReservedUsers(params: any) {
  return apiGET(`/statistics/meeting_rooms/most_reserved_users`, params)
}

// 场地类型分布
export async function getAreasByAreaType(params: any) {
  return apiGET(`/statistics/meeting_rooms/by_area_type`, params)
}

// 场地预订提前时间分布
export async function getAreasByLeadingTime(params: any) {
  return apiGET(`/statistics/meeting_rooms/leading_time`, params)
}

// 场地预订时长分布
export async function getAreasByReservationLength(params: any) {
  return apiGET(`/statistics/meeting_rooms/reservation_length`, params)
}

// 场地大小使用率分析
export async function getAreasByAreaCapacity(params: any) {
  return apiGET(`/statistics/meeting_rooms/by_area_capacity`, params)
}

// 按场地统计总会议次数，参与人数，总时长
export async function getAreasByAreaId(params: any) {
  return apiGET(`/statistics/meeting_rooms/by_area_id`, params)
}

export async function exportAreasByAreaId(params: any) {
  return apiGET(`/statistics/meeting_rooms/by_area_id.csv`, params)
}

// 场地是否过大或过小分布图
export async function getAreasCapacityFit(params: any) {
  return apiGET(`/statistics/meeting_rooms/capacity_fit`, params)
}

// 工位按天使用率统计
export async function getDeskUsageRate(params: any) {
  return apiGET(`/statistics/desks/usage_rate_history`, params)
}

// 延长工位预订
export async function extendDeskTime(reservationId: any, params: any) {
  return apiPUT(`/desk_reservations/${reservationId}/extend`, params)
}

// 延长会议预订
export async function extendMeetingTime(id: any, params: any) {
  return apiPUT(`/reservations/${id}/extend`, params)
}

// 查询延长工位的可预订时间段
export async function extendRanges(deskId: any) {
  return apiGET(`/desk_reservations/${deskId}/extend_ranges`)
}

// 查询延长会议的可预订时间段
export async function extendMeetingRanges(meetingId: any) {
  return apiGET(`/reservations/${meetingId}/extend_ranges`)
}

// 工位按天使用率导出
export async function getDeskUsageRateCsv(params: any) {
  return apiGET(`/statistics/desks/usage_rate_history.csv`, params)
}

// 当前工位按员工类型分布
export async function getEmployeeTypeDesks(params: any) {
  return apiGET(`/statistics/desks/by_employee_type`, params)
}

// 当前工位按部门分布
export async function getDepartmentDesks(params: any) {
  return apiGET(`/statistics/desks/by_department`, params)
}

// 导出工位按部门分布
export async function exportDepartmentDesks(params: any) {
  return apiGET(`/statistics/desks/by_department.csv`, params)
}

// 分区分布
export async function getAreaDesks(params: any) {
  return apiGET(`/statistics/desks/by_area`, params)
}
// 楼层分布
export async function getFloorDesks(params: any) {
  return apiGET(`/statistics/desks/by_floor_info`, params)
}

// 工位首页统计
export async function getLocationsDesksDashboard(id: any, params: any) {
  return apiGET(`/locations/${id}/desks/dashboard`, params)
}

// 工位统计
export async function getDesksDashboard(params: any) {
  return apiGET(`/statistics/desks/dashboard`, params)
}

// 各楼层工位数据（分店层面）
export async function getDeskStatsByFloorInfo(params: any) {
  return apiGET(`/statistics/desks/stats_by_floor_info`, params)
}

// 分店分组统计按天工作状态比例
export async function getWorkStateRatePerDayByLocation(params: any) {
  return apiGET(
    `/statistics/employee_work_statuses/work_state_rate_per_day_by_location`,
    params
  )
}

/**
 * 场地状态大屏 api
 */

// 创建场地大屏
export async function createMeetingStatusBoard(params: any) {
  return apiPOST(`/meeting_status_boards`, params)
}

// 场地大屏列表
export async function getMeetingStatusBoards(params: any) {
  return apiGET(`/meeting_status_boards`, params)
}

// 修改场地大屏信息
export async function updateMeetingStatusBoard(id: any, params: any) {
  return apiPUT(`/meeting_status_boards/${id}`, params)
}

// 场地大屏详情
export async function getMeetingStatusBoardDetail(id: any, params: any) {
  return apiGET(`/meeting_status_boards/${id}`, params)
}

// 删除场地大屏
export async function deleteMeetingStatusBoard(id: any) {
  return apiDELETE(`/meeting_status_boards/${id}`, null)
}

// 设备状态 statistic
export async function getPadsStats(params: any) {
  return apiGET(`/pads/stats`, params)
}

// 会议纪要
export async function getReservationSummaries(params: any) {
  return apiGET(`/reservation_summaries`, params)
}

// 会议纪要详情
export async function getReservationSummariesId(id: any) {
  return apiGET(`/reservation_summaries/${id}`)
}

// 更新会议纪要
export async function putReservationSummaries(id: any, params: any) {
  return apiPUT(`/reservation_summaries/${id}`, params)
}

// 创建会议纪要
export async function postReservationSummaries(params: any) {
  return apiPOST(`/reservation_summaries`, params)
}

// 增加会议 参会人
export async function postReservationAddattendees(id: any, params: any) {
  return apiPOST(`/reservations/${id}/add_attendees`, params)
}

// 工位列表
export async function getDesks(locationId: any, params: any) {
  return apiGET(`/locations/${locationId}/desks`, params)
}

// 修改工位
export async function updateDesk(locId: any, id: any, params: any) {
  return apiPUT(`/locations/${locId}/desks/${id}`, params)
}

// 修改工位
export async function batchUpdateDesks(locId: any, params: any) {
  return apiPUT(`/locations/${locId}/desks/batch_update`, params)
}

// 分配工位
export async function assignDesk(locId: any, deskId: any, params: any) {
  return apiPUT(`/locations/${locId}/desks/${deskId}/assign`, params)
}

// 获取预定工位
export async function getDeskReservations(params: any, format = 'json') {
  return apiGET(`/desk_reservations.${format}`, params)
}

// 导出预定工位
export async function getDeskReservationsCSV(params: any) {
  return apiGET(`/desk_reservations.csv`, params)
}

// 取消预订
export async function cancelReservationsDesks(id: any) {
  return apiDELETE(`/desk_reservations/${id}`)
}

// 工位预订详情
export async function deskReservationsDetail(id: any, params: any) {
  return apiGET(`/desk_reservations/${id}`, params)
}

// 预定工位
export async function createDeskReservations(params: any) {
  return apiPOST(`/desk_reservations`, params)
}

// 按部门预定数量分布图
export async function mostReservingDepartments(params: any) {
  return apiGET(`/statistics/desks/most_reserving_departments`, params)
}

// 按部门预定数量分布图
export async function mostReservingDepartmentsCsv(params: any) {
  return apiGET(`/statistics/desks/most_reserving_departments.csv`, params)
}

// 实际时长分布饼图
export async function realDuration(params: any) {
  return apiGET(`/statistics/desks/real_duration`, params)
}

// 实际时长分布饼图
export async function realDurationCsv(params: any) {
  return apiGET(`/statistics/desks/real_duration.csv`, params)
}

// 预订时长分布饼图
export async function bookingDuration(params: any) {
  return apiGET(`/statistics/desks/booking_duration`, params)
}

// 工位类型的比例分布
export async function countRateByDeskType(params: any) {
  return apiGET(`/statistics/desks/count_rate_by_desk_type`, params)
}

// 预订时长分布饼图
export async function bookingDurationCsv(params: any) {
  return apiGET(`/statistics/desks/booking_duration.csv`, params)
}

// 工位预订提前时间统计
export async function bookingLeadingTime(params: any) {
  return apiGET(`/statistics/desks/booking_leading_time`, params)
}

// 工位预订提前时间统计
export async function bookingLeadingTimeCsv(params: any) {
  return apiGET(`/statistics/desks/booking_leading_time.csv`, params)
}

// 流动工位预订使用率统计
export async function usageRate(params: any) {
  return apiGET(`/statistics/desks/usage_rate`, params)
}

// 流动工位预订使用率统计
export async function usageRateCsv(params: any) {
  return apiGET(`/statistics/desks/usage_rate.csv`, params)
}

// 取消预订工位
export async function cancelDeskReservation(id: any) {
  return apiDELETE(`/desk_reservations/${id}`)
}

// 释放
export async function releaseDesk(id: any, deskId: any) {
  return apiPUT(`/locations/${id}/desks/${deskId}/release`)
}

// 同时搜索工位, 分区 和 用户
export async function multiSearch(params: any) {
  return apiGET(`/space/multi_search`, params)
}

/*
  操作记录api
*/

// 操作记录列表
export async function getOptRecords(params: any, format = 'json') {
  return apiGET(`/audit_logs.${format}`, params)
}

// 操作记录详情
export async function getOptRecordDetail(id: any) {
  return apiGET(`/audit_logs/${id}`, null)
}

// 添加操作记录
export async function postOptRecord(params: any) {
  return apiPOST(`/audit_logs`, params)
}

// 会议室操作记录
export async function getAreaLogs(params: any) {
  return apiGET(`/audit_logs/get_area_logs`, params)
}

// 更新日志列表
export async function getChangelogs(params: any) {
  return apiGET(`/changelogs`, params)
}

// 更新日志详情
export async function getChangeLogId(id: any) {
  return apiGET(`/changelogs/${id}`)
}

// 增加附件（单个增加）
export async function postAddAttachment(params: any) {
  return apiPOST(`/mediums/`, params)
}

// 删除附件
export async function deleteAttachment(id: any) {
  return apiDELETE(`/mediums/${id}`)
}

// 会议纪要列表
export async function getReservationSummariesList(params: any) {
  return apiGET(`/reservation_summaries`, params)
}

// 创建会议纪要
export async function postAddReservationSummaries(params: any) {
  return apiPOST(`/reservation_summaries/`, params)
}

// 更新会议纪要
export async function putNewReservationSummaries(id: any, params: any) {
  return apiPUT(`/reservation_summaries/${id}`, params)
}

// 删除会议纪要
export async function deleteReservationSummaries(id: any) {
  return apiDELETE(`/reservation_summaries/${id}`)
}

// 会议纪要详情
export async function getReservationSummariesDetail(id: any) {
  return apiGET(`/reservation_summaries/${id}`)
}

// 通讯列表
export async function getUserBooksList(params: any) {
  return apiGET(`/user_books`, params)
}

// 通讯列表
export async function getUserBook(id: any) {
  return apiGET(`/user_books/${id}`)
}

// 创建通讯录
export async function createUserBooks(params: any) {
  return apiPOST(`/user_books`, params)
}

// 删除通讯录
export async function deleteUserBooks(ids: any) {
  return apiDELETE(`/user_books/batch_destroy`, ids)
}

// 更新通讯录
export async function updateUserBook(id: any, params: any) {
  return apiPUT(`/user_books/${id}`, params)
}

// 批量导入通讯录
export async function importUserBooks(params: any) {
  return apiFileUpload(`/user_books/import_user_books`, params, 'POST')
}

// 下载通讯录批量导入模版
export async function downloadTemplate() {
  return apiGET(`/user_books/download_template`)
}

// 添加会议设备
export async function createAreasDevice(id: any, params: any) {
  return apiPOST(`/meeting_rooms/${id}/create_areas_device`, params)
}

// 删除会议设备
export async function deleteAreasDevice(id: any, params: any) {
  return apiDELETE(`/meeting_rooms/${id}/destroy_areas_device`, params)
}
// 批量更新会议设备
export async function updateAreasDevice(id: any, params: any) {
  return apiPOST(`/meeting_rooms/${id}/batch_update_areas_device`, params)
}

// 恢复停用中的工位
export async function desksEnable(location_id: any, id: any) {
  return apiPUT(`/locations/${location_id}/desks/${id}/enable`)
}

// 停用工位
export async function desksDisable(location_id: any, id: any, params: any) {
  return apiPUT(`/locations/${location_id}/desks/${id}/disable`, params)
}

// 当前用户取消收藏会议室
export async function meetingRoomCancelCollect(params: any) {
  return apiPUT(`/user/meeting_room_cancel_collect`, params)
}

// 当前用户收藏会议室
export async function meetingRoomCollect(params: any) {
  return apiPUT(`/user/meeting_room_collect`, params)
}

// 批量联网开锁
export async function batchUnlock(params: any) {
  return apiPOST(`/locks/batch_unlock`, params)
}

// 通知设置列表
export async function getNotificationSettings(params: any) {
  return apiGET(`/notification_settings`, params)
}

// 添加通知设置
export async function addNotificationSettings(params: any) {
  return apiPOST(`/notification_settings`, params)
}

// 工位预订与占用关系
export async function deskReservationAndOccupancyRelate(params: any) {
  return apiGET(`/statistics/desks/reservation_state_occupied_rate`, params)
}

// 会议室预订与占用关系
export async function meetingReservationAndOccupancyRelate(params: any) {
  return apiGET(
    `/statistics/meeting_rooms/reservation_state_occupied_rate`,
    params
  )
}

export async function meetingRoomsDashBoard(params: any) {
  return apiGET(`/statistics/meeting_rooms/dashboard`, params)
}

// 会议室各分店会议室数据（会议室数量，会议室预定率，取消率，重复使用率）*
export async function meetingStatsByLocation(params: any) {
  return apiGET(`/statistics/meeting_rooms/stats_by_location`, params)
}

// 各分店工位数据（空间层面）
export async function deskStatsByLocation(params: any) {
  return apiGET(`/statistics/desks/stats_by_location`, params)
}

// 预定时长分布
export async function meetingReservationLength(params: any) {
  return apiGET(`/statistics/meeting_rooms/reservation_length`, params)
}

// 房间容纳数据
export async function meetingCapacityStats(params: any) {
  return apiGET(`/statistics/meeting_rooms/capacity_stats`, params)
}

// 各楼层会议室数据（会议室数量，会议室预定率，取消率，重复使用率）*
export async function meetingStatsByFloorInfo(params: any) {
  return apiGET(`/statistics/meeting_rooms/stats_by_floor_info`, params)
}

// 0~24小时预定次数
export async function reserveCountByHour(params: any) {
  return apiGET(`/statistics/meeting_rooms/reserve_counts_by_hour`, params)
}

// 按工位统计预订次数，时长
export async function deskReservationCountAndTime(params: any) {
  return apiGET(`/statistics/desks/by_desk_id`, params)
}

// 查看工位每五分钟占用
export async function occupiedPerMin(params: any) {
  return apiGET(`/statistics/desks/occupied_per_min`, params)
}

// 查看工位每五分钟人数占用
export async function occupiedPersonsPerMin(params: any) {
  return apiGET(`/statistics/desks/occupied_persons_per_min`, params)
}

// 查看会议室每五分钟占用
export async function occupiedPerMinOfMeetingRooms(params: any) {
  return apiGET(`/statistics/meeting_rooms/occupied_per_min`, params)
}

// 查看会议室每五分钟人数占用
export async function occupiedPersonsPerMinOfMeetingRooms(params: any) {
  return apiGET(`/statistics/meeting_rooms/occupied_persons_per_min`, params)
}

// 按预定人预订数量分布图
export async function mostReservingReservedBys(params: any) {
  return apiGET(`/statistics/desks/most_reserving_reserved_bys`, params)
}

// 各部门预定率
export async function reserveRateByDepartment(params: any) {
  return apiGET(`/statistics/desks/reserve_rate_by_department`, params)
}

// 智能设备列表
export async function smartDevices(params: any) {
  return apiGET(`/smart_devices`, params)
}

// 智能设备列表批量开启、关闭离线通知
export async function batchOfflineNotification(params: any) {
  return apiPUT(`/smart_devices/batch_offline_notification`, params)
}

// 智能设备列表开启、关闭离线通知
export async function setOfflineNotification(params: any) {
  return apiPUT(`/smart_devices/set_offline_notification`, params)
}

export async function devicesTotalControl(params: any) {
  return apiPUT('/smart_devices/total_control', params)
}

// 区域类型列表
export async function areaTypes(params: any) {
  return apiGET(`/area_types`, params)
}

// 预定趋势
export async function reservationStateRate(params: any) {
  return apiGET(`/statistics/desks/reservation_state_rate`, params)
}

// 预定密度
export async function reservationDensity(params: any) {
  return apiGET(`/statistics/desks/reservation_density`, params)
}

// 设备报修日志列表
export async function getDeviceRepairLogs(params: any) {
  return apiGET(`/device_repair_logs`, params)
}

// 导出设备报修日志列表
export async function getDeviceRepairLogsCSV(params: any) {
  return apiGET(`/tasks.csv`, params)
}

// 更新设备报修日志
export async function putDeviceRepairLogs(id: any, params: any) {
  return apiPUT(`/device_repair_logs/${id}`, params)
}

// 会务配置项列表
export async function productsList(params: any) {
  return apiGET(`/products`, params)
}

// 添加会务配置项
export async function postProducts(params: any) {
  return apiPOST(`/products`, params)
}

// 更新会务配置项
export async function putProducts(id: any, params: any) {
  return apiPUT(`/products/${id}`, params)
}

// 删除会务配置项
export async function deleteProducts(id: any) {
  return apiDELETE(`/products/${id}`)
}

// 会务列表
export async function productsOrderList(params: any) {
  return apiGET(`/reservation_product_orders`, params)
}

// 更新会务
export async function putProductsOrder(id: any, params: any) {
  return apiPUT(`/reservation_product_orders/${id}`, params)
}

// 导出会务列表
export async function getProductsOrderCSV(params: any) {
  return apiGET(`/reservation_product_orders.csv`, params)
}

// 会务列表
export async function getMeetingRoomCollections(params: any) {
  return apiGET(`/user/meeting_room_collects`, params)
}

// 智能设备批量开启，关闭
export async function putBatchSwitch(params: any) {
  return apiPUT(`/smart_devices/batch_switch`, params)
}

// 工位历史列表
export async function getDesksVersions(location_id: any, params: any) {
  return apiGET(`/locations/${location_id}/desks/versions`, params)
}

// 获取时区列表
export async function getTimeZone(params: any) {
  return apiGET(`/settings/timezones`, params)
}

// 获取工位占用率
export async function getOccupancyHistoryByDesk(params: any) {
  return apiGET(`/statistics/desks/occupancy_histroy_by_desk`, params)
}

// 立即同步按钮
export async function putSynchronous(params: any) {
  return apiPUT(`/members/sync_third_party`, params)
}

// 批量添加会务项

export async function postProductsBatchCreate(params: any) {
  return apiPOST(`/products/batch_create`, params)
}

// 展示空间中对接的外部服务状态
export async function getSpaceIntegrations() {
  return apiGET(`/space/integrations`)
}

// 当前用户取消关联第三方账户
export async function putDestroy_omniauth_account(params: any) {
  return apiPUT(`/user/destroy_omniauth_account`, params)
}

// 显示日历同步信息,如果尚未同步，返回空信息
export async function getMeeting_roomsCalendars(id: any) {
  return apiGET(`/meeting_rooms/${id}/calendars`)
}

// 获取会议室开放时段
export async function getMeetingRoomsOpenHours(params: any) {
  return apiGET(`/meeting_rooms/meeting_room_open_hours`, params)
}

// 添加外部(Outlook)日历到会议室
export async function putMeeting_roomsCalendars(id: any, params: any) {
  return apiPUT(`/meeting_rooms/${id}/calendar`, params)
}

// 取消关联office365
export async function deleteSpace_cancel_office365() {
  return apiDELETE(`/space/cancel_office365`)
}

// 会议模板列表
export async function getReservationTemplates(params: any) {
  return apiGET(`/user/reservation_templates`, params)
}

// 删除会议模板
export async function deleteReservationTemplate(id: any) {
  return apiDELETE(`/reservation_templates/${id}`)
}

// 置顶会议室
export async function setTopMeetingRoom(id: any, params: any) {
  return apiPUT(`/meeting_rooms/${id}/top_meeting_room`, params)
}

// 活动列表
export async function getActivities(locationId: any, params: any) {
  return apiGET(`/activities/activities`, {
    location_id: locationId,
    ...params,
  })
}

// 创建活动
export async function createActivities(params: any) {
  return apiPOST(`/activities/activities`, params)
}

// 编辑活动
export async function updateActivity(activityId: any, params: any) {
  return apiPUT(`/activities/activities/${activityId}`, params)
}

// 活动详情
export async function showActivity(activityId: any, params: any) {
  return apiGET(`/activities/activities/${activityId}`, params)
}

//  活动报名列表
export async function getActivityOrders(activityId: any, params: any) {
  return apiGET(`/activities/activities/${activityId}/orders`, params)
}

// 删除活动
export async function deleteActivity(activityId: any, params: any) {
  return apiDELETE(`/activities/activities/${activityId}`, params)
}

// 签到活动
export async function activitySign(activityId: any, id: any, params: any) {
  return apiPUT(
    `/activities/activities/${activityId}/orders/${id}/signin`,
    params
  )
}

// 取消活动报名
export async function cancelActivityOrder(activityId: any, id: any) {
  return apiPUT(`/activities/activities/${activityId}/orders/${id}/cancel`)
}

// 活动报名
export async function orderActivity(activityId: any, params: any) {
  return apiPOST(`/activities/activities/${activityId}/orders`, params)
}

// 获取活动提问列表
export async function getActivityQuestion(activityId: any, params: any) {
  return apiGET(`/activities/activities/${activityId}/questions`, params)
}

// 活动问题回答
export async function addQuestionReply(activity_id: any, id: any, params: any) {
  return apiPOST(
    `/activities/activities/${activity_id}/questions/${id}/reply`,
    params
  )
}

// 删除活动评论
export async function deleteActivityComment(activity_id: any, id: any) {
  return apiDELETE(`/activities/activities/${activity_id}/comments/${id}`)
}

// 删除活动问题
export async function deleteActivityQuestion(activity_id: any, id: any) {
  return apiDELETE(`/activities/activities/${activity_id}/questions/${id}`)
}

// 用户向活动提问
export async function addActivityQuestion(activityId: any, params: any) {
  return apiPOST(`/activities/activities/${activityId}/questions`, params)
}

// 取消活动
export async function cancelActivity(activity_id: any, params: any) {
  return apiPUT(`/activities/activities/${activity_id}/cancel`, params)
}

// 取消关联企业微信
export async function cancelQiyeWechat() {
  return apiDELETE(`/space/cancel_qiye_wechat`)
}

// 时间规则
export async function getTimeRules(params: any) {
  return apiGET(`/attendances/working_schedules`, params)
}

// 创建时间规则
export async function createWorkingSchedule(params: any) {
  return apiPOST(`/attendances/working_schedules`, params)
}

// 编辑时间规则
export async function updateWorkingSchedule(id: any, params: any) {
  return apiPUT(`/attendances/working_schedules/${id}`, params)
}

// 删除时间规则
export async function deleteWorkingSchedule(id: any, params = {}) {
  return apiDELETE(`/attendances/working_schedules/${id}`, params)
}

// 时间规则详情
export async function getWorkingSchedule(id: any) {
  return apiGET(`/attendances/working_schedules/${id}`)
}

// 获取活动类型
export async function getActivityTypes(params: any) {
  return apiGET(`/activities/activity_types`, params)
}

// 创建活动类型
export async function createActivityType(params: any) {
  return apiPOST(`/activities/activity_types`, params)
}

// 更新活动类型
export async function updateActivityType(id: any, params: any) {
  return apiPUT(`/activities/activity_types/${id}`, params)
}

// 工作台列表
export async function getApprovalRecords(params: any) {
  return apiGET(`/approval_records`, params)
}
// 工作台列表 我的待审批
export async function getApprovalRecordsDeals(params: any) {
  return apiGET(`/approval_records/deals`, params)
}

// 通过审批
export async function throughApprove(id: any, params: any) {
  return apiPUT(`/approval_records/${id}/approve`, params)
}

// 会议驳回审批
export async function rejectApprove(id: any, params: any) {
  return apiPUT(`/approval_records/${id}/reject`, params)
}

// 批量审批通过
export async function workSpaceBatchApprove(params: any) {
  return apiPUT(`/approval_records/batch_approve`, params)
}

// 批量拒绝
export async function workSpaceBatchReject(params: any) {
  return apiPUT(`/approval_records/batch_reject`, params)
}

// 删除人脸功能
export async function deleteMemberFace(params: any) {
  return apiDELETE(`/members/destroy_face_photo`, params)
}

// 删除访客人脸
export async function deleteVisitorFace(id: any) {
  return apiDELETE(`/visitors/visitors/${id}/destroy_face_photo`)
}

// 获取所有分店园区
export async function getLocationGroups(params: any) {
  return apiGET(`/location_groups`, params)
}

// 获取园区详情
export async function getLocationGroupsDetail(id: any) {
  return apiGET(`/location_groups/${id}`)
}

// 修改园区
export async function editLocationGroups(id: any, params: any) {
  return apiPUT(`/location_groups/${id}`, params)
}

// 添加园区
export async function addLocationGroups(params: any) {
  return apiPOST(`/location_groups`, params)
}

// 创建快速消息
export async function addQuickMessage(params: any) {
  return apiPOST(`/quick_messages`, params)
}

// 编辑快速消息
export async function updateQuickMessage(id: any, params: any) {
  return apiPUT(`/quick_messages/${id}`, params)
}

// 编辑快速消息
export async function sendQuickMeesage(id: any, params: any) {
  return apiPOST(`/quick_messages/${id}/send_message`, params)
}

// 删除快速消息
export async function deleteQuickMessage(id: any) {
  return apiDELETE(`/quick_messages/${id}`)
}

// 查看快速消息详情
export async function getQuickMessage(id: any) {
  return apiGET(`/quick_messages/${id}`)
}

// 快速消息列表
export async function getSendMessageList(params: any) {
  return apiGET(`/quick_messages`, params)
}

// 任务管理查询
export async function getTasksList(params: any) {
  return apiGET(`/tasks`, params)
}

// 添加任务
export async function addTasks(params: any) {
  return apiPOST(`tasks`, { ...params, post_source: 'managment' })
}

// 更新任务
export async function editTasks(id: any, params: any) {
  return apiPUT(`tasks/${id}`, params)
}

// 更新任务
export async function editTasksChangeState(params: any) {
  return apiPUT(`tasks/change_state`, params)
}

// 重置链接
export async function resetVisitorLink(id: any) {
  return apiPUT(`/locations/${id}/reset_visitor_link`)
}

// 手动下发权限
export async function manualSync(id: any, params: any) {
  return apiPOST(`/lock_permissions/${id}/manual_sync`, params)
}

// 检查状态
export async function refreshStatus(params: any) {
  return apiGET(`/smart_devices/refresh_status`, params)
}

// 各分店报修数统计
export async function getTaskCountPerDayGroupByLocation(params: any) {
  return apiGET(
    `/statistics/tasks/task_count_per_day_group_by_location`,
    params
  )
}

// 各分店用电量统计
export async function getSampleValueNumberPerDayByLocation(params: any) {
  return apiGET(
    `/statistics/sensor_data/sample_value_number_per_day_by_location`,
    params
  )
}

// saml设置
export async function samlSetting(params: any) {
  return apiPUT(`/space/saml_settings`, params)
}

// 报修添加描述
export async function addRepairComment(id: any, params: any) {
  return apiPUT(`/tasks/${id}/add_comment`, params)
}

// 手动下发权限
export async function reManualSync(id: any, params: any) {
  return apiPOST(`/lock_permissions/${id}/remanual_sync`, params)
}

// 会议室重复利用率及时间
export async function meetingRecaptureData(params: any) {
  return apiGET(`/statistics/meeting_rooms/recapture_data`, params)
}

// 工位计算重用率和时间
export async function deskRecaptureData(params: any) {
  return apiGET(`/statistics/desks/recapture_data`, params)
}

// 会议取消率
export async function meetingCancelRate(params: any) {
  return apiGET(`/statistics/meeting_rooms/cancellation_rate`, params)
}

// 会议室导出数据
export async function meetingExportData(params: any) {
  return apiGET(`/statistics/meeting_rooms/export_data`, params)
}

// 二维码下载
export async function exportQrcodes(params) {
  return apiGET(`/qrcodes/export`, params)
}

// 工位取消率
export async function deskCancelRate(params: any) {
  return apiGET(`/statistics/desks/cancellation_rate`, params)
}

// 空间层面总报修数量和关闭数量统计
export async function taskCountGroupByLocation(params: any) {
  return apiGET(`/statistics/tasks/task_count_group_by_location`, params)
}

// 报修每日新增及其活跃任务统计
export async function taskIncrementStatistic(params: any) {
  return apiGET(`/statistics/tasks/increment_statistic`, params)
}

// 报修数量统计
export async function repairTaskCountStatistic(params: any) {
  return apiGET(`/statistics/tasks/repair_task_count_statistic`, params)
}

// 各分店预定数统计
export async function getReservedCountPerDayByLocation(params: any) {
  return apiGET(
    `/statistics/meeting_rooms/reserved_count_per_day_by_location`,
    params
  )
}

// 各分店工位预定数统计
export async function getDeskReservedCountPerDayByLocation(params: any) {
  return apiGET(`/statistics/desks/reserved_count_per_day_by_location`, params)
}

// 各分店访客签到数统计
export async function getSignedCountPerDayByLocation(params: any) {
  return apiGET(`/statistics/visitors/signed_count_per_day_by_location`, params)
}

// 报修任务分类统计
export async function subtypeTaskStatistic(params: any) {
  return apiGET(`/statistics/tasks/subtype_task_statistic`, params)
}

// 快递任务状态统计
export async function subStatusTaskStatistic(params: any) {
  return apiGET(`/statistics/tasks/task_count_by_state`, params)
}

// 报修任务按重要性统计
export async function priorityTaskStatistic(params: any) {
  return apiGET(`/statistics/tasks/priority_task_statistic`, params)
}

// 报修任务负责人统计
export async function processorTaskStatistic(params: any) {
  return apiGET(`/statistics/tasks/processor_task_statistic`, params)
}

// 报修任务按区域统计
export async function areaTaskStatistic(params: any) {
  return apiGET(`/statistics/tasks/area_task_statistic`, params)
}

// 传感器数据历史记录
export async function sensorDataHistory(params: any) {
  return apiGET(`/statistics/sensor_data/history`, params)
}
// 传感器数据历史记录 /managements/v1/statistics/sensor_data/every_history
export async function sensorDataEveryHistory(params: any) {
  return apiGET(`/statistics/sensor_data/every_history`, params)
}

// 获取报修类型列表
export async function getRepairTypes(params: any) {
  return apiGET(`/task_types`, params)
}

// 获取报修类型任务类型可用分区
export async function getTaskTypesAvailableAreaTypes(params: any) {
  return apiGET(`/task_types/available_area_types`, params)
}

// 增加报修类型列表
export async function createRepairType(params: any) {
  return apiPOST(`/task_types`, params)
}

// 修改报修类型列表
export async function updateRepairType(repairTypeId: any, params: any) {
  return apiPUT(`/task_types/${repairTypeId}`, params)
}

// 空气实时传感器数据
export async function getAirRealTime(params: any) {
  return apiGET(`/statistics/sensor_data/air_realtime`, params)
}

// 获取今日日程
export async function getSchedule(params: any, restProps: any) {
  return apiGET(`/user/schedule`, params, restProps)
}

// fengmap search
export async function searchSpace(params: any) {
  return apiGET(`/space/search`, params)
}

export async function setLockMaintainOpen(params: any) {
  return apiPUT(`/smart_devices/switch_mode`, params)
}

// POST /managements/${import.meta.env.VITE_API_VERSION}/locations/{location_id}/desks/{id}/create_desk_device添加工位设备*
export async function createDeskDevice(location_id: any, id: any, params: any) {
  return apiPOST(
    `/locations/${location_id}/desks/${id}/create_desk_device`,
    params
  )
}

// DELETE /managements/${import.meta.env.VITE_API_VERSION}/locations/{location_id}/desks/{id}/destroy_desk_device删除工位设备*
export async function deleteDeskDevice(location_id: any, id: any, params: any) {
  return apiDELETE(
    `/locations/${location_id}/desks/${id}/destroy_desk_device`,
    params
  )
}

// 获取云视频方数
export async function getCloudPartiesConf(params: any) {
  return apiGET(`/spaces/biz_conf_parties`, params)
}

// 按工作日预定率
export async function getReservedRateByWday(params: any) {
  return apiGET(`/statistics/meeting_rooms/reserved_rate_by_wday`, params)
}
// 快速创建分店
export async function locationQuickDemo(params: any) {
  return apiPOST(`/locations/quick_demo`, params)
}
// 获取访客申请链接、二维码
export async function getVisitorApplyUrl(params: any) {
  return apiGET('/visitors/visitors/apply_url', params)
}

export async function getVisitorInfoByCode(params: any) {
  return apiGET('/visitors/visitors/search', params)
}
//
export async function authWebex(params: any) {
  return apiGET('/spaces/webex', params)
}

// 用户组
export async function getUserGroup(data: any) {
  return apiGET('/user_groups', data)
}

// 用户组详情
export async function getUserGroupView(params: any) {
  const { id } = params
  return apiGET(`/user_groups/${id}`, params)
}

export async function postUserGroup(data: any) {
  return apiPOST('/user_groups', data)
}

export async function putUserGroup(data: any) {
  const { id } = data
  return apiPUT(`/user_groups/${id}`, data)
}

export async function deleteUserGroup(data: any) {
  const { id } = data
  return apiDELETE(`/user_groups/${id}`, data)
}
// 退出人员组
export async function updateUserGroup(data: any) {
  return apiPUT(`/user_groups/batch_update`, data)
}
// 场景
// 场景列表
export async function getScenes(data: any) {
  return apiGET('/scenes', data)
}
// 场景
export async function getScene(data: any) {
  const { id } = data
  return apiGET(`/scenes/${id}`, data)
}
// 创建场景
export async function postScenes(data: any) {
  return apiPOST('/scenes', data)
}
// 修改场景
export async function putScenes(data: any) {
  const { id } = data
  return apiPUT(`/scenes/${id}`, data)
}
// 删除场景
export async function deleteScenes({ id }: any) {
  return apiDELETE(`/scenes/${id}`, {})
}

// 自动化设备类型
export async function getAutomationFormat() {
  return apiGET('/scenes/automation_format', {})
}

export async function getAutomations(data: any) {
  return apiGET('/scenario_automations', data)
}

export async function postAutomations(data: any) {
  return apiPOST('/scenario_automations', data)
}

export async function putAutomations(data: any) {
  const { id } = data
  return apiPUT(`/scenario_automations/${id}`, data)
}

export async function getAutomationSettings(data: any) {
  return apiGET(`/scenario_automations/rule_settings`, data)
}

export async function deleteAutomations({ id }: any) {
  return apiDELETE(`/scenario_automations/${id}`, {})
}

export async function getAutomation(data: any) {
  const { id } = data
  return apiGET(`/scenario_automations/${id}`, data)
}

export async function getSceneDevices(data: any) {
  return apiGET(`/smart_devices/scene_devices`, data)
}
// 执行场景
export async function putActScene(data: any) {
  const { id } = data
  return apiPUT(`/scenes/${id}/act_scene`, data)
}
// 储物柜类型
export async function getCabinetCategories(data: any) {
  return apiGET('/smart_locker/cabinets/locker_categories', data)
}

export async function exportDeskData({ locationId, ...data }: any) {
  return apiGET(`/locations/${locationId}/desks.csv`, data)
}

export async function exportLockPermission(data: any) {
  return apiGET('/lock_permissions/member_lock_permissions_info.csv', data)
}

export async function getGroupMembers(data: any) {
  const { id, ...rest } = data
  return apiGET(`/user_groups/${id}/members`, rest)
}
// 导出员工
export async function exportMembers(data: any) {
  return apiGET(`/members.csv`, data)
}

// 上传楼层，分区，工位等
export async function uploadSpaceData(id: any, formData: any) {
  return apiFileUpload(`/locations/${id}/floor_infos/import`, formData, 'put')
}

// 地图数据同步到分店
// locations/#{location.id}/floor_infos/sync_mapinfo
export async function syncMapInfo(location_id: any, data: any) {
  return apiPOST(`locations/${location_id}/floor_infos/sync_mapinfo`, data)
}

// 给预定添加会务
// PUT /managements/v1/reservations/{id}/create_product_order
export async function createProductOrder(id: any, data: any) {
  return apiPUT(`/reservations/${id}/create_product_order`, data)
}

// 工位组列表
export async function getDeskGroups(locationId: any, data: any) {
  return apiGET(`locations/${locationId}/desk_groups`, data)
}
// 创建工位组
export async function postDeskGroups(locationId: any, data: any) {
  return apiPOST(`locations/${locationId}/desk_groups`, data)
}
// 更新工位组
export async function putDeskGroups(locationId: any, id: any, data: any) {
  return apiPUT(`locations/${locationId}/desk_groups/${id}`, data)
}
// 删除工位组
export async function deleteDeskGroups(locationId: any, id: any) {
  return apiDELETE(`locations/${locationId}/desk_groups/${id}`)
}

// 工位删除
// DELETE /managements/v1/locations/{location_id}/desks/{id}
export async function deleteDesk(locationId: any, id: any) {
  return apiDELETE(`locations/${locationId}/desks/${id}`)
}

export async function getResourceData(
  locationId: any,
  params: any,
  restProps: any
) {
  return apiGET(`locations/${locationId}/resource_data`, params, restProps)
}

// 工位、分区、会议室一键关联
export async function batchLinkResource(data: any) {
  const { id, location_id, ...rest } = data
  return apiPUT(
    `locations/${location_id}/floor_infos/${id}/batch_relevance`,
    rest
  )
}

// 一键分配工位
export async function batchAssignDesks(params: any) {
  return apiPOST(`/rentings/batch_assign`, params)
}

// 一键解除分配工位
export async function batchCancelDesks(params: any) {
  return apiPUT(`/rentings/batch_cancel`, params)
}

// 工作日历
export async function getWorkStatuses(userId: any, params: any) {
  return apiGET(`/members/${userId}/work_statuses`, params)
}

// 同事的工作日历
export async function getMemberFollows(params: any) {
  return apiGET(`/members/follows`, params)
}

export async function followMember(params: any) {
  return apiPOST(`/members/add_follow`, params)
}

export async function cancelFollowMember(params: any) {
  return apiDELETE(`/members/cancel_follow`, params)
}

// 工作日历
export async function updateWorkStatus(userId: any, params: any) {
  return apiPUT(`/members/${userId}/update_work_statuses`, params)
}

// 分店详情
export async function getLocation(locationId: any, params: any) {
  return apiGET(`/locations/${locationId}`, params)
}

// 工位使用记录
export async function getDeskUsageRecords(
  locationId: any,
  params: any,
  format = 'json'
) {
  return apiGET(
    `/locations/${locationId}/desks/usage_records.${format}`,
    params
  )
}

// 删除settings /managements/v1/settings/0
export async function deleteSettings(params: any) {
  return apiDELETE(`/settings/0`, params)
}

// 查询 webhooks 列表
export async function getWebhooks(params: any) {
  return apiGET(`/webhooks`, params)
}

// 查询 webhooks 事件集合
export async function getWebhooksEvents(params: any) {
  return apiGET(`/webhooks/events`, params)
}

// 创建 webhooks 事件集合
export async function postWebhook(params: any) {
  return apiPOST(`/webhooks`, params)
}

// 编辑 webhooks 事件集合
export async function updateWebhook(id: any, params: any) {
  return apiPUT(`webhooks/${id}`, params)
}
// 查询 webhooks 详情
export async function webhookDetail(id: any) {
  return apiGET(`/webhooks/${id}`)
}

// 删除 webhook webhooks/{id}
export async function deleteWebhook(id: any) {
  return apiDELETE(`/webhooks/${id}`)
}

// /managements/v1/reservations/collisions 查询会议室冲突
export async function getCollisions(params: any) {
  return apiGET(`/reservations/collisions`, params)
}

// /managements/v1/space/plans 查询所有订阅Plans
export async function getPlans(params: any) {
  return apiGET(`/saas/subscriptions/plans`, params)
}

// 批量取消预约会议室
// /managements/v1/reservations/batch_destroy
export async function deleteReservations(params: any) {
  return apiDELETE(`/reservations/batch_destroy`, params)
}

// 考勤员工组设置列表
// /managements/v1/attendances/working_attendances
export async function getWorkingAttendances(params: any) {
  return apiGET(`/attendances/working_attendances`, params)
}

// 批量创建考勤设置
// /managements/v1/attendances/working_attendances/batch_create
export async function postWorkingAttendancesBatchCreate(params: any) {
  return apiPOST(`/attendances/working_attendances/batch_create`, params)
}
// GET /managements/v1/locations/usage_rate  分店使用率 start_date end_date
export async function getUsageRate(params: any) {
  return apiGET(`/locations/usage_rate`, params)
}

// GET /managements/v1/locations/weekly_times_percents  分店员工到分店次数分布 start_date end_date location_id
export async function getWeeklyTimesPercents(params: any) {
  return apiGET(`/locations/weekly_times_percents`, params)
}

// GET /managements/v1/locations/avg_work_office_times 各分店每周到公司的平均数 start_date end_date location_id
export async function getAvgWorkOfficeTimes(params: any) {
  return apiGET(`/locations/avg_work_office_times`, params)
}

// GET /managements/v1/locations/work_office_rate_weekly 每周几到公司员工的百分比 start_date end_date location_id
export async function getWorkOfficeRateWeekly(params: any) {
  return apiGET(`/locations/work_office_rate_weekly`, params)
}

// PUT /managements/v1/users/set_password
export async function updatePassword(params: any, token: any) {
  return apiPUT(`/users/set_password`, params, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

// /managements/v1/locations/{id}/paid_feature 购买功能
export async function postPaidFeature(id: any, params: any) {
  return apiPUT(`/locations/${id}/paid_feature`, params)
}

// /managements/v1/users/unlock 解锁用户
export async function putUnlock(params: any) {
  return apiPUT(`/users/unlock`, params)
}

// /managements/v1/locations/office_employees_counts_day 获得每天的到岗人数
export async function getOfficeEmployeesCountsDay(params: any) {
  return apiGET(`/locations/office_employees_counts_day`, params)
}

// /managements/v1/members/followings_work_statuses 关注的人的工作日历
export async function getFollowingsWorkStatuses(params: any) {
  return apiGET(`/members/followings_work_statuses`, params)
}

// /managements/v1/members/{id}/other_work_statuses 获取其他员工办公状态
export async function getOtherWorkStatuses(id: any, params: any) {
  return apiGET(`/members/${id}/other_work_statuses`, params)
}
// /managements/v1/members/invite 邀请同事
export async function postMembersInvitee(params: any) {
  return apiPOST(`/members/invite`, params)
}
// /managements/v1/data_view_projects 数据大屏列表
export async function getDataViewProjects(params: any) {
  return apiGET(`/data_view_projects`, params)
}
// /managements/v1/data_view_projects/{id} 删除数据大屏
export async function deleteDataViewProjects(id: any) {
  return apiDELETE(`/data_view_projects/${id}`)
}
// /managements/v1/data_view_projects 创建
export async function createDataViewProjects(params: any) {
  return apiPOST(`/data_view_projects`, params)
}
// /managements/v1/data_view_projects/{id} 编辑数据大屏
export async function editDataViewProjects(id: any, params: any) {
  return apiPUT(`/data_view_projects/${id}`, params)
}

// /managements/v1/members/invite_work_office 邀请同事到公司办公
export async function putInviteWorkOffice(params: any) {
  return apiPUT(`/members/invite_work_office`, params)
}

// /managements/v1/space_custome_fields 标签列表
export async function getSpaceCustomeFields(params: any) {
  return apiGET(`/space_custome_fields`, params)
}
// /managements/v1/space_custome_fields/{id} 删除标签
export async function deleteSpaceCustomeFields(id: any) {
  return apiDELETE(`/space_custome_fields/${id}`)
}
// /managements/v1/space_custome_fields 创建标签
export async function createSpaceCustomeFields(params: any) {
  return apiPOST(`/space_custome_fields`, params)
}
// /managements/v1/space_custome_fields/{id} 修改标签
export async function putSpaceCustomeFields(id: any, params: any) {
  return apiPUT(`/space_custome_fields/${id}`, params)
}
// /managements/v1/space_custome_fields/batch_add 批量创建标签
export async function createBatchSpaceCustomeFields(params: any) {
  return apiPOST(`/space_custome_fields/batch_add`, params)
}

// 发票地址查看 /managements/v1/saas/billing_addresses
export async function getBillingAddresses(params: any) {
  return apiGET(`/saas/billing_addresses`, params)
}
// 发票地址查看 /managements/v1/saas/billing_addresses
export async function postBillingAddresses(params: any) {
  return apiPOST(`/saas/billing_addresses`, params)
}
// /managements/v1/space_custome_fields/{id} 修改标签
export async function putBillingAddresses(id: any, params: any) {
  return apiPUT(`/saas/billing_addresses/${id}`, params)
}
// 混合办公人员列表
export async function getEmployeeWorkStatuses(params: any, isCsv = false) {
  if (isCsv) {
    return apiGET(`/employee_work_statuses.csv`, params)
  }
  return apiGET(`/employee_work_statuses`, params)
}
// 首页统计数据
export async function getEmployeeWorkStatusesData(params: any) {
  return apiGET(`/employee_work_statuses/employee_work_statuses_data`, params)
}
// employee_work_statuses/signin
export async function employeeWorkStatusesSignin(params: any) {
  return apiPOST(`/employee_work_statuses/signin`, params)
}
// 签退
export async function employeeWorkStatusesSignout(params: any) {
  return apiPOST(`/employee_work_statuses/signout`, params)
}
// /managements/v1/visitors/law_agreements 获取访客法律协议
export async function getLawAgreements(params: any) {
  return apiGET(`/visitors/law_agreements`, params)
}
// /managements/v1/visitors/law_agreements/{id} 删除访客法律协议
export async function deleteLawAgreements(id: any) {
  return apiDELETE(`/visitors/law_agreements/${id}`)
}
// /managements/v1/visitors/law_agreements 创建访客法律协议
export async function createLawAgreements(params: any) {
  return apiPOST(`/visitors/law_agreements`, params)
}
// /managements/v1/visitors/law_agreements/{id} 修改访客法律协议
export async function putLawAgreements(id: any, params: any) {
  return apiPUT(`/visitors/law_agreements/${id}`, params)
}

// /managements/v1/employee_work_statuses/signin 签到
export async function createSignin(params: any) {
  return apiPOST(`/employee_work_statuses/signin`, params)
}

// /managements/v1/employee_work_statuses/signout 签退
export async function createSignout(params: any) {
  return apiPOST(`/employee_work_statuses/signout`, params)
}

// /managements/v1/locations/{location_id}/third_meeting_rooms 获取第三方会议室
export async function getThirdMeetingRooms(location_id: any, params: any) {
  return apiGET(`/locations/${location_id}/third_meeting_rooms`, params)
}

// /managements/v1/locations/{location_id}/sync_meeting_rooms 同步第三方会议室
export async function postSyncMeetingRooms(location_id: any, params: any) {
  return apiPOST(`/locations/${location_id}/sync_meeting_rooms`, params)
}

// /managements/v1/integration_apps 集成服务列表
export async function getIntegrationApps(params: any) {
  return apiGET(`/integration_apps`, params)
}

// /managements/v1/integration_apps/{id} 集成服务详情
export async function getIntegrationApp(id: any, params: any) {
  return apiGET(`/integration_apps/${id}`, params)
}

// /managements/v1/integration_apps/{id}/remove 集成服务从空间移除
export async function postIntegrationAppsRemove(id: any, params: any) {
  return apiPOST(`/integration_apps/${id}/remove`, params)
}

// GET /managements/v1/locations/galleryful_percent 空间容纳人数
export async function getGalleryfulPercent(params: any) {
  return apiGET(`/locations/galleryful_percent`, params)
}

// GET /managements/v1/employee_work_statuses/state_composes 混合办公比例
export async function getStateComposes(params: any) {
  return apiGET(`/employee_work_statuses/state_composes`, params)
}

// GET /managements/v1/employee_work_statuses/mixture_office_percent 按分店混合办公比例
export async function getMixtureOfficePercent(params: any) {
  return apiGET(`/employee_work_statuses/mixture_office_percent`, params)
}

// GET /managements/v1/employee_work_statuses/work_office_percent_by_day 按天统计到公司办公人数比例
export async function getWorkOfficePercentByDay(params: any) {
  return apiGET(`/employee_work_statuses/work_office_percent_by_day`, params)
}

// GET /managements/v1/employee_work_statuses/work_office_percent_by_week 按周统计到公司办公人数比例
export async function getWorkOfficePercentByWeek(params: any) {
  return apiGET(`/employee_work_statuses/work_office_percent_by_week`, params)
}

// GET /managements/v1/employee_work_statuses/department_office_distribution 按部门混合办公比例分布
export async function getDepartmentOfficePercent(params: any) {
  return apiGET(
    `/employee_work_statuses/department_office_distribution`,
    params
  )
}

// // 会议室详情导出
// export async function exportMeetingRoomDetailWithOccupancy(meetingId, params, token) {
//   return apiGET(`/meeting_rooms/${meetingId}/detail_with_occupancy?start_at=${params.start_at}&end_at=${params.end_at}&user_token=${token}`)
// }

// 重置员工密码 /managements/v1
export async function resetPasswordMember(id: any) {
  return apiPOST(`/members/${id}/reset_password`)
}

// /managements/v1/users/unlock_email
export async function postUnlocks(params: any) {
  return apiPOST(`/users/unlock_email`, params)
}
// /managements/v1/statistics/visitors/sign_in_by_date 按weekday日期统计*
export async function getVisitorByDateOfWeek(params: any) {
  return apiGET(`/statistics/visitors/sign_in_by_date`, params)
}

/*
  自定义动态字段
*/
export const getDynamicAttributes = (params: any) =>
  apiGET(`/dynamic_attributes`, params)

export const createDynamicAttribute = (params: any) =>
  apiPOST(`/dynamic_attributes`, params)

export const updateDynamicAttribute = (params: any) =>
  apiPUT(`/dynamic_attributes/${params.id}`, params)

export const deleteDynamicAttribute = (id: any) =>
  apiDELETE(`/dynamic_attributes/${id}`)

// media_libraries
export async function getMedialibrary(params: any) {
  return apiGET(`/media_libraries`, params)
}
// media_libraries
export async function deleteMedialibrary(id: any) {
  return apiDELETE(`/media_libraries/${id}`)
}
// media_libraries
export async function createMedialibrary(params: any) {
  return apiPOST(`/media_libraries`, params)
}
// media_libraries 详情
export async function getMedialibraryOne(id: any, params: any) {
  return apiGET(`/media_libraries/${id}`, params)
}
// managements/v1/media_libraries/batch_destroy 详情
export async function batchDeleteMedialibrary(params: any) {
  return apiDELETE(`/media_libraries/batch_destroy`, params)
}

// /managements/v1/floor_desk_datum/search_by_date //工位楼层使用情况
export async function getSearchByDate(params: any) {
  return apiGET(`/floor_desk_datum/search_by_date`, params)
}

// /managements/v1/statistics/meeting_rooms/by_floor //会议按楼层展示每天使用率
export async function getMeetingRoomsByFloor(params: any) {
  return apiGET(`/statistics/meeting_rooms/by_floor`, params)
}
// /managements/v1/employee_work_rules 混合办公政策列表
export async function getEmployeeWorkRules(params: any) {
  return apiGET(`/employee_work_rules`, params)
}
// 混合办公政策
export async function deleteEmployeeWorkRules(id: any) {
  return apiDELETE(`/employee_work_rules/${id}`)
}
// 编辑混合办公政策
export async function updateEmployeeWorkRules(id: any, params: any) {
  return apiPUT(`/employee_work_rules/${id}`, params)
}

// 创建混合办公政策
export async function createEmployeeWorkRules(params: any) {
  return apiPOST(`/employee_work_rules`, params)
}

// /managements/v1/statistics/sensor_data/group_by_area 按区域类型统计电表数据
export async function getGroupByArea(params: any) {
  return apiGET(`/statistics/sensor_data/group_by_area`, params)
}

// 电量统计
export async function getSpaceStats(params: any) {
  return apiGET(`/statistics/sensor_data/group_by_location_stats`, params)
}

// /managements/v1/statistics/sensor_data/group_by_area_stats 按区域用电统计(上月对比)
export async function getGroupByAreaStats(params: any) {
  return apiGET(`/statistics/sensor_data/group_by_area_stats`, params)
}

// /managements/v1/statistics/sensor_data/group_by_year 本年/上年用电量统计
export async function getGroupByYear(params: any) {
  return apiGET(`/statistics/sensor_data/group_by_year`, params)
}

// /managements/v1/statistics/sensor_data/group_by_month 本月/上月用电量统计
export async function getGroupByMonth(params: any) {
  return apiGET(`/statistics/sensor_data/group_by_month`, params)
}

// /managements/v1/statistics/sensor_data/group_by_week 本周/上周用电量统计
export async function getGroupByWeek(params: any) {
  return apiGET(`/statistics/sensor_data/group_by_week`, params)
}

// /managements/v1/statistics/sensor_data/group_by_day 今日/昨天用电量统计
export async function getGroupByDay(params: any) {
  return apiGET(`/statistics/sensor_data/group_by_day`, params)
}
// 创建混合办公政策
export async function PostAreasBatchUpdateDeskGroups(params: any) {
  return apiPOST(`/areas/batch_update_desk_groups`, params)
}
// not_open_dates
export async function GetNotOpenDates(id: any, params: any) {
  return apiGET(`/locations/${id}/not_open_dates`, params)
}
// clone_desk_group
export async function postCloneDeskGroup(
  id: any,
  deskRuleId: any,
  params: any
) {
  return apiPOST(
    `/locations/${id}/desk_groups/${deskRuleId}/clone_desk_group`,
    params
  )
}

// get /managements/v1/locations/分店id/open_state 分店开放状态
export async function getOpenState(id: any, params: any) {
  return apiGET(`/locations/${id}/open_state`, params)
}
//
export async function deskReservationsRandom(params: any) {
  return apiPOST(`/desk_reservations/random_assign`, params)
}

// 操作记录列表/managements/v1/reservation_sync_records
export async function getReservationSyncRecords(params: any, format = 'json') {
  return apiGET(`/reservation_sync_records`, params)
}
// 集成服务同步员工
export async function syncThirdParty(params: any) {
  return apiPUT(`/members/sync_third_party`, params)
}

// 订阅新/managements/v1/pay/plans
export async function getPayPlans(params: any) {
  return apiGET(`/pay/plans`, params)
}

// 空间当前有效的订阅 managements/v1/pay/subscriptions
export async function getPaySubscriptions(params: any) {
  return apiGET(`/pay/subscriptions`, params)
}
//  managements/v1/pay/subscriptions
export async function paySubscriptions(params: any) {
  return apiPOST(`/pay/subscriptions`, params)
}

// 创建订阅
export async function postPaySubscriptions(params: any) {
  return apiPOST(`/pay/subscriptions`, params)
}

// 修改（追加）订阅数量 managements/v1/pay/subscriptions/{id}
export async function putPaySubscriptions(id: any, params: any) {
  return apiPUT(`/pay/subscriptions/${id}`, params)
}

// 取消自动订阅 /managements/v1/subscriptions/{id}
export async function deleteSubscriptions(id: any, params: any) {
  return apiDELETE(`/pay/subscriptions/${id}`, params)
}

// 恢复订阅 /managements/v1/subscriptions/{id}/resume
export async function putSubscriptionsResume(id: any, params: any) {
  return apiPUT(`/pay/subscriptions/${id}/resume`, params)
}

// 订阅账单 /managements/v1/pay/invoices
export async function getPayInvoices(params: any) {
  return apiGET(`/pay/invoices`, params)
}

// 计算订阅价格 /managements/v1/pay/subscriptions/calculate
export async function paySubscriptionsCalculate(params: any) {
  return apiPOST(`/pay/subscriptions/calculate`, params)
}
// 设置支付卡需要调用获取 /managements/v1/pay/payment_method/client_secret
export async function postPayPaymentMethod(params: any) {
  return apiPOST(`pay/payment_method`, params)
}

// 导入智能设备 /managements/v1/sensors/import
export async function sensorsImport(formData: any) {
  return apiFileUpload('/sensors/import', formData, 'post')
}

// 智能联动 /managements/v1/assetable_automations
export async function getAssetableAutomations(params: any) {
  return apiGET(`/assetable_automations`, params)
}

// 创建智能联动  /managements/v1/assetable_automations
export async function postAssetableAutomations(params: any) {
  return apiPOST(`/assetable_automations`, params)
}

// 智能联动执行记录 /managements/v1/automation_records
export async function getAutomationRecords(params: any) {
  return apiGET(`/automation_records`, params)
}

// 删除
export async function delAssetableAutomations(id: any, params: any) {
  return apiDELETE(`/assetable_automations/${id}`, params)
}

// 更新智能联动/managements/v1/assetable_automations
export async function putAssetableAutomations(id: any, params: any) {
  return apiPUT(`/assetable_automations/${id}`, params)
}

// 设备统计仪表盘
export async function getDeviceDashBoard(params: any) {
  return apiGET(`/statistics/smart_devices/dashboard`, params)
}

// 设备统计仪表盘
export async function pwrtotalValueStatistic(params: any) {
  return apiGET(`statistics/sensor_data/pwr_total_value_statistic`, params)
}

// 设备统计仪表盘
export async function pwrValueByDailyStatistic(params: any) {
  return apiGET(`statistics/sensor_data/pwr_value_by_daily_statistic`, params)
}

// 用电量统计
export async function getElectricityConsumption(params: any) {
  return apiGET(`/statistics/smart_devices/electricity_consumption`, params)
}

// 环境指标设置
export async function getEnvIndicatorSettings(params: any) {
  return apiGET(`/statistics/smart_devices/env_indicator_settings`, params)
}

// 环境指标数据
export async function getEnvIndicators(params: any) {
  return apiGET(`/statistics/smart_devices/env_indicators`, params)
}

// 报警记录/managements/v1/smart_devices_alarm_records
export async function getSmartDevicesAlarmRecords(params: any) {
  return apiGET(`/smart_devices_alarm_records`, params)
}

// /managements/v1/scene_records
export async function getSceneRecords(params: any) {
  return apiGET(`/scene_records`, params)
}

// managements/v1/assetable_automations/batch_destroy
export async function deletemAssetableAutomations(params: any) {
  return apiDELETE(`/assetable_automations/batch_destroy`, params)
}

// 访客数据分析/managements/v1/statistics/visitors/dashboard
export async function getVisitorsDashBoard(params: any) {
  return apiGET(`/statistics/visitors/dashboard`, params)
}
// 混合办公分析
export async function getEmployeeWorkDashBoard(params: any) {
  return apiGET(`/statistics/employee_work_statuses/dashboard`, params)
}
// 各分店访客数据 /managements/v1/statistics/visitors/visitor_count_group_by_location
export async function visitorsCountGroupByLocation(params: any) {
  return apiGET(`/statistics/visitors/visitor_count_group_by_location`, params)
}

// 各部门到公司办公人数的百分比 /managements/v1/statistics/visitors/visitor_count_group_by_location
export async function workOfficeRateByDepartment(params: any) {
  return apiGET(
    `/statistics/employee_work_statuses/work_office_rate_by_department`,
    params
  )
}

// 各部门远程办公人数的百分比 /managements/v1/statistics/visitors/visitor_count_group_by_location
export async function workHomeRateByDepartment(params: any) {
  return apiGET(
    `/statistics/employee_work_statuses/work_home_rate_by_department`,
    params
  )
}

// 取消率
export async function meetingCancellationRateByDay(params: any) {
  return apiGET(`/statistics/meeting_rooms/cancellation_rate_by_day`, params)
}

// /managements/v1/areas/batch_update 批量修改分区*
export async function areasBatchUpdate(data: any) {
  return apiPUT(`/areas/batch_update `, data)
}

// /managements/v1/areas/batch_destroy
export async function areasBatchDestroy(params: any) {
  return apiDELETE(`/areas/batch_destroy`, params)
}
