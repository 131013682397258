import { Link } from 'react-router-dom'
import { tr } from '@/utils/utils'
import Exception from '@/components/Exception'
import Error from '@/assets/error.png'
import { Button } from 'antd'

/**
 * A fallback component for ErrorBoundary
 * @returns
 */
function ErrorFallback({
  error,
  resetError,
}: {
  error: any
  resetError: () => void
}) {
  return (
    <Exception
      title="Error"
      img={Error}
      linkElement={Link}
      desc={`${tr('app.exception.description.error')}`}
      backText={tr('app.exception.back')}
      actions={
        <Button
          type="primary"
          onClick={() => {
            resetError()
            window.location.assign('/')
          }}
        >
          {tr('app.pwa.serviceworker.updated.ok')}
        </Button>
      }
    />
  )
}

export default ErrorFallback
