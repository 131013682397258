import React, { createContext, useState, useEffect } from 'react'
import intl from 'react-intl-universal'
import { useLocation, useNavigate } from 'react-router-dom'
import { SUPPORTED_LOCALES } from '@/utils/constants/supportedLocales'

import dayjs from 'dayjs'

import PageLoading from '@/components/PageLoading'
import { app, authentication } from '@microsoft/teams-js'

import { toggleTheme } from '@zougt/vite-plugin-theme-preprocessor/dist/browser-utils'
import { Spin } from 'antd'

// MUST Load all locales needed manually

const ThemeContext = createContext({})

function getLangValue() {
  const defaultTheme =
    sessionStorage.getItem('resetTheme') || localStorage.getItem('theme')
  const [isTheme, setIsTheme] = useState(defaultTheme || 'theme-default')
  const [isDark, setIsDark] = useState(defaultTheme === 'theme-dark')

  return {
    isTheme,
    setIsTheme,
    isDark,
    setIsDark,
  }
}

export function ThemeProvider({ children }: any) {
  const value = getLangValue()
  return <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
}

export function useThemeContext() {
  const context = React.useContext(ThemeContext)
  if (context === undefined) {
    throw new Error('useThemeContext must be used within a ThemeProvider')
  }
  return context
}
