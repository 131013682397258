import Exception403 from '../pages/Exception/403'

import Exception404 from '../pages/Exception/404'

import Exception500 from '../pages/Exception/500'

const routes = {
  path: '/exception',
  context: 'exception',
  // element: <SpaceLayout />,
  permission: 'all',
  children: [
    {
      path: '403',
      name: 'not-permission',
      permission: 'all',

      element: <Exception403 />,
    },
    {
      path: '404',
      name: 'not-find',
      permission: 'all',

      element: <Exception404 />,
    },
    {
      path: '500',
      name: 'server-error',

      element: <Exception500 />,
    },
  ],
}

export default routes
