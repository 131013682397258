import { Modal, notification } from 'antd'

import axios, { isCancel } from 'axios'
import * as Sentry from '@sentry/react'
import { getCurrentLocale, tr, reloadWithTimestamp } from '@/utils/utils'
// request.js

import { API_BASE_URL } from '@/utils/constants/apiBaseUrls'
import { axiosToCurl } from './convert_to_curl'

/**
 * 一、功能：
 * 1. 统一拦截http错误请求码；
 * 2. 统一拦截业务错误代码；
 * 3. 统一设置请求前缀
 * |-- 每个 http 加前缀 baseURL = /api/v1，从配置文件中获取 apiPrefix
 * 4. 配置异步请求过渡状态：显示蓝色加载条表示正在请求中，避免给用户页面假死的不好体验。
 * |-- 使用 NProgress 工具库。
 */
// 设置全局参数，如响应超市时间，请求前缀等。
axios.defaults.timeout = 45000
axios.defaults.baseURL = API_BASE_URL
axios.defaults.headers.common['X-branding'] = `${import.meta.env.VITE_BRANDING_NAME
  }`
// axios.defaults.headers.common['Accept-Language'] = `zh-CN,zh;q=0.9`;
// axios.defaults.headers.common['Accept-Language'] = `en,zh;q=0.9`;
axios.defaults.withCredentials = false

export function configApiToken(token: any) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
}

const checkStatus = (response: any) => {
  // 401
  if (response.status === 401) {
    if (!window.showLoginConfirm) {
      window.showLoginConfirm = true
      Modal.warning({
        title: tr('app.exception.401'),
        okText: tr('app.login'),
        onOk() {
          const inTeams = sessionStorage.getItem('inTeams') === 'true'
          if (inTeams) {
            sessionStorage.setItem('from', 'teams')
            localStorage.clear()
            reloadWithTimestamp()
            return
          }
          localStorage.clear()

          window.showLoginConfirm = false
          window.location.href = '/login'
        },
      })
    }
    return tr('app.exception.401')
  }

  // hideError 不显示错误信息. 查看`./api/teamsLogin` for usage
  if (response.config.hideError) {
    return
  }

  // other error
  let errorMessage
  try {
    const errorObj = response.data
    errorMessage =
      errorObj?._error?.message ||
      errorObj?.message ||
      errorObj?.error ||
      errorObj?.errors
  } catch (e) { }
  // TODO: hackhack! remove this later
  // const notShowError = ["/statistics/sensor_data/air_realtime"];
  // if (
  //   get(response, "import.meta.env.VITE_API_BASE_URL", []).includes(
  //     notShowError
  //   )
  // ) {
  //   return;
  // }
  // show error message
  notification.error({
    message: errorMessage || tr('app.exception.description.error'),
    // description: `${codeMessage[response.status]}`,
    // className: "ant-notification-notice-error",
  })

  // 如果return Error或者response，都会触发dva onError, 导致无法在component中catch或finally
  // 所以这里返回string
  return errorMessage
}

function paginate(paginationText: any) {
  if (paginationText) {
    const pairs = paginationText.split(',')
    const result = {}
    pairs.forEach((pair: any) => {
      let [key, val] = pair.split('=')
      key = key.trim()
      val = parseInt(val, 10)
      // convert pagination to antd format
      if (key === 'current_page') {
        result.current = val
      } else if (key === 'limit_value') {
        result.pageSize = val
      } else if (key === 'total_count') {
        result.total = val
      }

      // TODO: keep this for backward compatibility. Remove after changing all table

      result[key] = val
    })

    return result
  }
  return {}
}

axios.interceptors.request.use(
  (config) => {
    const currentLocaleData = getCurrentLocale()
    // 获取空间
    // 获取分店语言
    // const location_language = (localStorage.getItem('location_language') === 'en-US' || localStorage.getItem('location_language') === "en") ? 'en-US' : 'zh-CN'

    config.headers.common['Accept-Language'] =
      `${currentLocaleData.value},zh;q=0.9`

    config.headers.common['X-client'] = 'web'

    config.headers.common['X-location'] = window._x_location || ''

    config.headers.common['X-space'] = window._x_space || ''
    // sentry中添加header信息
    // console.log('headers', config.headers);
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

export default function request(opt: any) {
  // 调用 axios api，统一拦截, do not reject 因为不是总catch
  // eslint-disable-next-line no-unused-vars
  return new Promise((resolve, reject) => {
    const r = axios(opt)
      .then((response) => {
        // >>>>>>>>>>>>>> 请求成功 <<<<<<<<<<<<<<
        // paginate
        const pagination = paginate(response.headers['x-pagination'])
        resolve({
          response: response.data,
          pagination,
        })
      })
      .catch((error) => {
        // >>>>>>>>>>>>>> 请求失败 <<<<<<<<<<<<<<

        if (import.meta.env.VITE_PRODUCTION != 'true') {
          console.debug(
            `【${opt.method} ${opt.url}】请求失败，响应数据：%o`,
            error.response
          )
        }

        // 取消请求不展示报错信息
        if (isCancel(error)) {
          return
        }

        if (error.response?.status > 0) {
          // 存在请求，但是服务器的返回一个状态码 > 300
          // if (import.meta.env.VITE_PRODUCTION) {
          //   checkStatus(error.response)
          //   return
          // }
          // return reject(checkStatus(error.response));
          // if (error.response.status === 500) {
          //   reject(checkStatus(error.response));
          // } else {
          checkStatus(error.response)
          reject(
            new Error({
              ...error,
              message: `API STATUS ${error.response.status}`,
            })
          )
        } else {
          // 一些错误是在设置请求时触发的
          // 一些不显示错误的接口
          notification.error({
            message: tr('app.exception.description.error'),
            description: error.message,
          })

          const curl = axiosToCurl(error)
          if (process.env.NODE_ENV !== 'production') {
            console.log(curl)
          }
          Sentry.captureException(
            `Unknown API Error: ${error.message}: ${curl}`
          )
          reject(
            new Error({
              ...error,
              message: `Axios Error: ${error.message}`,
            })
          )
        }
      })
    return r
  })
}
