import React, { createElement } from 'react'
import classNames from 'classnames'
import { Button } from 'antd'

import config from './typeConfig'

import styles from './index.module.less'

import { getLocalStorage } from '@/utils/localStorage'

import { tr } from '@/utils/utils'

function Exception(props: any) {
  const {
    className,
    linkElement = 'a',
    type,
    title,
    desc,
    img,
    actions,
    ...rest
  } = props
  const backText = props.backText || tr('app.exception.back')

  const pageType = type in config ? type : '404'
  const clsString = classNames(styles.exception, className)
  return (
    <div className={clsString} {...rest}>
      <div
        className={styles.imgEle}
        style={{ backgroundImage: `url(${img || config[pageType].img})` }}
      />

      <div className={styles.content}>
        <h1>{title || config[pageType].title}</h1>

        <div className={styles.desc}>{desc || config[pageType].desc}</div>

        <div className={styles.actions}>
          {actions ||
            createElement(
              linkElement,
              {
                to: '/',
                href: '/',
              },

              <Button type="primary">{backText}</Button>
            )}
        </div>
      </div>
    </div>
  )
}

export default Exception
