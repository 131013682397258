export function axiosToCurl(res: any) {
  const reqConf = res.config

  let curlStr = 'curl'
  const slash = '\\'
  const flags = {
    request: '--request',
    url: '--url',
    header: '--header',
    data: '--data',
  }

  const addMethod = () => {
    curlStr += ` ${flags.request} ${reqConf.method?.toUpperCase()} ${slash}\n`
    return curlStr
  }

  const addUrl = () => {
    let query = ''
    if (reqConf.params) {
      Object.keys(reqConf.params).forEach((key, index) => {
        query +=
          index === 0
            ? `?${key}=${encodeURIComponent(reqConf.params[key])}`
            : `&${key}=${encodeURIComponent(reqConf.params[key])}`
      })
    }
    curlStr += ` ${flags.url} ${reqConf.baseURL}${reqConf.url}${query} ${slash}\n`
    return curlStr
  }

  const addHeaders = () => {
    for (const key of Object.keys(reqConf.headers)) {
      curlStr += ` ${flags.header} '${key}: ${reqConf.headers[key]}' ${slash}\n`
    }
    return curlStr
  }

  const addData = () => {
    if (reqConf.data) {
      curlStr += ` ${flags.data} '${JSON.stringify(reqConf.data)}' ${slash}\n`
    }

    curlStr += ' '
    return curlStr
  }

  const logCurl = () => {
    console.log(`=============== CURL for ${reqConf.url} ===============`)
    console.log()
    console.log(curlStr)
    console.log()
    return curlStr
  }

  const pipe =
    (...fns: any[]) =>
      (x: any) =>
        fns.reduce((v, f) => f(v), x)

  pipe(addMethod, addUrl, addHeaders, addData)(curlStr)
  return curlStr
}

// Usage
// const http = axios.create({
//     baseURL: '',
//     headers: {},
//   });
// http.interceptors.request.use(axiosToCurl);
