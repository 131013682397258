import reserveActive from '@/assets/reserveActive.png'

import reserveCancelled from '@/assets/reserveCancelled.png'

import reserveCheckin from '@/assets/reserveCheckin.png'

import assigned_to_department from '@/assets/assigned_to_department.png'

import { GRAY, GREEN, ORANGE, RED, BLUE } from '@/constants/colors'

import {
  STATE_BLUE,
  STATE_GREEN,
  STATE_ORANGE,
  STATE_GRAY,
  STATE_ERROR,
  STATE_DEFAULT,
  STATE_SUCCESS,
} from '@/constants/stateColors'
export const AI_DEVICE_STATUS = {
  online: 'devices.statuses.online',
  offline: 'devices.statuses.offline',
  disable: 'visitor.unknowns',
  expired: 'visitor.unknowns',
  never_reported: 'visitor.unknowns',
  disabled: 'visitor.unknowns',
  unknown: 'visitor.unknowns',
}

export const RESERVATION_STATUS = [
  {
    id: 'active',
    title: 'reservationInfo.active',
    text: 'reservationInfo.active',
    color: '24,144,255',
    img: reserveActive,
    bgColor: 'rgba(144, 97, 249, 0.15)',
    icon: 'icon-yes_fill',
    iconColor: '#9061F9',
    // type: "success"
  },
  {
    id: 'checked_in',
    title: 'reservationInfo.checked_in',
    text: 'reservationInfo.checked_in',
    color: '24,144,255',
    bgColor: 'rgba(14, 159, 110, 0.15)',
    img: reserveActive,
    iconColor: '#0E9F6E',
    // type: "success",
    icon: 'icon-yes_fill',
  },
  {
    id: 'ended',
    title: 'reservationInfo.ended',
    text: 'reservationInfo.ended',
    color: '113,115,119',
    img: reserveCancelled,
    bgColor: 'rgba(84, 104, 128, 0.15)',
    iconColor: '#546880',
    icon: 'icon-yes_fill',
    // type: "info"
  },
  {
    id: 'reserved',
    title: 'reservationInfo.upcoming',
    text: 'reservationInfo.upcoming',
    color: '243,114,74',
    img: reserveActive,
    bgColor: 'rgba(144, 97, 249, 0.15)',
    icon: 'icon-yes_fill',
    iconColor: '#9061F9',
    // type: "warning"
  },
  {
    id: 'upcoming',
    title: 'reservationInfo.upcoming',
    text: 'reservationInfo.upcoming',
    color: '243,114,74',
    img: reserveActive,
    bgColor: 'rgba(144, 97, 249, 0.15)',
    icon: 'icon-yes_fill',
    iconColor: '#9061F9',
    // type: "warning"
  },
  {
    id: 'ongoing',
    title: 'reservationInfo.ongoing',
    text: 'reservationInfo.ongoing',
    color: '0,166,153',
    img: reserveCheckin,
    icon: 'icon-time_fill',
    bgColor: 'rgba(6, 148, 162, 0.15)',
    iconColor: '#0694A2',
    // type: "info"
  },
  {
    id: 'cancelled',
    title: 'reservationInfo.cancelled',
    text: 'reservationInfo.cancelled',
    color: '113,115,119',
    img: reserveCancelled,
    bgColor: 'rgba(84, 104, 128, 0.15)',
    iconColor: '#546880',
    icon: 'icon-error_fill',
    // type: "error"
  },
  {
    id: 'terminated',
    title: 'reservationInfo.terminated',
    text: 'reservationInfo.terminated',
    color: '113,115,119',
    img: reserveCancelled,
    icon: 'icon-yes_fill',
    bgColor: 'rgba(84, 104, 128, 0.15)',
    iconColor: '#546880',
    // type: "error"
  },
  {
    id: 'starting_soon',
    title: 'reservationInfo.starting_soon',
    text: 'reservationInfo.starting_soon',
    color: '243,114,74',
    img: reserveActive,
    icon: 'icon-time_fill',
    bgColor: 'rgba(63, 131, 248, 0.15)',
    iconColor: '#3F83F8',
    // type: "warning"
  },
  {
    id: 'ending_soon',
    title: 'reservationInfo.ending_soon',
    text: 'reservationInfo.ending_soon',
    color: '243,114,74',
    img: reserveActive,
    bgColor: 'rgba(255, 138, 76, 0.15)',
    iconColor: '#FF8A4C',
    icon: 'icon-sigh_fill',
    // type: "warning"
  },
  {
    id: 'approving',
    title: 'activity.statuses.approving',
    text: 'activity.statuses.approving',
    color: '243,114,74',
    bgColor: 'rgba(255, 138, 76, 0.15)',
    img: reserveActive,
    icon: 'icon-wait_for_fill',
    iconColor: '#FF8A4C',
    // type: "warning"
  },
]

export const DESK_RECORD_STATE = [
  {
    id: 'approving',
    title: 'activity.statuses.approving',
    text: 'activity.statuses.approving',
    color: STATE_ORANGE,
    img: reserveActive,
    type: 'success',
  },
  {
    id: 'active',
    title: 'deskReservation.havedReservation',
    text: 'deskReservation.havedReservation',
    color: STATE_ORANGE,
    img: reserveActive,
    type: 'success',
  },
  {
    id: 'assigned',
    title: 'newAreaManage.departmentAllocated',
    text: 'newAreaManage.departmentAllocated',
    color: STATE_ORANGE,
    img: reserveActive,
    type: 'success',
  },
  {
    id: 'cancelled',
    title: 'deskHistory.cancelled',
    text: 'deskHistory.cancelled',
    color: STATE_GRAY,
    img: reserveCancelled,
    type: 'grey',
  },
  {
    id: 'completed',
    title: 'deskHistory.completed',
    text: 'deskHistory.completed',
    color: STATE_GRAY,
    img: reserveCancelled,
    type: 'info',
  },
  {
    id: 'checkedin',
    title: 'deskHistory.checkedin',
    text: 'deskHistory.checkedin',
    color: STATE_GREEN,
    img: reserveCheckin,
    type: 'success',
  },
  {
    id: 'checked_in',
    title: 'deskHistory.checked_in',
    text: 'deskHistory.checked_in',
    color: STATE_GREEN,
    img: reserveCheckin,
    type: 'success',
  },
  {
    id: 'starting_soon',
    title: 'deskHistory.starting_soon',
    text: 'deskHistory.starting_soon',
    color: STATE_GREEN,
    img: reserveCheckin,
    type: 'success',
  },
  {
    id: 'upcoming',
    title: 'deskHistory.upcoming',
    text: 'deskHistory.upcoming',
    color: STATE_GREEN,
    img: reserveCheckin,
    type: 'success',
  },
  {
    id: 'terminated',
    title: 'deskHistory.terminated',
    text: 'deskHistory.terminated',
    color: STATE_GRAY,
    img: reserveCancelled,
    type: 'grey',
  },
  {
    id: 'ongoing',
    title: 'deskHistory.ongoing',
    text: 'deskHistory.ongoing',
    color: STATE_GREEN,
    img: reserveCheckin,
    type: 'success',
  },
  {
    id: 'assigned_to_department',
    title: 'desks.states.assigned_to_department',
    text: 'desks.states.assigned_to_department',
    color: STATE_BLUE,
    img: assigned_to_department,
    type: 'success',
  },
  {
    id: 'free',
    title: 'deskReservation.enableReservation',
    text: 'deskReservation.enableReservation',
    color: STATE_GREEN,
    img: reserveCheckin,
    type: 'success',
  },
  {
    id: 'ending_soon',
    title: 'desks.states.ending_soon',
    text: 'desks.states.ending_soon',
    color: STATE_GREEN,
    img: reserveCheckin,
    type: 'success',
  },
  {
    id: 'disabled',
    title: 'controlRepair_disable',
    text: 'controlRepair_disable',
    color: STATE_GRAY,
    img: reserveCheckin,
    type: 'success',
  },
  {
    id: 'freefixed',
    title: 'desks.states.freefixed',
    text: 'desks.states.freefixed',
    color: STATE_GREEN,
    img: reserveCheckin,
    type: 'success',
  },
  {
    id: 'assignedfixed',
    title: 'meetings.states.assigned',
    text: 'meetings.states.assigned',
    color: STATE_ORANGE,
    img: reserveActive,
    type: 'success',
  },
]

// 会议重复 数值
export const REPEAT_MODES = [
  {
    id: 'no',
    key: 'no',
    name: 'recurrence.modes.no',
  },
  { id: 'workDay', key: 'workDay', name: 'recurrence.modes.workDay' },
  { id: 'day', key: 'day', name: 'recurrence.modes.day' },
  { id: 'week', key: 'week', name: 'recurrence.modes.week' },
  { id: 'twoweek', key: 'twoweek', name: 'recurrence.modes.twoweek' },
  { id: 'month', name: 'recurrence.modes.month' },
]

export const BIZ_INIT_TIMES_OF_WEEK = {
  mon: { '09:00': '18:00' },
  tue: { '09:00': '18:00' },
  wed: { '09:00': '18:00' },
  thu: { '09:00': '18:00' },
  fri: { '09:00': '18:00' },
  sat: { '09:00': '18:00' },
  sun: { '09:00': '18:00' },
}

export const WEEK_DAYS = [
  {
    day: 'mon',
    begin: '09:00',
    end: '18:00',
    working: true,
    value: 1,
    firstDay: true,
  },
  {
    day: 'tue',
    begin: '09:00',
    end: '18:00',
    working: true,
    value: 2,
    firstDay: false,
  },
  {
    day: 'wed',
    begin: '09:00',
    end: '18:00',
    working: true,
    value: 3,
    firstDay: false,
  },
  {
    day: 'thu',
    begin: '09:00',
    end: '18:00',
    working: true,
    value: 4,
    firstDay: false,
  },
  {
    day: 'fri',
    begin: '09:00',
    end: '18:00',
    working: true,
    value: 5,
    firstDay: false,
  },
  {
    day: 'sat',
    begin: '09:00',
    end: '18:00',
    working: false,
    value: 6,
    firstDay: false,
  },
  {
    day: 'sun',
    begin: '09:00',
    end: '18:00',
    working: false,
    value: 7,
    firstDay: true,
  },
]

export const TASK_DAYS = [
  {
    id: 'aDay',
    value: 1,
  },
  {
    id: 'threeDays',
    value: 3,
  },
  {
    id: 'aWeek',
    value: 7,
  },
  {
    id: 'twoWeeks',
    value: 14,
  },
  {
    id: 'oneMonth',
    value: 30,
  },
]

export const OFFICE_AREA_TYPES = [
  {
    id: 'public_office',
    name: 'area.type.openOfficeArea',
  },
  {
    id: 'private_office',
    name: 'area.type.closedOffice',
  },
  {
    id: 'flexible_office',
    name: 'area.type.flexibleOfficeArea',
  },
  {
    id: 'hotel_office',
    name: 'area.type.hotDeskArea',
  },
  {
    id: 'meeting_room',
    name: 'office.areaTypes.meeting_room',
  },
  // {
  //   id: "delivery_station",
  //   name: "area.type.delivery_station",
  // },
]

export const AREA_ALL_TYPES = [
  {
    id: 'public_office',
    name: 'office.areaTypes.publicOffice',
  },
  {
    id: 'private_office',
    name: 'office.areaTypes.privateOffice',
  },
  {
    id: 'break_room',
    name: 'area.typesWithout.restArea',
  },
  {
    id: 'meeting_room',
    name: 'area.typesWithout.meetingRoom',
  },
  {
    id: 'cafe',
    name: 'area.typesWithout.coffeeShop',
  },
  {
    id: 'gym',
    name: 'area.typesWithout.gym',
  },
  {
    id: 'other',
    name: 'area.typesWithout.otherArea',
  },
  {
    id: 'activity_room',
    name: 'area.typesWithout.activityRooms',
  },
  {
    id: 'broadcasting_studio',
    name: 'area.typesWithout.broadcastingStudio',
  },
  {
    id: 'photography_studio',
    name: 'area.typesWithout.photographyStudio',
  },
  {
    id: 'gaming_room',
    name: 'area.typesWithout.gameRoom',
  },
  {
    id: 'sleeping_room',
    name: 'area.typesWithout.sleepArea',
  },
  {
    id: 'bed',
    name: 'area.typesWithout.sleepingPod',
  },
  {
    id: 'flexible_office',
    name: 'office.areaTypes.hotDeskArea',
  },
  {
    id: 'big_meeting_room',
    name: 'area.typesWithout.conferenceRoom',
  },
  {
    id: 'interview_room',
    name: 'area.typesWithout.interviewRoom',
  },
  {
    id: 'call_room',
    name: 'area.typesWithout.phoneBooth',
  },
  {
    id: 'class_room',
    name: 'area.typesWithout.trainingRoom',
  },
  {
    id: 'conference_room',
    name: 'area.typesWithout.bigConferenceRoom',
  },
  {
    id: 'event_room',
    name: 'area.typesWithout.activityRoom',
  },
  {
    id: 'project_room',
    name: 'area.typesWithout.projectRoom',
  },
  {
    id: 'study_room',
    name: 'area.typesWithout.studyRoom',
  },
  {
    id: 'huddle_room',
    name: 'area.typesWithout.consultingRoom',
  },

  {
    id: 'kitchen',
    name: 'area.typesWithout.kitchen',
  },
  {
    id: 'lab',
    name: 'area.typesWithout.labRoom',
  },
  {
    id: 'lactation_room',
    name: 'area.typesWithout.maternityRoom',
  },
  {
    id: 'listening',
    name: 'area.typesWithout.audioRoom',
  },
  {
    id: 'lobby',
    name: 'area.typesWithout.lobby',
  },
  {
    id: 'lounge',
    name: 'area.typesWithout.receptionArea',
  },
  {
    id: 'parking',
    name: 'area.typesWithout.parkingArea',
  },
  {
    id: 'restroom',
    name: 'area.typesWithout.washRoom',
  },
  {
    id: 'female_restroom',
    name: 'area.typesWithout.femaleRoom',
  },
  {
    id: 'male_restroom',
    name: 'area.typesWithout.maleRoom',
  },
  {
    id: 'studio',
    name: 'area.typesWithout.workRoom',
  },
  {
    id: 'theater',
    name: 'area.typesWithout.theater',
  },
  {
    id: 'utility_room',
    name: 'area.typesWithout.toolRoom',
  },
  {
    id: 'closet',
    name: 'area.typesWithout.storageRoom',
  },
  {
    id: 'work_area',
    name: 'area.typesWithout.workArea',
  },
  {
    id: 'hotel_office',
    name: 'office.areaTypes.preBookHotDeskArea',
  },
  {
    id: 'front_desk',
    name: 'visitor.approve.reception',
  },
  {
    id: 'corridor',
    name: 'publicArea.corridor',
  },
  {
    id: 'dining_room',
    name: 'publicArea.dining_room',
  },
  {
    id: 'recreation_room',
    name: 'publicArea.recreation_room',
  },
  {
    id: 'pit',
    name: 'area.type.pit',
  },
]
export const AREA_TYPE = {
  private_office: 'area.type.closedOffice',
  public_office: 'area.type.openOfficeArea',
  break_room: 'area.type.restArea',
  meeting_room: 'area.type.meetingRoom',
  cafe: 'area.type.coffeeShop',
  gym: 'area.type.gym',
  other: 'area.type.other',
  activity_room: 'area.type.activityArea',
  broadcasting_studio: 'area.type.studio',
  photography_studio: 'area.type.photoStudio',
  gaming_room: 'area.type.gameRoom',
  sleeping_room: 'area.type.sleepingArea',
  bed: 'area.type.sleepChamber',
  flexible_office: 'area.type.flexibleOfficeArea',
  big_meeting_room: 'area.type.largeConferenceHall',
  interview_room: 'area.type.interviewRoom',
  call_room: 'area.type.telephoneBooth',
  class_room: 'area.type.trainingRoom',
  conference_room: 'area.type.bigConferenceRoom',
  event_room: 'area.type.activityRoom',
  project_room: 'area.type.projectOffice',
  study_room: 'area.type.studyRoom',
  huddle_room: 'area.type.consultingRoom',
  kitchen: 'area.type.kitchen',
  lab: 'area.type.lab',
  lactation_room: 'area.type.maternityRoom',
  listening: 'area.type.audioRoom',
  lobby: 'area.type.lobby',
  lounge: 'area.type.receptionArea',
  parking: 'area.type.parkingArea',
  restroom: 'area.type.washRoom',
  female_restroom: 'area.type.femaleRoom',
  male_restroom: 'area.type.maleRoom',
  studio: 'area.type.workRoom',
  theater: 'area.type.theater',
  utility_room: 'area.type.toolRoom',
  closet: 'area.type.storageRoom',
  work_area: 'area.type.workArea',
  hotel_office: 'area.type.hotDeskArea',
  front_desk: 'area.type.reception',
  corridor: 'area.type.corridor',
  dining_room: 'area.type.cafe',
  recreation_room: 'area.type.entertainmentRoom',
  pit: 'area.type.pit',
  third_restroom: 'area.type.third_restroom',
  disable_restroom: 'area.type.disable_restroom',
  delivery_station: 'area.type.delivery_station',
  public_restroom: 'area.type.public_restroom',
}

export const AREA_TYPE_NEW = [
  { id: 'private_office', name: 'area.type.closedOffice' },
  { id: 'public_office', name: 'area.type.openOfficeArea' },
  { id: 'break_room', name: 'area.type.restArea' },
  { id: 'meeting_room', name: 'area.type.meetingRoom' },
  { id: 'cafe', name: 'area.type.coffeeShop' },
  { id: 'gym', name: 'area.type.gym' },
  { id: 'other', name: 'area.type.other' },
  { id: 'activity_room', name: 'area.type.activityArea' },
  { id: 'broadcasting_studio', name: 'area.type.studio' },
  { id: 'photography_studio', name: 'area.type.photoStudio' },
  { id: 'gaming_room', name: 'area.type.gameRoom' },
  { id: 'sleeping_room', name: 'area.type.sleepingArea' },
  { id: 'bed', name: 'area.type.sleepChamber' },
  { id: 'flexible_office', name: 'area.type.flexibleOfficeArea' },
  { id: 'big_meeting_room', name: 'area.type.largeConferenceHall' },
  { id: 'interview_room', name: 'area.type.interviewRoom' },
  { id: 'call_room', name: 'area.type.telephoneBooth' },
  { id: 'class_room', name: 'area.type.trainingRoom' },
  { id: 'conference_room', name: 'area.type.bigConferenceRoom' },
  { id: 'event_room', name: 'area.type.activityRoom' },
  { id: 'project_room', name: 'area.type.projectOffice' },
  { id: 'study_room', name: 'area.type.studyRoom' },
  { id: 'huddle_room', name: 'area.type.consultingRoom' },
  { id: 'kitchen', name: 'area.type.kitchen' },
  { id: 'lab', name: 'area.type.lab' },
  { id: 'lactation_room', name: 'area.type.maternityRoom' },
  { id: 'listening', name: 'area.type.audioRoom' },
  { id: 'lobby', name: 'area.type.lobby' },
  { id: 'lounge', name: 'area.type.receptionArea' },
  { id: 'parking', name: 'area.type.parkingArea' },
  { id: 'restroom', name: 'area.type.washRoom' },
  { id: 'female_restroom', name: 'area.type.femaleRoom' },
  { id: 'male_restroom', name: 'area.type.maleRoom' },
  { id: 'studio', name: 'area.type.workRoom' },
  { id: 'theater', name: 'area.type.theater' },
  { id: 'utility_room', name: 'area.type.toolRoom' },
  { id: 'closet', name: 'area.type.storageRoom' },
  { id: 'work_area', name: 'area.type.workArea' },
  { id: 'hotel_office', name: 'area.type.hotDeskArea' },
  { id: 'front_desk', name: 'area.type.reception' },
  { id: 'corridor', name: 'area.type.corridor' },
  { id: 'dining_room', name: 'area.type.cafe' },
  { id: 'recreation_room', name: 'area.type.entertainmentRoom' },
  { id: 'pit', name: 'area.type.pit' },
  { id: 'third_restroom', name: 'area.type.third_restroom' },
  { id: 'disable_restroom', name: 'area.type.disable_restroom' },
  { id: 'delivery_station', name: 'area.type.delivery_station' },
]

export const SYSTEM_SPACE_ROLES = [
  {
    id: 'super_admin',
    role: 0,
    name: 'member.roles.superAdmin',
    notes: 'member.roles.superAdmin',
  },
  {
    id: 'admin',
    role: 1,
    name: 'member.roles.spaceAdmin',
    notes: 'member.roles.spaceAdmin',
  },
  {
    id: 'billing',
    role: 15,
    name: 'member.roles.billing',
    notes: 'member.roles.billing',
  },
]

export const SYSTEM_LOCATION_ROLES = [
  {
    id: 'location_manager',
    role: 3,
    name: 'member.roles.locationManager',
    notes: 'member.roles.locationManager',
  },
  {
    id: 'location_operator',
    role: 4,
    name: 'member.roles.locationOperator',
    notes: 'member.roles.locationOperator',
  },
  {
    id: 'reception',
    role: 13,
    name: 'memberRoles.reception',
    notes: 'memberRoles.reception',
  },
  {
    id: 'engineer',
    role: 12,
    name: 'memberRoles.engineer',
    notes: 'memberRoles.engineer',
  },
  {
    id: 'department_principal',
    role: 14,
    name: 'member.roles.departmentPrincipal',
    notes: 'member.roles.departmentPrincipal',
  },
]

export const LOCK_SOURCE_TYPE = {
  Reservation: 'lock.sourceType.roomBooking',
  Visitor: 'activities_type3',
  User: 'lock_source_type.user',
  SpaceRole: 'lock_source_type.SpaceRole',
  Renting: 'lock.sourceType.deskAssignment',
  DeskReservationsDesk: 'lock.sourceType.deskBooking',
  Location: 'menu.member.desk',
  Area: 'menu.member.desk',
}

export const MEMBER_STATES = [
  // {
  //   label: 'member_state0',
  //   value: 'init',
  // },
  {
    id: 'active',
    color: GREEN,
  },
  {
    id: 'init',
    color: GREEN,
  },
  //  {
  //   label: 'member_state2',
  //   value: 'rejected',
  // },
  {
    id: 'dismission',
    color: RED,
  },
]

export const MEETING_LEADING_TIME_OBJECT = {
  less_than_0: 'meeting_leading_time_object0',
  '0_to_15': 'meeting_leading_time_object1',
  '15_to_30': 'meeting_leading_time_object2',
  '30_to_1_hour': 'meeting_leading_time_object3',
  '1_to_2_hour': 'meeting_leading_time_object4',
  '2_to_1_day': 'meeting_leading_time_object5',
  '1_to_2_day': 'meeting_leading_time_object6',
  '2_to_1_week': 'meeting_leading_time_object7',
  '1_to_1_month': 'meeting_leading_time_object8',
  more_than_1_month: 'meeting_leading_time_object9',
}

export const MEETING_DURATION_OBJECT = {
  '0_to_30': 'meeting_duration_object0',
  '30_to_1_hour': 'meeting_duration_object1',
  '1_to_2_hour': 'meeting_duration_object2',
  '2_to_4_hour': 'meeting_duration_object3',
  more_than_4_hour: 'meeting_duration_object4',
}

export const EQUIPMENTSTATE = {
  online:
    'linear-gradient(180deg,rgba(100,238,108,1) 0%,rgba(23,176,29,1) 100%)',
  offline:
    'linear-gradient(180deg,rgba(250,120,102,1) 0%,rgba(244,67,54,1) 100%)',
  expired:
    'linear-gradient(180deg,rgba(179,179,179,1) 0%,rgba(90,90,90,1) 100%)',
  never_reported:
    'linear-gradient(180deg,rgba(179,179,179,1) 0%,rgba(90,90,90,1) 100%)',
  disabled:
    'linear-gradient(180deg,rgba(179,179,179,1) 0%,rgba(90,90,90,1) 100%)',
  unknown:
    'linear-gradient(180deg,rgba(179,179,179,1) 0%,rgba(90,90,90,1) 100%)',
}

export const EQUIPMENTCOLOR = {
  online: 'rgba(23,176,29,1)',
  offline: 'rgba(244,67,54,1)',
  expired: 'rgba(90,90,90,1)',
  never_reported: 'rgba(90,90,90,1)',
  disabled: 'rgba(90,90,90,1)',
  unknown: 'rgba(90,90,90,1)',
}

export const MEETING_DEVICE_IMG = {
  meeting_pad: 'icon-iot-meeting_room_line',
  control_pad: 'icon-iot-central_control_panel_line',
  visitor_pad: 'icon-iot-visitor_pad_line',
  cabinet_pad: 'icon-iot-locker_line',
  desk_pad: 'icon-iot-meeting_room_line',
  lock: 'icon-iot-access_control',
  human_sensor: 'icon-iot-controller_line',
  actuator: 'icon-iot-controller_line',
  fog_glass: 'icon-iot-atomized_glass_line',
  power_strip: 'icon-iot-jack_line',
  person_flow: 'icon-iot-human_body_sensor_line',
  air: 'icon-iot-air_sensor_line',
  other: 'icon-iot-other_line',
  air_conditioner: 'icon-iot-air_conditioner_line',
  gateway: 'icon-iot-gateway_line',
  curtain: 'icon-iot-window_curtains_line',
  door_magnetic: 'icon-iot-door_magnetism_line',
  ink_screen: 'icon-iot-ink_screen_line',
  monitor_camera: 'icon-iot-webcam_line',
  monitor_server: 'icon-iot-monitoring_server_line',
}

export const CONTROL_REPAIR_LIST = [
  {
    label: 'controlRepair_processed',
    value: 'pending',
  },
  {
    label: 'controlRepair.received',
    value: 'received',
  },
  {
    label: 'controlRepair_complete',
    value: 'completed',
  },
  {
    label: 'controlRepair_refused',
    value: 'rejected',
  },
  {
    label: 'controlRepair_expired',
    value: 'expired',
  },
]

export const CONTROL_DELIVERY_LIST = [
  {
    label: 'delivery.pending',
    value: 'pending',
  },
  {
    label: 'controlRepair_complete',
    value: 'completed',
  },
  {
    label: 'controlRepair_refused',
    value: 'rejected',
  },
  {
    label: 'controlRepair_expired',
    value: 'expired',
  },
]

export const DEVICE_REPAIR_CONFIG_STATE = {
  pending: {
    label: 'controlRepair_processed',
    color: ORANGE,
  },
  completed: {
    label: 'controlRepair_complete',
    color: GREEN,
  },
  rejected: {
    label: 'controlRepair_refused',
    color: RED,
  },
  enabled: {
    label: 'controlRepair_enable',
    color: GREEN,
  },
  disabled: {
    label: 'controlRepair_disable',
    color: GRAY,
  },
  cancelled: {
    label: 'reservationInfo.cancelled',
    color: GRAY,
  },
  received: {
    label: 'controlRepair_received',
    color: 'rgb(72,150,235)',
  },
}

export const PERMISSIONS_SELECT_LIST = [
  {
    id: 'show_my_created',
    name: 'meetingStatistic.myScheduledMeeting',
  },
  {
    id: 'show_my_reserved',
    name: 'meetingStatistic.myChairedMeeting',
  },
  {
    id: 'show_my_attended',
    name: 'meetingStatistic.myAttendedMeeting',
  },
]

export const VALIDATION_METHODS = [
  'qrcode_validate',
  'face_validate',
  'secret_validate',
  'nfc_validate',
]

export const WORKSTATUS = [
  {
    text: 'workspace.unreviewed',
    value: 'unreviewed',
    color: '24,144,255',
  },
  {
    text: 'workspace.approving',
    value: 'approving',
    color: '247,138,22',
  },
  {
    text: 'workspace.approved',
    value: 'approved',
    color: '47,247,108',
  },
  {
    text: 'workspace.rejected',
    value: 'rejected',
    color: '87,87,87',
  },
  {
    text: 'workspace.terminated',
    value: 'terminated',
    color: '87,87,87',
  },
]

export const REPAIR_TYPE = [
  {
    // 网络设备
    value: 'it',
    image: 'icon-repair-network_equipment-bicolor',
  },
  {
    // 灯具电路
    value: 'engineering',
    image: 'icon-repair-lamps_and_lanterns--bicolor',
  },
  {
    // 水路管件
    value: 'water',
    image: 'icon-repair-waterway_fittings-bicolor',
  },
  {
    // 门窗桌椅
    value: 'office',
    image: 'icon-repair-table_and_chair-bicolor',
  },
  {
    // 墙地面
    value: 'wall',
    image: 'icon-repair-wall_floor-bicolor',
  },
  {
    // 安装拆除
    value: 'install',
    image: 'icon-repair-assembling_and_removing-bicolor',
  },
  {
    // 开锁换锁
    value: 'open_lock',
    image: 'icon-repair-unlock_and_change_lock-bicolor',
  },
  {
    // 空调
    value: 'air',
    image: 'icon-repair-air_conditioner-bicolor',
  },
  {
    // 常规维修
    value: 'common',
    image: 'icon-repair-routine_maintenance-bicolor',
  },
  {
    // 电器
    value: 'electrical',
    image: 'icon-repair-electrical_equipment-bicolor',
  },
  {
    // 会议室设备
    value: 'meeting',
    image: 'icon-repair-meeting_room_equipment-bicolor',
  },
  {
    // 门窗
    value: 'window',
    image: 'icon-repair-doors_and_windows-bicolor',
  },
  {
    // 其他报修
    value: 'other',
    image: 'icon-repair-other-bicolor',
  },
]

export const DELIVERY_TYPE = [
  {
    // 网络设备
    value: 'it',
    image: 'icon-Frame13',
  },
  {
    // 灯具电路
    value: 'engineering',
    image: 'icon-Frame-13',
  },
  {
    // 水路管件
    value: 'water',
    image: 'icon-Frame-23',
  },
  {
    // 门窗桌椅
    value: 'office',
    image: 'icon-Frame-31',
  },
  {
    // 墙地面
    value: 'wall',
    image: 'icon-Frame-41',
  },
  {
    // 安装拆除
    value: 'install',
    image: 'icon-Frame-51',
  },
  {
    // 开锁换锁
    value: 'open_lock',
    image: 'icon-Frame-61',
  },
  {
    // 空调
    value: 'air',
    image: 'icon-Frame-71',
  },
  {
    // 常规维修
    value: 'common',
    image: 'icon-Frame-81',
  },
  {
    // 电器
    value: 'electrical',
    image: 'icon-Frame-91',
  },
  {
    // 会议室设备
    value: 'meeting',
    image: 'icon-Frame-101',
  },
  {
    // 其他报修
    value: 'other',
    image: 'icon-Frame-111',
  },
]

export const RESERVE_TIME_TYPE = [
  {
    text: 'all_day',
    value: 'all_day',
  },
  {
    text: 'half_day',
    value: 'half_day',
  },
  {
    text: 'locationHomePage.time',
    value: 'time',
  },
]

export const PUBLIC_AREA_TYPES = [
  {
    id: 'front_desk',
    name: 'visitor.approve.reception',
  },
  {
    id: 'corridor',
    name: 'publicArea.corridor',
  },
  {
    id: 'dining_room',
    name: 'publicArea.dining_room',
  },
  {
    id: 'recreation_room',
    name: 'publicArea.recreation_room',
  },
]

export const COMMUNICATIONS_MODE = [
  {
    id: 'iot',
  },
  {
    id: 'socket',
  },
  {
    id: 'all',
  },
]

export const MEETING_TIME = [
  10, 15, 30, 45, 60, 90, 120, 180, 240, 300, 360, 420, 480, 540, 600, 660,
]

import online_1 from '@/assets/web/online/online_1.png'

import online_2 from '@/assets/web/online/online_2.png'

import online_3 from '@/assets/web/online/online_3.png'

import online_4 from '@/assets/web/online/online_4.png'

import online_5 from '@/assets/web/online/online_5.png'
export const CLOUD_VIDEO_CONF = [
  {
    id: 'webex',
    name: 'webex',

    image: <img src={online_1} alt="icon" />,
  },
  {
    id: 'tm',
    name: 'tm',

    image: <img src={online_2} alt="icon" />,
  },
  {
    id: 'biz_conf',
    name: 'biz_conf',

    image: <img src={online_3} alt="icon" />,
  },
  {
    id: 'zoom',
    name: 'zoom',

    image: <img src={online_4} alt="icon" />,
  },
  {
    id: 'teams',
    name: 'teams',

    image: <img src={online_5} alt="icon" />,
  },
]

export const GEO_MAP_KEY = '0974ffa7440cd3b5fb8b0c1ac782a957'

export const MEETING_ROOM_STATE_COLOR = {
  free: STATE_SUCCESS, // #1890ff
  partial_free: STATE_SUCCESS,
  reserved: STATE_ERROR, // #faad14
  assigned: STATE_ORANGE,
  expiring: STATE_ERROR,
  disabled: STATE_DEFAULT, // '#d9d9d9',
}

export const MEETING_ROOM_STATE_OBJ = {
  free: 'meetings.states.free',
  partial_free: 'meetings.states.partially_free',
  reserved: 'meetings.states.reserved',
  assigned: 'meetings.states.assigned',
  expiring: 'meetings.states.expiring',
  disabled: 'meetings.states.disabled',
}
export const DATE_TYPES = [
  {
    id: 'today',
  },
  {
    id: 'thisWeek',
  },
  {
    id: 'nextWeek',
  },
  {
    id: 'all',
  },
]
