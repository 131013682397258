import { createRoot } from 'react-dom/client'
import {
  BrowserRouter,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom'
import React from 'react'
import App from '@/App'
import './index.less'
import * as Sentry from '@sentry/react'

// 本地开发环境下不加载sentry
import.meta.env.VITE_NODE_ENV === 'production' &&
  import.meta.env.VITE_SENTRY_DSN &&
  import.meta.env.VITE_SENTRY_PROJECT &&
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    beforeSend: (event, hint) => {
      const { response } = hint.originalException
      // axios API error
      const status = response && response.status
      // link: https://stackoverflow.com/questions/55846690/how-to-globally-ignore-errors-with-sentry-v5-to-reduce-noise
      // If the request returns a 422 status code, we want to discard the event
      if ([422, 401, 403].includes(status)) {
        return null
      }

      return event
    },
    ignoreErrors: [
      /^Axios Error:/,
      'Non-Error promise rejection captured',
      'ResizeObserver loop limit exceeded',
      'Large Render Blocking Asset',
      'UnhandledRejection: Object captured as promise rejection with keys: code, config, message, name, request',
      'ResizeObserver loop completed with undelivered notifications.',
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    // replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    // replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })

const container = document.getElementById('root')

const root = createRoot(container)
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
)
