import intl from 'react-intl-universal'

export const getLocalStorage = (key: any, callback: any) =>
  localStorage.getItem(key, callback)

export const setLocalStorage = (key: any, value: any, callback: any) =>
  localStorage.setItem(key, value, callback)

export const deleteLocalStorage = (key: any, callback: any) =>
  localStorage.removeItem(key, callback)

export const clearLocalStorage = () => {
  let currentLocale = intl.determineLocale({
    urlLocaleKey: 'lang',
    cookieLocaleKey: 'lang',
    localStorageLocaleKey: 'lang',
  })

  const vLogs = getLocalStorage('v_logs') || []

  const lang = getLocalStorage('lang') || currentLocale || 'zh-CN'
  localStorage.clear()

  setLocalStorage('v_logs', vLogs)

  setLocalStorage('lang', lang)
}
